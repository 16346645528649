import { Divider, IconButton, Typography } from "@material-ui/core";
import React, { Component } from "react";

import Button from '@material-ui/core/Button';
import CartExtra from './cartExtra'
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Modal from '@material-ui/core/Modal';
import { alertActions } from '../actions/alert.actions.js';
import { connect } from 'react-redux';
import { removePlant } from '../actions/cartActions'
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    fullWidth: {
        whiteSpace: 'nowrap',
        width: '100%',
    },
    deleteIcon: {
        '&:hover': {
            cursor: 'pointer',
            fill: theme.palette.secondary.dark,
        },
        fill: theme.palette.primary.dark,
        transition: '0.3s'
    },
    currentTisanePlant: {
        padding:0,
    },
    quantity: {
        textAlign: 'center',
    },
    addToCartButton: {
        textAlign: 'center',
    },
    tisaneHeader: {
        textAlign: 'center',
    },
    plantList: {
        paddingLeft: '0.3em',
    },
    twoColumns: {
        columns: 2,
    },

    plantName: {
        'font-weight': 'bold',
        '& > span': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        }
    },
    currentTisaneHeader: {
        padding: theme.spacing(1.5, 0),
        lineHeight: '1em',
        textAlign: 'center',
        position: 'relative',
    },
    title: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    currentTisaneFooter: {
        margin: theme.spacing(1, 'auto'),
    },
    price: {
        fontSize: '1.2em',
    },
    noPlant: {
        paddingLeft: '0.5em',
        paddingRight: '0.5em',
    },
    blackDivider: {
        backgroundColor: theme.palette.secondary.light,
    },
    validButton: {
        paddingLeft: '8px',
        paddingRight: '8px',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        }
    },
    closeIcon: {
        position: 'absolute',
        right: '0px',
        fill: theme.palette.secondary.main,
        transition: '0.3s',
        float: 'right',
        marginTop: '-20px',
    },
});


class CurrentTisane extends Component {

    removePlant = (plant_id) => {
        this.props.removePlant(plant_id);
    }

    handleOpen = () => {
        this.setState({ open: true});
    };

    handleClose = () => {
        this.setState({ open: false});
    };

    constructor() {
        super();
        this.state = {
            open: 0,
            // weightError: false,
        }
    }

    render() {
        if (!this.props.cartValid){
            return (
                <p>Chargement...</p>
            )
        }

        const classes = this.props.classes;
        let addedItems = this.props.addedItems;
        let columnClasses = addedItems.length > 2 ? classes.twoColumns : '';
        return (
            <React.Fragment>
                <Modal
                open={this.state.open}
                onClose={this.handleClose}
                style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <CartExtra handleClose={this.handleClose}/>
                </Modal>
                <Grid
                  container
                  direction="column"
                  >
                    <Grid item className={classes.currentTisaneHeader}>
                        <Typography variant="h5" component="span" className={classes.title}>Ma Cueillette</Typography>
                        { this.props.closeModal !== undefined &&
                            <IconButton className={classes.closeIcon} size='medium'>
                                <CloseIcon onClick={this.props.closeModal}/>
                            </IconButton>
                        }

                    </Grid>
                    <Divider className={classes.blackDivider}/>
                    { addedItems.length > 0 &&
                        <Grid item className={classes.fullWidth}>
                            <List dense='true' className={`${classes.plantList} ${columnClasses}`}>
                                {addedItems.map(id => {
                                    const plant_name = this.props.plants.find(item => item.id === id).name;
                                        return(
                                    <ListItem className={classes.currentTisanePlant}>
                                        <ClearIcon onClick={()=>{this.removePlant(id)}} className={classes.deleteIcon}/>
                                        <ListItemText
                                            primary={plant_name} className={classes.plantName}/>
                                    </ListItem>
                                )})}
                            </List>
                        </Grid>
                    }
                    {
                        addedItems.length === 0 &&
                        <Typography variant='body1' className={classes.noPlant}>Ajoutez une plante pour commencer votre tisane !</Typography>
                    }
                    <Divider className={classes.blackDivider}/>
                    <Grid item className={classes.currentTisaneFooter}>
                        <Button className={classes.validButton} variant="outlined" disabled={addedItems.length === 0} onClick={this.handleOpen}>Valider</Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps= (dispatch)=>{
    return {
        removePlant: (plant_id)=>{dispatch(removePlant(plant_id))},
        errorAlerts: (message) => {dispatch(alertActions.error(message))}
    }
}

const mapStateToProps = (state)=>{
    return {
        maxPlantsByWeight: state.cartReducer.maxPlantsByWeight,
        sweetAdded: state.cartReducer.sweetAdded,
        decoAdded: state.cartReducer.decoAdded,
        plants : state.cartReducer.plants,
        cartValid: state.cartReducer.cartValid
    }}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CurrentTisane));
