import { userConstants } from '../actions/action-types/user-actions.js';

const initialState = {loggedIn: false, token: '', loading: true};

export default function authentication(state = initialState, action) {
    switch (action.type) {
    case userConstants.LOGIN_REQUEST:
        return {
        loading: true,
        };
    case userConstants.LOGIN_SUCCESS:
        return {
        loggedIn: true,
        loading: false,
        token: action.user.auth_token
        };
    case userConstants.LOGIN_FAILURE:
        return {};
    case userConstants.LOGOUT:
        return initialState;
    default:
        return state
    }
}
