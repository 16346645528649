import React, { Component } from "react";

import Container from '@material-ui/core/Container';
import FacebookIcon from '@material-ui/icons/Facebook';
import Grid from '@material-ui/core/Grid';
import InstagramIcon from '@material-ui/icons/Instagram';
import Paper from '@material-ui/core/Paper';
import { Typography } from "@material-ui/core";
import { connect } from 'react-redux';
import { resetCart } from '../actions/cartActions';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    paper: {
        margin: theme.spacing(2, 0),
        padding: theme.spacing(2),
    },
    title: {
        textAlign: 'center',
        paddingBottom: theme.spacing(1),
    },
    socialLink: {
        textAlign: 'center',
    },
    socialLinkInner: {
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.secondary.dark,
        }
    }
});

class Success extends Component {

    componentDidMount(){
        const { dispatch } = this.props;
        dispatch(resetCart())
    }

  render() {
    const classes = this.props.classes;
    return (
        <Container maxWidth="sm">
            <Paper className={classes.paper}>
                <Typography variant="h4" color="secondary" className={classes.title}>Merci de votre commande!</Typography>
                <Typography variant="subtitle">Vous recevrez un mail de confirmation de commande. Notre équipe préparera vos tisanes dans les meilleurs délais.</Typography>
                <Typography variant="subtitle" component="p">Partagez votre éxperience sur les réseaux sociaux <b>Tisanéo</b></Typography>
                <Grid container>
                    <Grid item xs={6} className={classes.socialLink}>
                    <a href="https://www.facebook.com/tisaneo.fr/" className={classes.socialLinkInner} target="_blank" rel="noopener noreferrer"><FacebookIcon /></a>
                    </Grid>
                    <Grid item xs={6} className={classes.socialLink}>
                    <a href="https://www.instagram.com/tisaneo.fr/" className={classes.socialLinkInner}target="_blank" rel="noopener noreferrer"><InstagramIcon /></a>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
  }
}


export default connect(null)(withStyles(styles)(Success));
