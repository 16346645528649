import * as yup from 'yup';

import React, { Component } from "react";

import CartAddressForm from './CartAddressForm'
import Checkbox from '@material-ui/core/Checkbox';
import { Collapse } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { userService } from '../services/user.service';
import { withStyles } from '@material-ui/styles';

;
const styles = theme => ({
    sectionTitle: {
        paddingTop: theme.spacing(2),
        textAlign: 'center',
        color : theme.palette.secondary.main,
    }
});


class CartAddress extends Component {

    signupSchema = yup.object().shape({
        addressId: yup.string(),
        fullName: yup.string().required('Obligatoire'),
        address1: yup.string().required('Obligatoire'),
        address2: yup.string(),
        city: yup.string().required('Obligatoire'),
        zipCode: yup.string().required('Obligatoire'),
        country: yup.string().required('Obligatoire'),
        phone: yup.string().min(5, 'Invalide').required('Obligatoire'),
    })

    constructor(props) {
        super(props);
        this.state = {
            saveAddress: false,
            billingAddress: true,
            addresses: []
        }

        this.livForm = React.createRef();
        this.factuForm = React.createRef();

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }



    handleCheckboxChange(e) {
        const { name, checked } = e.target;
        this.setState({...this.state, [name]: checked });
    }

    componentDidMount() {
        if (this.props.loggedIn){
            userService.get_user_addresses()
                .then ((data) => {
                    this.livForm.current.updateAddressSelect(data);
                    this.factuForm.current.updateAddressSelect(data);
            });
        }
      }


    submitForm(event) {
        const add1 = this.livForm.current.getAddress()
        const livAddress = add1.address;
        const errors1 = add1.errors;
        let errors2 = add1.errors;
        let factuAddress = livAddress;
        let saveAddress = this.state.saveAddress;
        if (!this.state.billingAddress){
            let add2 = this.factuForm.current.getAddress()
            factuAddress = add2.address;
            errors2 = add2.errors;
        }
        return {livAddress, factuAddress, errors1, errors2, saveAddress}
    }

    render() {
        const classes = this.props.classes;
        const checkLabel = this.state.billingAddress ? "Sauvegarder cette adresse pour de futurs achats" : "Sauvegarder ces adresses pour de futurs achats"
        return (
            <Container>
                <Typography variant="h4" className={classes.sectionTitle}>Adresse d'expédition</Typography>
                <FormControlLabel
                control={<Checkbox color="secondary" name="billingAddress" checked={this.state.billingAddress} onChange={this.handleCheckboxChange} value={this.state.billingAddress}/>}
                label="Utiliser comme adresse de facturation" />
                <CartAddressForm ref={this.livForm}/>
                <Collapse in={!this.state.billingAddress}>
                    <Typography variant="h4" className={classes.sectionTitle}>Adresse de facturation</Typography>
                    <CartAddressForm ref={this.factuForm} />
                </Collapse>
                {this.props.loggedIn &&
                    <FormControlLabel
                        control={<Checkbox color="secondary" name="saveAddress" checked={this.state.saveAddress} onChange={this.handleCheckboxChange} value={this.state.saveAddress}/> }
                        label={checkLabel}/>}
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return { loggedIn: state.authentication.loggedIn };
}


export default connect(mapStateToProps, null, null, {forwardRef: true})(withStyles(styles)(CartAddress));
