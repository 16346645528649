import { buildAuthHeader, handleResponse } from '../helpers/ajax';

export const adminService = {
    getConfigs,
    updateConfigs,
    getDeliveryItems,
    getDeliveryItem,
    updateDeliveryItem,
    getAdminTag,
    getAdminTagForm,
    updateAdminTag
}


function getConfigs(keys){
    const requestOptions = {
        method: 'GET',
        headers: buildAuthHeader(),
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/settings/` + keys.join(','), requestOptions)
        .then(handleResponse)
}

function updateConfigs(values){
    const requestOptions = {
        method: 'POST',
        headers: buildAuthHeader(),
        body: JSON.stringify(values)
    }
    // JSON.stringify(values)
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/settings/` , requestOptions)
        .then(handleResponse)
}

function getDeliveryItems(){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/admin/deliveries/` , requestOptions)
        .then(handleResponse)
}

function getDeliveryItem(delivery_id){
    const requestOptions = {
        method: 'GET',
        headers: buildAuthHeader()
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/admin/delivery/` + delivery_id , requestOptions)
        .then(handleResponse)
}

function updateDeliveryItem(delivery_id, values){
    const requestOptions = {
        method: 'POST',
        headers: buildAuthHeader(),
        body: JSON.stringify(values)
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/admin/delivery/` + delivery_id, requestOptions)
    .then(handleResponse)
}


function getAdminTag(categoryId){
    const requestOptions = {
        method: 'GET',
        headers: buildAuthHeader(),
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/admin/tag/` + categoryId, requestOptions)
        .then(handleResponse)
}

function getAdminTagForm(tagId){
    const requestOptions = {
        method: 'GET',
        headers: buildAuthHeader(),
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/admin/tag/edit/` + tagId, requestOptions)
        .then(handleResponse)
}

function updateAdminTag(tagId, values){
    const requestOptions = {
        method: 'POST',
        headers: buildAuthHeader(),
        body: JSON.stringify(values),
    }
    const realTagId = tagId ? 'edit/' + tagId : 'new/'
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/admin/tag/` + realTagId, requestOptions)
        .then(handleResponse)
}
