import React, { Component } from "react";
import { Route, Router, Switch } from "react-router-dom";

import About from "./components/About"
import Admin from "./components/Admin"
import AdminEmails from "./components/AdminEmails";
import AdminOrders from "./components/AdminOrders";
import AdminPlants from "./components/AdminPlants";
import AdminPlantsForm from "./components/AdminPlantsForm";
import AdminPrice from "./components/AdminPrice";
import AdminRecipes from "./components/AdminRecipes"
import AdminRecipesForm from "./components/AdminRecipesForm"
import AdminShipment from "./components/AdminShipment";
import AdminShipmentForm from "./components/AdminShipmentForm";
import AdminTags from "./components/AdminTags";
import AdminTagsForm from "./components/AdminTagForm";
import AdminUsers from "./components/AdminUsers";
import Alert from '@material-ui/lab/Alert';
import Cgv from "./components/Cgv"
import Contact from "./components/Contact"
import Login from "./components/Login";
import NavBar from "./components/NavBar";
import Order from "./components/Order";
import OrderList from "./components/UserOrders";
import PasswordReset from "./components/PasswordReset"
import PlantDescription from "./components/PlantDescription";
import PlantList from "./components/PlantList";
import { PrivateRoute } from "./components/PrivateRoute";
import Register from "./components/register";
import ResetPasswordForm from "./components/PasswordResetForm"
import Settings from './components/Settings'
import Success from './components/Success'
import TisaneList from "./components/UserTisane";
import UserInfo from "./components/UserInfo";
import { alertActions } from './actions/alert.actions.js';
import cartValidationPage from "./components/cartValidationPage";
import { connect } from 'react-redux';
import { history } from './helpers/history';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    alertBox : {
        position: 'fixed',
        zIndex: '1200',
        left: "50%",
        transform: "translate(-50%)",
        marginTop: theme.spacing(2),
    },
});

class App extends Component {

    constructor(props) {
        super(props);
        history.listen((location, action) => {
            // clear alert on location change
            this.props.clearAlerts();
        });
    }

  render() {
    const { alert } = this.props;
    const classes = this.props.classes;
    if (alert.message) {
        setTimeout(() => {
            this.props.clearAlerts();
        }, 5000)
    }
    return (
        <React.Fragment>
            <NavBar/>
            {alert.message && <Alert className={classes.alertBox} variant='standard' severity={alert.type}>{alert.message}</Alert>}

            <Router history={history}>
                <div>
                    <Switch>
                        <Route exact path="/about" component={About} />
                        <Route exact path="/contact" component={Contact} />
                        <Route exact path="/cgv" component={Cgv} />
                        <Route exact path="/" component={PlantList} />
                        <Route path="/login/:cart" component={Login} />
                        <Route path="/password_reset" component={PasswordReset} />
                        <Route path="/reset/:token" component={ResetPasswordForm} />
                        <Route path="/register" component={Register} />
                        <Route path="/plant/:plant_id" component={PlantDescription} />
                        <Route path="/cart" component={cartValidationPage} />
                        <Route exact path="/success" component={Success} />
                        <PrivateRoute isAutenticated={this.props.authentication.loggedIn} exact path="/me" component={UserInfo} />
                        <PrivateRoute isAutenticated={this.props.authentication.loggedIn} exact path="/my_tisane" component={TisaneList} />
                        <PrivateRoute isAutenticated={this.props.authentication.loggedIn} exact path="/my_orders" component={OrderList} />
                        <PrivateRoute isAutenticated={this.props.authentication.loggedIn} exact path="/order/:order_id" component={Order} />


                        <PrivateRoute isAutenticated={this.props.authentication.loggedIn} exact path="/admin" component={Admin} />
                        <PrivateRoute isAutenticated={this.props.authentication.loggedIn} exact path="/admin/plants" component={AdminPlants} />
                        <PrivateRoute isAutenticated={this.props.authentication.loggedIn} exact path="/admin/plants/edit/:plant_id?" component={AdminPlantsForm} />
                        <PrivateRoute isAutenticated={this.props.authentication.loggedIn} exact path="/admin/orders" component={AdminOrders} />
                        <PrivateRoute isAutenticated={this.props.authentication.loggedIn} exact path="/admin/tags/:tag_type" component={AdminTags} />
                        <PrivateRoute isAutenticated={this.props.authentication.loggedIn} exact path="/admin/tag/edit/:tag_id?" component={AdminTagsForm} />
                        <PrivateRoute isAutenticated={this.props.authentication.loggedIn} exact path="/admin/orders" component={AdminOrders} />
                        <PrivateRoute isAutenticated={this.props.authentication.loggedIn} exact path="/admin/settings" component={Settings} />
                        <PrivateRoute isAutenticated={this.props.authentication.loggedIn} exact path="/admin/recipes" component={AdminRecipes} />
                        <PrivateRoute isAutenticated={this.props.authentication.loggedIn} exact path="/admin/recipes/edit/:recipe_id?" component={AdminRecipesForm} />
                        <PrivateRoute isAutenticated={this.props.authentication.loggedIn} exact path="/admin/price" component={AdminPrice} />
                        <PrivateRoute isAutenticated={this.props.authentication.loggedIn} exact path="/admin/shipment" component={AdminShipment} />
                        <PrivateRoute isAutenticated={this.props.authentication.loggedIn} exact path="/admin/shipment/edit/:delivery_id" component={AdminShipmentForm} />
                        <PrivateRoute isAutenticated={this.props.authentication.loggedIn} exact path="/admin/users" component={AdminUsers} />
                        <PrivateRoute isAutenticated={this.props.authentication.loggedIn} exact path="/admin/emails" component={AdminEmails} />
                    </Switch>
                </div>
            </Router>
        </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
    const { alert, authentication } = state;
    return { alert, authentication };
}

const mapDispatchToProps = {
    clearAlerts: alertActions.clear,
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));
