import { Container, Typography } from "@material-ui/core";
import React, { Component } from "react";

import AdminCGV from './AdminCGV';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/styles';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
}

const styles = theme => ({
    root: {
        flexGrow: 1,
      },
    centered: {
        textAlign: 'center',
    }
});


class Admin extends Component {

    constructor(props){
        super(props);
        this.state = {
            value: null,
        }
    }


    handleChange = (event, newValue) => {
        this.setState({value: newValue})
    };

    render() {
        const classes = this.props.classes;
        return (
            <Container>
                <Paper className={classes.root}>
                    <Typography variant="h6" color="secondary" className={classes.centered}>General settings</Typography>
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        >
                        <Tab label="CGV" />
                    </Tabs>
                    <TabPanel value={this.state.value} index={0}>
                        <AdminCGV/>
                    </TabPanel>
                    {/* <TabPanel value={this.state.value} index={1}>

                    </TabPanel>
                    <TabPanel value={this.state.value} index={2}>
                        <AdminCGV settings={[{name: 'app_description_1', type: 'text'}, {name: 'app_description_2', type: 'text'}, {name: 'app_description_3', type: 'text', 'about': 'text'}]} />
                    </TabPanel> */}
                </Paper>
            </Container>
        )
    }
}

export default withStyles(styles)(Admin);
