import React, { Component } from "react";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Modal from '@material-ui/core/Modal';
import TisaneDescription from './TisaneDescription'
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    root: {
        '&:hover': {
            cursor: 'pointer',
        },
        height: '270px',
        position: 'relative'
    },
    mainTitle: {
        fontSize: '14px',
        lineHeight: '19px',
        fontWeight: '600',
    },
    container: {
        outline: 'none',
    }
})

class PreniumTisaneInner extends Component {

    constructor(props){
        super(props);
        this.state = {
            modalOpen: false,
          };
    }

    handleOpen = () => {
        this.setState({modalOpen: true});
    };

    handleModalClose = () => {
        this.setState({modalOpen: false});
    };

    render() {
        const classes = this.props.classes;

        if (! this.props.plants.length){
            return (
                <p>Chargement des tisanes.</p>
            )
        }
        const plantList = []

        for (let i=0; i < this.props.item.tisanePlants.length ; i++) {
            plantList.push(this.props.plants.find(item => item.id === this.props.item.tisanePlants[i]).name)
        }
        return (
            <React.Fragment>
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.handleModalClose}
                    style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <TisaneDescription id={this.props.item.id} closeModal={this.handleModalClose}/>
                </Modal>
                <Card className={classes.root} onClick={this.handleOpen}>
                    <CardMedia
                    component="img"
                    alt={this.props.item.name}
                    src={`${process.env.REACT_APP_USERS_SERVICE_URL}${this.props.item.picture_url}`}
                    title={this.props.item.name}
                    style={{height: '140px'}}
                    />
                    <CardContent>
                        <Typography gutterBottom component="span" className={classes.mainTitle}>
                            {this.props.item.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {plantList.join(', ')}
                        </Typography>
                    </CardContent>
                </Card>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state)=>{
    return {
        plants: state.cartReducer.plants,
    }
}

const ConnectedInner = connect(mapStateToProps)(withStyles(styles)(PreniumTisaneInner));

class PreniumTisane extends Component {

    render() {
        return (
            <ConnectedInner item={this.props.item}/>
        )
    }
}



export default PreniumTisane;
