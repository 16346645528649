import React, { Component } from "react";

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { userService } from '../services/user.service';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    title: {
        textAlign: 'center',
    },
    userInfo: {
        padding: theme.spacing(1),
        margin: theme.spacing(2, 0)
    },
    submitContainer: {
        textAlign: 'center',
    },
    submitButton: {
        color: 'white',
        fontSize: '16px',
        fontWeight: '600',
        '&:hover': {
            color: 'white',
        }
    }
})

class UserPersonal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            validateButton: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.submitUserChange = this.submitUserChange.bind(this);
    }

    componentDidMount() {
        userService.get_user_data()
            .then ((data) => {
                this.setState({firstName : data.first_name, lastName: data.last_name, email: data.email})
            });
      }

    handleChange(e){
        const { name, value } = e.target;
        this.setState({[[name]]: value, validateButton: true})
    }

    submitUserChange(){
        userService.updateUserData(this.state.firstName, this.state.lastName)
    }

    render() {
        const classes = this.props.classes;
        return (
            <Paper className={classes.userInfo}>
                <Typography variant="h4" className={classes.title}>Informations personnelles</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField variant="outlined" name="firstName" fullWidth onChange={this.handleChange} value={this.state.firstName}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField variant="outlined" name="lastName" fullWidth onChange={this.handleChange}  value={this.state.lastName}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField variant="outlined" fullWidth disabled value={this.state.email}/>
                    </Grid>
                    <Grid item xs={12} className={classes.submitContainer}>
                        <Button type="submit" variant="contained" color="primary" disabled={!this.state.validateButton} className={classes.submitButton} onClick={this.submitUserChange}>
                            Enregistrer
                        </Button>
                    </Grid>
                </Grid>

            </Paper>
        )
    }
}

export default (withStyles(styles)(UserPersonal));
