import { applyMiddleware, createStore } from 'redux';

// import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import reducer from './reducers/index';
import thunkMiddleware from 'redux-thunk';

// const loggerMiddleware = createLogger();

export const store = createStore(reducer,
    applyMiddleware(
        thunkMiddleware
        // loggerMiddleware
    ));
export const persistor = persistStore(store);
