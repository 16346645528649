import { history } from '../helpers/history';
import { store } from '../configureStore'
import { userActions } from '../actions/user.actions';

export function buildAuthHeader() {
    return buildHeader(true)
}

export function buildHeader(auth=false) {
    const header = new Headers();
    header.append('Content-Type', 'application/json')
    if (auth) {
        const user_token = getUserToken()
        if(user_token)
            header.append('Authorization', 'Bearer ' + user_token);
    }
    return header;
}

export function getUserToken() {
    // return authorization header with jwt token
    let state = store.getState();
    if (state.authentication.token !== '') {
        return state.authentication.token;
    } else {
        return '';
    }
}

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            // 401 unothorized. A user that is not logged in and try to access a logged
            // in content
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                store.dispatch(userActions.logout());
                window.location.reload(true);
            }
            // 403 forbidden. A user not Admin that try to access an Admin only content
            if (response.status === 403) {
                history.push('/');
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
