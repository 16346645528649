import React, {Component} from 'react';

import AccountCircle from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Basket from './Basket'
import Grid from '@material-ui/core/Grid';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { history } from '../helpers/history';
import { resetNotification } from '../actions/cartActions.jsx'
import { userActions } from '../actions/user.actions';
import { withStyles } from '@material-ui/styles';

function NavBarItems(props) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [modalOpen, setOpen] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
      };

      const handleClose = () => {
        setAnchorEl(null);
      };

    const handleMeClick = () => {
        history.push('/me');
    };

    const handleMyTisaneClick = () => {
        history.push('/my_tisane');
    };

    const handleMyOrders = () => {
        history.push('/my_orders');
    };

    const handleConnexion = () => {
        history.push('/login/false');
    };

    const handleOpen = () => {
        setOpen(true);
        props.resetNotification()
      };

      const handleModalClose = () => {
        setOpen(false);
      };

    return (
        <React.Fragment>
            <div>
                <Modal
                open={modalOpen}
                onClose={handleModalClose}
                className={props.modalClass}>
                    <Basket mobile={true} closeModal={handleModalClose}/>
                </Modal>
                <Hidden smUp>
                    <IconButton onClick={handleOpen} size="small">
                        <Badge badgeContent={props.notifications} className={props.badge}>
                            <ShoppingBasketIcon className={props.iconClass}/>
                        </Badge>
                    </IconButton>
                </Hidden>

            {!props.isAuthenticated &&
                <React.Fragment>
                    <Hidden smUp>
                        <IconButton size="small" onClick={handleConnexion}>
                            <AccountCircle className={props.iconClass} />
                        </IconButton>
                    </Hidden>
                    <Hidden xsDown>
                        <IconButton onClick={handleConnexion}>
                            <AccountCircle className={props.iconClass} />
                        </IconButton>
                    </Hidden>
                </React.Fragment>
                }
            {props.isAuthenticated &&
            <React.Fragment>
                <Hidden smUp>
                <IconButton
                    onClick={handleMenu}
                    color="inherit"
                    size="small"
                >
                    <AccountCircle className={props.iconClass} />
                </IconButton>
            </Hidden>
                <Hidden xsDown>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <AccountCircle className={props.iconClass} />
                    </IconButton>
                </Hidden>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={()=> handleMeClick()} to='/me'>Profil</MenuItem>
                    <MenuItem href='/my_tisane' onClick={()=> handleMyTisaneClick()}>Mes Tisanes</MenuItem>
                    <MenuItem href='/my_orders' onClick={()=> handleMyOrders()}>Mes Commandes</MenuItem>
                    <MenuItem onClick={()=> props.handleLogout()}>Déconnexion</MenuItem>
                </Menu>
                </React.Fragment>
                }
            </div>
        </React.Fragment>)
    ;
}

const styles = theme => ({

    appbar: {
        background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
        position: "static",
        padding: '0px !important'
        },
    toolbar: {
        minHeight: "80px",
    },
    title: {
      flexGrow: 1,
      textAlign: 'center'
    },
    accountCircle: {
        fill: theme.palette.secondary.main,
    },
    logo: {
        margin: theme.spacing(1),
        height: '70px',
    },
    smalllogo: {
        margin: theme.spacing(1),
        height: '50px',
    },
    basketModal: {
        position: 'absolute',
        overflow: 'scroll',
    },
    '@media screen and (max-width: 600px)': {
        appbar: {
            position: 'sticky'
        }
    },
    badge: {
        '& > span': {
            backgroundColor: theme.palette.tertiary.main,
        }
    },
    centered: {
        textAlign: 'center',
    },
    aboutLink: {
        color: theme.palette.secondary.main,
    },
    aboutIcon: {
        color: theme.palette.secondary.main,
    }
});

class ButtonAppBar extends Component {

    constructor(props) {
        super(props);
        this.handleLogout = this.handleLogout.bind(this);
        this.resetNotification = this.resetNotification.bind(this);
    }

    handleLogout() {
        this.props.dispatch(userActions.logout());
    }

    resetNotification(){
        this.props.dispatch(resetNotification())
    }

    handleAbout(){
        history.push('/about')
    }

    render(){
        const classes = this.props.classes;
        return (
            <AppBar className={classes.appbar}>
                <Toolbar className={classes.toolbar}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={2} className={classes.centered}>
                            <Hidden mdUp>
                                <IconButton className={classes.aboutIcon} onClick={this.handleAbout}>
                                    <HelpOutlineIcon />
                                </IconButton>
                        </Hidden>
                        <Hidden smDown>
                            <Typography variant="h6"><a className={classes.aboutLink} href="/about">Qui sommes-nous?</a></Typography>
                        </Hidden>
                        </Grid>
                        <Grid item xs={8} className={classes.centered}>
                            <Hidden xsDown>
                                <Link href="/" className={classes.logo}>
                                    <img src="/logo.png" className={classes.logo} alt='logo'/>
                                </Link>
                            </Hidden>
                            <Hidden smUp>
                                <Link href="/">
                                    <img src="/logo.png" className={classes.smalllogo} alt='logo'/>
                                </Link>
                            </Hidden>
                        </Grid>
                        <Grid item xs={2} className={classes.centered}>
                            <NavBarItems
                                isAuthenticated={this.props.loggedIn}  handleLogout={this.handleLogout} iconClass={classes.accountCircle} modalClass={classes.basketModal} resetNotification={this.resetNotification} notifications={this.props.cartNotification} badge={classes.badge} />
                        </Grid>
                    </Grid>

                </Toolbar>
            </AppBar>
        );
    }
}

function mapStateToProps(state) {
    return {
        loggedIn: state.authentication.loggedIn,
        cartNotification: state.cartReducer.cartNotification,
     };
}

export default  connect(mapStateToProps)(withStyles(styles, { withTheme: true })(ButtonAppBar));
