export const ADD_TO_TISANE = 'ADD_TO_TISANE';
export const ADD_OFFICIAL_TISANE = 'ADD_OFFICIAL_TISANE';
export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const ADD_SWEETS = 'ADD_SWEETS';
export const ADD_DECO = 'ADD_DECO';
export const UPDATE_TISANE_NAME = 'UPDATE_TISANE_NAME';
export const UPDATE_MAX_PLANTS = 'UPDATE_MAX_PLANTS';
export const ADD_TO_CART = 'ADD_TO_CART';
export const UP_QUANTITY = 'UP_QUANTITY';
export const DOWN_QUANTITY = 'DOWN_QUANTITY';
export const REQUEST_PLANTS = 'REQUEST_PLANTS';
export const RECEIVE_PLANTS = 'RECEIVE_PLANTS';
export const REQUEST_SWEETS = 'REQUEST_SWEETS';
export const RECEIVE_SWEETS = 'RECEIVE_SWEETS';
export const REQUEST_DECO = 'REQUEST_DECO';
export const RECEIVE_DECO = 'RECEIVE_DECO';
export const REMOVE_PLANT = 'REMOVE_PLANT';
export const DELETE_ITEM = 'DELETE_ITEM';
export const EDIT_ITEM = 'EDIT_ITEM';
export const SET_DELIVERY_METHOD = 'SET_DELIVERY_METHOD';
export const SET_DELIVERY_PRICE = 'SET_DELIVERY_PRICE';
export const REQUEST_WEIGHTS = 'REQUEST_WEIGHTS';
export const RECEIVE_WEIGHTS = 'RECEIVE_WEIGHTS';
export const RECEIVE_DELIVERIES = 'RECEIVE_DELIVERIES';
export const REQUEST_DELIVERIES = 'REQUEST_DELIVERIES';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
export const RESET_CART = 'RESET_CART';
