import 'react-quill/dist/quill.snow.css';

import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Divider from '@material-ui/core/Divider';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { adminService } from '../services/admin.service';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
});


class AdminTags extends Component {

    constructor(props){
        let tag_type = props.match.params.tag_type === "taste" ? 0 : 1
        super(props);
        this.state = {
            tagType: tag_type,
            tags: [],
        }
    }

    componentDidMount(){
        adminService.getAdminTag(this.state.tagType)
            .then(res=>this.setState({tags: res}))
    }

    render() {
        let button = this.state.tagType ? 'Nouvelle propriété' : "Nouveau goût"
        return (
            <Container maxWidth="sm">
                <Button href="/admin/" variant="contained">Retour</Button>
                <Button href="/admin/tag/edit" color="primary" variant="contained">{button}</Button>
                <List dense='true'>
                    {this.state.tags.map(item => {
                        let visibility = item.visible === "True" ? '' : ' (caché)';
                        return (
                            <React.Fragment>
                                <Divider component="li" />
                                <ListItem>
                                    <ListItemText
                                        primary={item.name + visibility}
                                        />
                                    <ListItemSecondaryAction>
                                        <IconButton href={"/admin/tag/edit/" + item.id}>
                                            <EditOutlinedIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </React.Fragment>
                        )
                    })}
                </List>
            </Container>
        )
    }
}

export default withStyles(styles)(AdminTags);
