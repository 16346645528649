import React, { Component } from "react";

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { adminService } from '../services/admin.service';
import { history } from '../helpers/history';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({

});


class AdminShipmentForm extends Component {

    componentDidMount(){
        adminService.getDeliveryItem(this.state.delivery_id)
            .then(res => this.setState({deliveryItem: res}))
    }

    constructor(props){
        super(props);
        this.state = {
            delivery_id: this.props.match.params.delivery_id,
            deliveryItem: {},
        }
    }

    render() {
        if (!Object.keys(this.state.deliveryItem).length){
            return <p>Chargement...</p>
        }
        const initialValues = {
            'zone': this.state.deliveryItem.zone,
            'price': this.state.deliveryItem.price,
            'franco_price': this.state.deliveryItem.franco_price}
        return (
            <Container maxWidth="sm">
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting }) => {
                        adminService.updateDeliveryItem(this.state.delivery_id, values)
                        .then(history.push('/admin/shipment'))
                          setSubmitting(false);
                    }}>

                {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <TextField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="standard-basic" label="Nom de zone" name="zone"
                                        value={values.zone}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            id="standard-basic" label="Prix" name="price"
                                            value={values.price}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            id="standard-basic" label="Prix franco" name="franco_price"
                                            value={values.franco_price}/>
                                </Grid>
                            </Grid>
                            <Button variant="contained" type="submit" color="primary">Valider</Button>
                        </form>
                    )}
                    </Formik>
            </Container>
        )
    }
}

export default withStyles(styles)(AdminShipmentForm);
