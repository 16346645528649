import { Divider, FormControl, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { fetchDeliveriesIfNeeded, fetchPlantsIfNeeded, setDeliveryPrice } from '../actions/cartActions.jsx'

import CartItem from './cartItem'
import CartItemDescription from './cartItemDescription';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    price: {
        float: 'right'
    },
    totalprice: {
        float: 'right',
        fontWeight: '505',
        fontSize: '16px',
    },
    cartValidation: {
        margin: '2em 1em',
    },
    cartTableHeader : {
        marginBottom: '0.4em',
    },
    secondPart: {
        marginTop: '1em',
    },
    totalTable: {
        padding: theme.spacing(0, 1),
    },
    totalTablePrice: {
        padding: theme.spacing(0, 1)
    },
    sectionTitle: {
        paddingTop: theme.spacing(2),
        textAlign: 'center',
        color : theme.palette.secondary.main,
    },
    cartDivider: {
        backgroundColor: theme.palette.secondary.main,
    },
    itemWrapper: {
        padding: theme.spacing(1, 0)
    },
    total: {
        fontSize: '16px',
        fontWeight: '505'
    },
    subTotal: {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '1',
    }
})


class cartValidation extends Component {

    handleDeliveryPrice(e) {
        const zone = e.target;
        const { dispatch } = this.props;
        dispatch(setDeliveryPrice(zone.value));
    }

    componentDidMount() {
        this.props.dispatch(fetchPlantsIfNeeded())
        this.props.dispatch(fetchDeliveriesIfNeeded())
    }

    render() {
        const classes = this.props.classes;
        return (
            <React.Fragment>
            <Typography variant="h4" className={classes.sectionTitle}>Votre panier</Typography>
            <div className={classes.cartValidation}>
                <div className={classes.cartTableHeader}>
                    <CartItemDescription />
                    <Divider className={classes.cartDivider}/>
                </div>
                {
                    this.props.cart.length === 0 &&
                    <div>
                        <Typography variant="body2">Aucune tisane créée</Typography>
                    </div>
                }

                {this.props.cart.map((item, idx)=>{
                    let divider = <Divider className={classes.cartDivider} variant="inset"/>;
                    if (idx === this.props.cart.length - 1)
                        divider = [];
                    return (
                        <React.Fragment>
                            <div className={classes.itemWrapper}>
                                <CartItem item={item}/>
                            </div>
                            {divider}
                        </React.Fragment>
                    )
                })}
                <Divider className={classes.cartDivider}/>
                <Grid container spacing={2} className={classes.secondPart}>
                    <Grid item xs={6}>
                        <form>
                            <Typography variant="body2">Zone de livraison</Typography>
                            <FormControl fullWidth="true" className={classes.formControl}>
                                <Select
                                    value={this.props.deliveryZone}
                                    labelId="delivery-zone-label" id="delivery-zone"

                                    onChange={(e) => {this.handleDeliveryPrice(e)}}>
                                        {this.props.deliveryPriceByZone.length > 0 &&
                                            this.props.deliveryPriceByZone.map(item=>
                                            <MenuItem value={item.id}>{item.zone}</MenuItem>
                                                )
                                        }
                                </Select>
                            </FormControl>
                            {this.props.deliveryFranco &&
                            <Typography variant="body2" color="secondary">Frais de port offerts à partir de {this.props.deliveryFranco}€</Typography>}
                        </form>
                    </Grid>
                    <Grid item xs={6}>
                        {
                            this.props.plants.length > 0 ? (
                                <React.Fragment>
                                    <Typography className={classes.totalTable}>
                                        <span className={classes.subTotal}>Vos tisanes</span>
                                        <span className={classes.price}>{this.props.subTotal}€</span>
                                    </Typography>
                                    <Typography className={classes.totalTable}>
                                        <span className={classes.subTotal}>Livraison</span>
                                        <span className={classes.price}>{this.props.deliveryPrice}€</span>
                                    </Typography>
                                    <Typography className={classes.totalTablePrice}>
                                        <span className={classes.total}>Total</span>
                                        <span className={classes.totalprice}>{ this.props.total }€</span>
                                    </Typography>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Typography className={classes.totalTable}>Chargement...</Typography>
                                    <Typography className={classes.totalTable}>Chargement...</Typography>
                                    <Typography className={classes.totalTable}>Chargement...</Typography>
                                </React.Fragment>
                            )
                        }

                    </Grid>
                </Grid>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state)=>{
    return{
        plants: state.cartReducer.plants,
        cart : state.cartReducer.cart,
        subTotal: state.cartReducer.subTotal,
        deliveryPriceByZone: state.cartReducer.deliveryPriceByZone,
        deliveryZone: state.cartReducer.deliveryZone,
        deliveryPrice: state.cartReducer.deliveryPrice,
        deliveryFranco: state.cartReducer.deliveryFranco,
        total: state.cartReducer.total
        }
}


export default connect(mapStateToProps)(withStyles(styles)(cartValidation));
