import React, { Component } from "react";
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import { Link } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import { plantService } from '../services/plant.service'
import { userService } from '../services/user.service';

const styles = theme => ({
    eyeIcon: {
        color: theme.palette.primary.main,
    },
    newPlant: {
        margin: theme.spacing(1),
    }
})


class AdminPlants extends Component {

    columns = [
        { id: 'name', label: 'Nom', minWidth: 120 },
        { id: 'latin_name', label: 'Nom latin', minWidth: 120 },
        {
            id: 'plant_type',
            label: 'Type',
            minWidth: 100,
            align: 'right',

        },
        {
            id: 'plant_part',
            label: 'Partie',
            minWidth: 100,
            align: 'right',
            format: (value) => value,
          },
        {
          id: 'is_fr',
          label: 'Origine FR',
          minWidth: 100,
          align: 'right',
        },
        {
          id: 'is_bio',
          label: 'Bio',
          minWidth: 100,
          align: 'right',
        },
        {
          id: 'density',
          label: 'Densité',
          minWidth: 50,
          align: 'right',
        },
        {
          id: 'price',
          label: 'Prix (€/g)',
          minWidth: 50,
          align: 'right',
        },
        {
            id: 'visible',
            label: 'Visible',
        },
        {
            id: 'edit',
            label: '',
        },
    ];

    getPlantType(value) {
        const mapping = {0: 'Tisane', 1: 'Deco', 2:'Sweet'};
        return mapping[value]
    }

    handleToggleVisible = async (event, id) => {
        const plant = await userService.togglePlantVisibility(id);
        const newIcon = this.get_icon(plant.visible);
        ReactDOM.render(newIcon, document.getElementById('visible-icon-' + id));
    }

    get_icon(value) {
        const classes = this.props.classes;
        if (value){
            return <VisibilityIcon className={classes.eyeIcon}/>;
        }
        else {
            return <VisibilityOffIcon />;
        }
    }

    getVisibilityIcon(value, id) {
        let icon = [];
        if (value){
            icon = <IconButton id={'visible-icon-' + id} onClick={(e)=> {this.handleToggleVisible(e, id)}}>{this.get_icon(value)}</IconButton>;
        }
        else {
            icon = <IconButton id={'visible-icon-' + id} onClick={(e)=> {this.handleToggleVisible(e, id)}}>{this.get_icon(value)}</IconButton>;
        }
        return icon
    }

    getEditIcon(id) {
        return (<Link to={"/admin/plants/edit/" + id}>
            <IconButton><EditOutlinedIcon /></IconButton></Link>);
    }


    createData(plant) {
        const is_fr = plant.fr_origin ? 'Yes' : 'No';
        const is_bio = plant.is_bio ? 'Yes' : 'No';
        const visible = this.getVisibilityIcon(plant.visible, plant.id);
        const edit = this.getEditIcon(plant.id)
        return {
            name: plant.name,
            latin_name: plant.latin_name,
            plant_part: plant.plant_part,
            is_fr: is_fr,
            is_bio: is_bio,
            density: plant.density,
            price: plant.price,
            edit: edit,
            visible: visible,
            plant_type: this.getPlantType(plant.plant_type)
        }};

    componentDidMount() {
        plantService.getAdminPlants()
            .then(res => this.setState({plants: res}))
    }

    constructor(props) {
        super(props);
        this.state = {
            plants: []
        }
    }


    render() {
        const classes = this.props.classes
        const rows = [];
        if (this.state.plants.length === 0 )
            return <span></span>
        for (var i = 0; i < this.state.plants.length; i++){
            const plant = this.state.plants[i];
            rows.push(this.createData(plant));
        }
        return (
            <Container maxWidth="lg">
                <Button href="/admin/plants/edit/" color="primary" variant="contained" className={classes.newPlant}>Nouvelle plante</Button>
                <Button href="/admin/" variant="contained" className={classes.newPlant}>Retour</Button>
                <Paper className={classes.root}>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {this.columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row) => {
                                return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {this.columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align} className={column.className}>
                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                        </TableCell>
                                    );
                                    })}
                                </TableRow>
                                );
                            })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Container>
        );
    }
}


export default withStyles(styles)(AdminPlants);
