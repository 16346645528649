import React from "react";

class Thumb extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            thumb: undefined,
            img_url: props.img_url,
          };
    }

    componentDidMount(){
        if (this.state.img_url){
            this.setState({ loading: true });
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            }
            fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}${this.state.img_url}`, requestOptions)
            .then(response => response.blob())
            .then(blob => {
                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    this.setState({ loading: false, thumb: reader.result });
                };
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.file) { return; }

        this.setState({ loading: true }, () => {
            let reader = new FileReader();

            reader.onloadend = () => {
            this.setState({ loading: false, thumb: reader.result });
            };

            reader.readAsDataURL(nextProps.file);
        });
    }

    render() {
        const { file, img_url } = this.props;
        const { loading, thumb } = this.state;

        if (!file && !img_url) { return null; }

        if (loading) { return <p>loading...</p>; }

        return (<img src={thumb}
            alt=''
            className="img-thumbnail mt-2"
            height={200}
            width={200} />);
    }
}

export default Thumb;
