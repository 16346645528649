import { ADD_DECO, ADD_FILTER, ADD_OFFICIAL_TISANE, ADD_SWEETS, ADD_TO_CART, ADD_TO_TISANE, DELETE_ITEM, DOWN_QUANTITY, EDIT_ITEM, RECEIVE_DELIVERIES, RECEIVE_PLANTS, RECEIVE_WEIGHTS, REMOVE_FILTER, REMOVE_PLANT, REQUEST_DELIVERIES, REQUEST_PLANTS, REQUEST_WEIGHTS, RESET_CART, RESET_NOTIFICATION, SET_DELIVERY_METHOD, SET_DELIVERY_PRICE, UPDATE_MAX_PLANTS, UPDATE_TISANE_NAME, UP_QUANTITY } from './action-types/cart-actions'

import { adminService } from '../services/admin.service'
import { plantService } from '../services/plant.service'

export const addToTisane = (plant_id)=>{
    return {
        type: ADD_TO_TISANE,
        plant_id
    }
}
export const updateTisaneName = (name) => {
    return {
        type: UPDATE_TISANE_NAME,
        name
    }
}

export const resetCart = () => {
    return {
        type: RESET_CART,
    }
}

export const resetNotification = () => {
    return {
        type: RESET_NOTIFICATION,
    }
}

export const updateMaxPlants = (weight) => {
    return {
        type: UPDATE_MAX_PLANTS,
        weight
    }
}

export const addToCart = () => {
    return {
        type: ADD_TO_CART,
    }
}

export const addOfficialTisane = (tisane) => {
    return {
        type: ADD_OFFICIAL_TISANE,
        tisane: tisane,
    }
}

export const upQuantity = (tisane_id) => {
    return {
        type: UP_QUANTITY,
        tisane_id
    }
}

export const downQuantity = (tisane_id) => {
    return {
        type: DOWN_QUANTITY,
        tisane_id
    }
}

export const editItem = (tisane_id) => {
    return {
        type: EDIT_ITEM,
        tisane_id
    }
}

export const deleteItem = (tisane_id) => {
    return {
        type: DELETE_ITEM,
        tisane_id
    }
}

export const removePlant = (plant_id) => {
    return {
        type: REMOVE_PLANT,
        plant_id
    }
}

export const requestPlants = () => {
    return {
        type: REQUEST_PLANTS,
    }
}

export const receivePlants = (json) => {
    return {
        type: RECEIVE_PLANTS,
        plants: json
    }
}

export const checkCartValid = () =>{
    return {
        type: 'CHECK_CART_VALID',
    }
}

export const addSweets = (item_id) => {
    return {
        type: ADD_SWEETS,
        item_id: item_id,
    }
}

export const addDeco = (item_id) => {
    return {
        type: ADD_DECO,
        item_id: item_id,
    }
}

export const setDeliveryMethod = (deliveryMethod) => {
    return {
        type: SET_DELIVERY_METHOD,
        deliveryMethod: deliveryMethod,
    }
}

export const setDeliveryPrice = (deliveryZone) => {
    return {
        type: SET_DELIVERY_PRICE,
        deliveryZone: deliveryZone,
    }
}

export const addFilter = (key, value) => {
    return {
        type: ADD_FILTER,
        key: key,
        value: value,
    }
}

export const removeFilter = (key, value) => {
    return {
        type: REMOVE_FILTER,
        key: key,
        value: value,
    }
}

// BEGINING Fetch plants

const fetchPlants = () => dispatch => {
    dispatch(requestPlants())
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/plants/`, requestOptions)
          .then(res => res.json())
          .then(json => {
              dispatch(receivePlants(json));
              dispatch(checkCartValid());})
          .catch(err => {
            console.log(err);
          });
}

// const shouldFetchPlants = (state) => {
//     const plants = state.cartReducer.plants;
//     const isFetchingPlants = state.isFetchingPlants;
//     if (!plants.length) {
//         return true
//     }
//     if (isFetchingPlants) {
//         return false
//     }
// }

export const fetchPlantsIfNeeded = () => (dispatch, getState) => {
    // Allways request plants.
    return dispatch(fetchPlants())
}

// END Fetch plants

// BEGIN FETCH WEIGHTS


export const requestWeights = () => {
    return {
        type: REQUEST_WEIGHTS,
    }
}

export const receiveWeights = (json) => {
    return {
        type: RECEIVE_WEIGHTS,
        weights: json
    }
}

const fetchWeights = () => dispatch => {
    dispatch(requestWeights())
    return plantService.getWeights()
            .then(json => dispatch(receiveWeights(json)))
}

const shouldFetchWeights = (state) => {
    const weights = state.cartReducer.availableWeightsOptions
    const isFetchingWeights = state.isFetchingWeights
    if (!weights.length) {
        return true
    }
    if (isFetchingWeights) {
        return false
    }
}

export const fetchWeightsIfNeeded = () => (dispatch, getState) => {
    if (shouldFetchWeights(getState())){
        return dispatch(fetchWeights())
    }
}

// END FETCH WEIGHTS

export const requestDeliveries = () => {
    return {
        type: REQUEST_DELIVERIES,
    }
}

export const receiveDeliveries = (json) => {
    return {
        type: RECEIVE_DELIVERIES,
        deliveries: json
    }
}

const fetchDeliveries = () => dispatch => {
    dispatch(requestDeliveries())
    return adminService.getDeliveryItems()
            .then(json => dispatch(receiveDeliveries(json)))
}

const shouldFetchDeliveries = (state) => {
    const deliveries = state.cartReducer.deliveryPriceByZone
    const isFetchingDeliveries = state.cartReducer.isFetchingDeliveries
    if (deliveries.length) {
        return false
    }
    if (isFetchingDeliveries) {
        return false
    }
    return true
}


export const fetchDeliveriesIfNeeded = () => (dispatch, getState) => {
    if (shouldFetchDeliveries(getState())){
        return dispatch(fetchDeliveries())
    }
}
