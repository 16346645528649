import React, { Component } from "react";

import Button from '@material-ui/core/Button';
import CartAdress from "./cartAddress"
import CartValidation from './CartValidation'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { alertActions } from '../actions/alert.actions.js';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { userService } from "../services/user.service";
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    container: {
        margin: theme.spacing(2, 'auto'),
    },
    price: {
        float: 'right'
    },
    actionButtons: {
        // margin: theme.spacing(2, 0),
    },
    paymentButton: {
        fontSize: '16px',
        fontWeight: '600',
        float: 'right',
        color: 'white',
    },
})

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.



class cartValidationPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isToggleOn: true,
            stripePromise: loadStripe('pk_live_4CQxzafL8mfn2t3xEw3yQQP600L6XP67rp'),
        };

        this.addressState = React.createRef();

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = async (event) => {
        if (this.props.cart.length === 0){
            const { dispatch } = this.props;
            dispatch(alertActions.error("Votre panier est vide, commencez par créer une tisane."))
            return;
        }
        event.preventDefault();
        // Call your backend to create the Checkout session.
        let delivery_id = {
            'deliveryZone': this.props.deliveryZone}
        let { livAddress, factuAddress, errors1, errors2, saveAddress } = this.addressState.current.submitForm(event)
        if (Object.keys(errors1).length || Object.keys(errors2).length) return;

        let address = {livAddress: livAddress, factuAddress: factuAddress, saveAddress: saveAddress}

        const { sessionId } = await userService.checkUserCart(this.props.cart, delivery_id, address)
        // // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await this.state.stripePromise;
        const { stripeerror } = await stripe.redirectToCheckout({
        sessionId,
        });
        console.log(stripeerror);
    }

    render() {
        const classes = this.props.classes;
        return (
            <Container className={classes.container}>
                <Grid container direction="column" spacing={1}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Paper className={classes.cartSection}>
                                <CartValidation />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper className={classes.cartSection}>
                                <CartAdress ref={this.addressState}/>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <div className={classes.actionButtons}>
                            <Button variant="contained" href="/">Retour</Button>
                            <Button role="link" variant="contained" color="primary" onClick={this.handleClick} className={classes.paymentButton} size="large">Paiement</Button>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = (state)=>{
    return{
        cart : state.cartReducer.cart,
        deliveryMethod: state.cartReducer.deliveryMethod,
        deliveryZone: state.cartReducer.deliveryZone,
        }
}

export default connect(mapStateToProps)(withStyles(styles)(cartValidationPage));
