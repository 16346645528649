import 'react-multi-carousel/lib/styles.css';

import React, { Component } from "react";
import { fetchPlantsIfNeeded, fetchWeightsIfNeeded } from '../actions/cartActions.jsx'

import AppDescription from './AppDescription'
import Basket from './Basket'
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import PlantSearch from './PlantSearch'
import Plants from './Plants'
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    formControl: {
        minWidth: 120,
    },
    descriptionSection: {
        paddingBottom: '0em',
    },
    stickybasket: {
        position: 'sticky',
        top: '0',
        marginBottom: theme.spacing(1),
    },
    appDescription: {
        background: 'white',
        padding: theme.spacing(3, 0),
    },
    tisaneList: {
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    content: {
        marginTop: theme.spacing(2),
    },

})


class PlantList extends Component {

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(fetchPlantsIfNeeded())
        dispatch(fetchWeightsIfNeeded())
    }

    render() {
        const classes = this.props.classes;
        return (
            <React.Fragment>
                <div className={classes.appDescription}>
                    <Container>
                            <AppDescription />
                    </Container>
                </div>
                <Divider />

                <Container className={classes.content}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        spacing={2}>
                        <Grid container item sm={7} md={9} spacing={1}>
                            <PlantSearch />
                            <Plants/>
                        </Grid>
                        <Grid item sm={5} md={3} className={classes.stickybasket}>
                            <Hidden xsDown>
                                <Basket mobile={false}/>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Container>
            </React.Fragment>
        );
    }
};


export default connect(null)(withStyles(styles)(PlantList));
