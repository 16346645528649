import * as yup from 'yup';

import { Button, TextField, Typography } from "@material-ui/core";
import React, { Component } from "react";

import Container from '@material-ui/core/Container';
import { Formik } from "formik";
import { alertActions } from '../actions/alert.actions.js';
import { connect } from 'react-redux';
import { history } from '../helpers/history';
import { userService } from "../services/user.service";
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    passwordReinitialize: {
        padding: theme.spacing(6, 2)
    },
    form: {
        width: '100%',
    },
    submitButton: {
        margin: theme.spacing(3, 0)
    }
})


class PasswordResetForm extends Component {

    passwordSchema = yup.object().shape({
        password: yup.string()
            .required('Obligatoire')
            .min(8, 'Mot de passe trop court - doit être au moins de 8 caractère.')
    })

    constructor(props) {
        super(props);
        this.state = {
            'token' : this.props.match.params.token,
        }
    }



    render() {
        const classes = this.props.classes;
        return (
            <Container maxWidth="xs" className={classes.passwordReinitialize}>
                <Typography variant="h6">Réinitialisation de votre mot de passe</Typography>
                <Typography variant="body1">
                    Veuillez préciser votre nouveau mot de passe.
                </Typography>
                <Formik
                    initialValues={{password: ''}}
                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(false);
                        userService.resetPassword(JSON.stringify(values), this.state.token)
                        .then(history.push('/login/false'))
                        .then(this.props.success("Votre mot de passe a été modifié avec succes"))
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <form onSubmit={handleSubmit} className={classes.form} novalidate>
                                <TextField
                                    error={errors.password && touched.password}
                                    helperText={errors.password}
                                    // type="password"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    variant="outlined"
                                    label="password" />

                            <div>
                                <Button type="submit" variant="contained" disabled={isSubmitting} className={classes.submitButton}>
                                Continuer
                                </Button>
                            </div>
                          </form>
                        )}
                </Formik>
            </Container>
        );
    }
}

const mapDispatchToProps = {
    success: alertActions.success,
    error: alertActions.error,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(PasswordResetForm));
