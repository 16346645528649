import React, { Component } from "react";

import Container from '@material-ui/core/Container';
import FacebookIcon from '@material-ui/icons/Facebook';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import InstagramIcon from '@material-ui/icons/Instagram';
import { Typography } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    footer: {
        background: `linear-gradient(to bottom, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
        width: '100%',
    },
    linkContainer: {
        textAlign: "center",
        margin: theme.spacing(2, 0)
    },
    linkContainerMobile: {
        textAlign: "center",
        margin: theme.spacing(1, 0)
    },
    smallMarginlinkContainerMobile: {
        textAlign: "center",
        margin: theme.spacing(0.5, 0)
    },
    footerLink: {
        margin: theme.spacing(0, 1),
        color: theme.palette.text.primary,
    },
    socialLink: {
        margin: theme.spacing(0, 3),
        color: theme.palette.text.primary,
    },
    black: {
        color: theme.palette.text.primary,
    },
    blackMobile: {
        color: theme.palette.text.primary,
        textAlign:'center',
    }
})


class Footer extends Component {

    render() {
        const classes = this.props.classes;
        return (
                <footer className={classes.footer}>
                    <Container>
                        <Hidden xsDown>
                            <Grid container justify="center" alignItems="center">
                                <Grid item xs={3}>
                                    <Typography className={classes.black}>© Rontheo</Typography>
                                </Grid>
                                <Grid item xs={6} className={classes.linkContainer}>
                                    <a href="https://www.facebook.com/tisaneo.fr/" className={classes.socialLink} target="_blank" rel="noopener noreferrer"><FacebookIcon fontSize="large"/></a>
                                    <a href="https://www.instagram.com/tisaneo.fr/" rel="noopener noreferrer" target="_blank" className={classes.socialLink}><InstagramIcon fontSize="large"/></a>
                                </Grid>
                                <Grid item xs={3} className={classes.linkContainer}>
                                    <a href="/about" className={classes.footerLink}>À propos</a>
                                    <a href="/contact" className={classes.footerLink}>Contact</a>
                                    <a href="/cgv" className={classes.footerLink}>CGV</a>
                                </Grid>
                            </Grid>
                        </Hidden>
                        <Hidden smUp>
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item xs={12} className={classes.linkContainerMobile}>
                                    <a href="https://www.facebook.com/tisaneo.fr/" className={classes.socialLink} target="_blank" rel="noopener noreferrer"><FacebookIcon /></a>
                                    <a href="https://www.instagram.com/tisaneo.fr/" className={classes.socialLink} target="_blank" rel="noopener noreferrer"><InstagramIcon /></a>
                                </Grid>
                                <Grid item xs={12} className={classes.smallMarginlinkContainerMobile}>
                                    <a href="/about" className={classes.footerLink}>À propos</a>
                                    <a href="/contact" className={classes.footerLink}>Contact</a>
                                    <a href="/cgv" className={classes.footerLink}>CGV</a>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.blackMobile}>© Rontheo</Typography>
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Container>
                </footer>
        );
    }
}

export default withStyles(styles)(Footer);
