import React, { Component } from "react";

import Grid from '@material-ui/core/Grid';
import Plant from './Plant'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
})


class Plants extends Component {

    plantFilter(obj){
        if (obj.filtered === undefined || obj.filtered === true)
            return true
        return false
    }

    render() {
        const plants = this.props.plants.filter(this.plantFilter)
        if (plants.length === 0){
            return (
                <React.Fragment>
                    Aucune plante ne correspond à votre recherche
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                {plants.map(plant => {
                    return (
                        <Grid item xs={12} md={6} lg={4}>
                            <Plant plant={plant} key={plant.id}/>
                        </Grid>
                    )
                })}
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state)=>{
    return {plants: state.cartReducer.plants}}

export default connect(mapStateToProps)(withStyles(styles)(Plants));
