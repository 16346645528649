import { ADD_DECO, ADD_FILTER, ADD_OFFICIAL_TISANE, ADD_SWEETS, ADD_TO_CART, ADD_TO_TISANE, DELETE_ITEM, DOWN_QUANTITY, EDIT_ITEM, RECEIVE_DELIVERIES, RECEIVE_PLANTS, RECEIVE_WEIGHTS, REMOVE_FILTER, REMOVE_PLANT, REQUEST_DELIVERIES, REQUEST_PLANTS, REQUEST_WEIGHTS, RESET_CART, RESET_NOTIFICATION, SET_DELIVERY_PRICE, UPDATE_MAX_PLANTS, UPDATE_TISANE_NAME, UP_QUANTITY } from '../actions/action-types/cart-actions'

const initState = {
    // General info. I think it should not be in the reducer. I'll leave it here for now
    // They are static and never changed


    // state of plant fetching. True if we are currently fetching plants
    // items will hold the array of possible plants to choose from
    isFetchingPlants: false,
    plants: [],
    deco: [],
    sweets: [],


    // State of weight fetching.
    isFetchingWeights: false,
    availableWeightsOptions: [],
    weightByMaxPlants: {},
    maxPlantsByWeight: {},

    // Descript current tisane (the one the user is currently building)
    name: '',
    weight: '',
    maxPlants: 7,
    plantsAdded: 0, // Number of plants currently added
    tisane_price: 0,
    addedItems: [], // Plants added
    quantity: 1,
    decoAdded: [],
    sweetAdded: [],
    cartNotification: 0,

    // delivery management
    deliveryMethod: 'colissimo',
    deliveryZone: 1,

    isFetchingDeliveries: false,
    deliveryPriceByZone : [],
        // 'france': 8,
        // 'eu_1': 9,
        // 'eu_2': 11,
        // 'overseas': 11,
        // 'world': 25
    // When a tisane is created it is moved to the cart and everything to descript current tisane is reset
    cartValid: false,
    cart: [],
    subTotal: 0,
    deliveryPrice: 8,
    deliveryFranco: 70,
    total: 0,

    extraPrice: 0.75,

    nameSearch: '',
    tasteSearch: '',
    propertySearch: '',
}


function intersection(nameHits, tasteHits, propertyHits) {
	var result = [];
    var lists = [nameHits, tasteHits, propertyHits];

    for(var i = 0; i < lists.length; i++) {
        var currentList = lists[i];
        for(var y = 0; y < currentList.length; y++) {
            let currentValue = currentList[y];
        if(result.indexOf(currentValue) === -1) {
            if(lists.filter(function(obj) { return obj.indexOf(currentValue) === -1 }).length === 0) {
            result.push(currentValue);
            }
        }
        }
    }
  return result;
}


function getAvailableWeightOptions(plants, addedItems, plantsAdded, maxPlantsByWeight) {
    // Returns an array with available weight options.
    let availableWeightsOptions = [];
    for (var key in maxPlantsByWeight){
        if (parseInt(key) >= plantsAdded){
            availableWeightsOptions = availableWeightsOptions.concat(maxPlantsByWeight[key]);
        }
    }
    let heavyPlants = 0;
    for (let plant_idx=0; plant_idx < addedItems.length; plant_idx++) {
        let density = plants.find(item => item.id === addedItems[plant_idx]).density
        if (density === 1){
            heavyPlants += 1;
        }
    }
    if (heavyPlants >= 1){
        let min = 100;
        if (heavyPlants >= 4)
            min = 200
        availableWeightsOptions = availableWeightsOptions.map(x => +x).filter(x => x>=min)
    }
    availableWeightsOptions = availableWeightsOptions.map(x => x.toString())
    return availableWeightsOptions
}

function round5(x)
{
    return (x % 5) >= 2.5 ? parseInt(x / 5) * 5 + 5 : parseInt(x / 5) * 5;
}

function getTisanePrice(plants, weight, addedItems, sweetsAdded, decoAdded){
    if (!plants.length){
        return 0
    }
    // Returns tisane price depending on the total density
    if (!weight || addedItems.length === 0)
        return 0
    let density = 0;
    for (let i=0; i<addedItems.length; i++) {
        const plant = plants.find(item => item.id === addedItems[i])
        density += (1 / plant.density);
    }
    let tisane_price = 0;

    if (addedItems.length){
        const plantQuantity = {}
        for (let a=0; a< addedItems.length - 1; a++){
            // Round quantity to nearest 5g
            const plant = plants.find(item => item.id === addedItems[a])
            plantQuantity[plant.id] = round5((((1/density)/plant.density) * weight))
        }
        const plant = plants.find(item => item.id === addedItems[addedItems.length - 1])
        plantQuantity[plant.id] = (weight - Object.values(plantQuantity).reduce((a, b) => a + b, 0))
        for(let i = 0; i < addedItems.length; i++) {
            const plant = plants.find(item => item.id === addedItems[i])
            let quantity = plantQuantity[plant.id];
            tisane_price += quantity * plant.price
        }
    }

    // We round the price of the tisane and then we add the price of the deco
    // const price_rounded = tisane_price*.toFixed(1)

    const priceWithExtra = (Math.ceil(tisane_price * 4) / 4) + ((0.75 * sweetsAdded.length) + (0.75 * decoAdded.length));
    return priceWithExtra.toFixed(2);
}

function getSubTotalPrice(plants, cart) {
    let subTotal = 0;
    for (var i=0; i<cart.length; i++) {
        let tisane = cart[i];
        subTotal += getTisanePrice(plants, tisane.weight, tisane.addedItems, tisane.sweetAdded, tisane.decoAdded) * tisane.quantity
    }
    return subTotal
}

function getTotalPrice(plants, cart, deliveryPrice, deliveryFranco) {
    let subTotal = getSubTotalPrice(plants, cart);
    let total = subTotal;
    if (deliveryFranco === null || subTotal < deliveryFranco){
        total = deliveryPrice + subTotal;
    }
    return { total, subTotal }
}

function normalizeString(string){
    // Everything in lowcase + no accent
    if (string == null){
        return ''
    }
    string = string.toLowerCase()
    string = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    return string
}

function filterPlantsByName(plants, value){
    if (value === '')
        return plants.map(item => item.id)
    let hit_ids = []
    value = normalizeString(value)
    for (let i=0; i<plants.length; i++) {
        let plantName = normalizeString(plants[i].name)
        let english = normalizeString(plants[i].english_name)
        let synonyme = normalizeString(plants[i].synonyme_name)
        let latinPlantName = normalizeString(plants[i].latin_name)
        if (plantName.includes(value) ||latinPlantName.includes(value) || english.includes(value) || synonyme.includes(value))
            hit_ids.push(plants[i].id)
    }
    return hit_ids
}

function filterPlantsByTag(plants, key, value) {
    if (value === '' || key === null)
        return plants.map(item => item.id)
    let hit_ids = []
    for (let i=0; i<plants.length; i++) {
        if (plants[i][[key]].includes(value)){
            hit_ids.push(plants[i].id)
        }
    }
    return hit_ids
}


function removeItem(array, index) {
    return array.filter((item, idx) => idx !== index)
  }


const cartReducer= (state = initState, action)=>{
    //INSIDE HOME COMPONENT
    if (action.type === RESET_CART){
        return Object.assign({},
            {...initState}, {
                plants: state.plants, deco: state.deco, sweets: state.sweets,
                weightByMaxPlants: state.weightByMaxPlants,
                maxPlantsByWeight: state.maxPlantsByWeight})
    }
    if (action.type === REQUEST_PLANTS){
        return {...state, isFetchingPlants: true, cartValid: false}
    }
    if (action.type === RECEIVE_PLANTS) {
        let plants = action.plants.filter(plant => plant.plant_type===0);
        let deco = action.plants.filter(plant => plant.plant_type===1);
        let sweets = action.plants.filter(plant => plant.plant_type===2);
        return {
            ...state, isFetchingPlants: false, plants: plants,
            deco: deco, sweets: sweets}
    }
    if (action.type === REQUEST_WEIGHTS) {
        return {...state, isFetchingWeights: true}
    }
    if (action.type === RECEIVE_WEIGHTS) {
        if(!action.weights.length)
            return {...state}
        const weightByMaxPlants = {}
        const maxPlantsByWeight= {}
        for (var i=0; i<action.weights.length; i++){
            weightByMaxPlants[action.weights[i].value] = action.weights[i].maxPlants
        }

        for (var j=0; j<action.weights.length; j++){
            if (Array.isArray(maxPlantsByWeight[action.weights[j].maxPlants])){
                maxPlantsByWeight[action.weights[j].maxPlants].push(action.weights[j].value)
            }
            else {
                maxPlantsByWeight[action.weights[j].maxPlants] = [action.weights[j].value]
            }
        }

        return {...state, isFetchingWeights: false, weightByMaxPlants: weightByMaxPlants, maxPlantsByWeight: maxPlantsByWeight}
    }
    if (action.type === RECEIVE_DELIVERIES){
        return {...state, isFetchingDeliveries: false, deliveryPriceByZone: action.deliveries, deliveryPrice: action.deliveries[0].price, deliveryFranco: action.deliveries[0].franco_price}
    }
    if (action.type === REQUEST_DELIVERIES){
        return {...state, isFetchingDeliveries: true}
    }
    if (action.type === RESET_NOTIFICATION) {
        return {...state, cartNotification: 0}
    }
    if (action.type === 'CHECK_CART_VALID') {
        let tisaneToDelete = [];
        let newCart = state.cart;
        // Check if created tisane have all plantes visible.
        // If not, discard the tisane
        for (var idx_tisane = 0; idx_tisane < state.cart.length; idx_tisane++) {
            // Check plantes
            for (var k = 0; k < state.cart[idx_tisane].addedItems.length; k++) {
                let addedPlant = state.cart[idx_tisane].addedItems[k];
                let found = false;
                for (var l = 0; l < state.plants.length; l++){
                    if (addedPlant === state.plants[l].id){
                        found = true;
                        break
                    }
                }
                if (!found){
                    if (tisaneToDelete.indexOf(state.cart[idx_tisane].tisane_id)){
                        tisaneToDelete.push(state.cart[idx_tisane].tisane_id)
                    }
                }
            }

            // deco
            for (var r = 0; r < state.cart[idx_tisane].decoAdded.length; r++){
                let decoAdded = state.cart[idx_tisane].decoAdded[r];
                let found = false;
                for (var e = 0; e < state.deco.length; e++){
                    if (decoAdded === state.deco[e].id){
                        found = true;
                        break;
                    }
                }
                if (!found){
                    if (tisaneToDelete.indexOf(state.cart[idx_tisane].tisane_id)){
                        tisaneToDelete.push(state.cart[idx_tisane].tisane_id)
                    }
                }
            }

            // sweets
            for (var a = 0; a < state.cart[idx_tisane].sweetAdded.length; a++){
                let sweetAdded = state.cart[idx_tisane].sweetAdded[a];
                let found = false;
                for (var w = 0; w < state.sweets.length; w++){
                    if (sweetAdded === state.sweets[w].id){
                        found = true;
                        break;
                    }
                }
                if (!found){
                    if (tisaneToDelete.indexOf(state.cart[idx_tisane].tisane_id)){
                        tisaneToDelete.push(state.cart[idx_tisane].tisane_id)
                    }
                break;
                }
            }
        }

        newCart = state.cart;
        for (let h = 0; h < tisaneToDelete.length; h++){
            let idx = newCart.findIndex(item => item.tisane_id === tisaneToDelete[h])
            newCart = removeItem(newCart, idx)
        }

        // Check if current tisane have all plantes visible.
        // If not, discard the current tisane
        let addedItems = state.addedItems;
        let maxPlants = state.maxPlants;
        let weight = state.weight;
        let plantsAdded = state.plantsAdded;
        let quantity = state.quantity;
        let decoAdded = state.decoAdded;
        let sweetAdded = state.sweetAdded;
        let tisane_price = state.tisane_price;
        for (var m=0; m < addedItems.length; m++){
            var found = false;
            let addedPlant = addedItems.length;
            for (var n = 0; n < state.plants.length; n++){
                if (addedPlant === state.plants[n].id){
                    found = true;
                    break;
                }
            }
            if (!found){
                addedItems = [];
                maxPlants = 7;
                weight = '';
                plantsAdded = 0;
                quantity = 1;
                decoAdded = [];
                sweetAdded = [];
                tisane_price = 0;
                break;
            }
        }
        // check if deco still available. If not discard the deco. Same for sweets
        for (let n=0 ; n<decoAdded.length ; n++){
            var found = false;
            for (var o = 0; o < state.plants.length; o++){
                if (decoAdded[n] === state.plants[o].id){
                    found = true;
                    break;
                }
            }
        }
        if (!found){
            decoAdded = [];
        }
        for (let p=0 ; p<sweetAdded.length ; p++){
            var found = false;
            for (var q = 0; q < state.plants.length; q++){
                if (decoAdded[p] === state.plants[q].id){
                    found = true;
                    break;
                }
            }
        }
        if (!found){
            sweetAdded = [];
        }

        return {
            ...state, cart: newCart, cartValid: true, addedItems: addedItems,
            maxPlants: maxPlants, weight: weight, plantsAdded: plantsAdded,
            quantity: quantity, decoAdded: decoAdded, sweetAdded: sweetAdded,
            tisane_price: tisane_price}
    }
    if (action.type === ADD_FILTER) {
        let plants = state.plants;
        let nameHits = []
        let tasteHits = []
        let propertyHits = []
        if (action.key === 'plantName')
            state.nameSearch = action.value
        nameHits = filterPlantsByName(plants, state.nameSearch)
        if (action.key === 'taste_tags')
            state.tasteSearch = action.value
        tasteHits = filterPlantsByTag(plants, 'taste_tags', state.tasteSearch)
        if (action.key === 'property_tags')
            state.propertySearch = action.value
        propertyHits = filterPlantsByTag(plants, 'property_tags', state.propertySearch)
        let hits = intersection(nameHits, tasteHits, propertyHits)

        for (let i=0; i<plants.length; i++) {
            if (hits.includes(plants[i].id))
                plants[i].filtered = true;
            else
                plants[i].filtered = false;
        }
        return {...state, plants: [...plants]}
    }

    if (action.type === REMOVE_FILTER) {
        const plants = state.plants;
        return {...state, plants: plants}
    }
    if (action.type === ADD_SWEETS){
        let addedItem = state.sweets.find(item=> item.id === action.item_id);
        if (state.sweetAdded.includes(addedItem.id)){
            const idxToRemove = state.sweetAdded.findIndex(el => el === addedItem.id)
            const sweetAdded = [...state.sweetAdded.slice(0, idxToRemove), ...state.sweetAdded.slice(idxToRemove + 1)]
            let tisane_price = getTisanePrice(state.plants, state.weight, state.addedItems, sweetAdded, state.decoAdded);
            return {...state, sweetAdded: sweetAdded, tisane_price: tisane_price}
        }
        const sweetAdded = [...state.sweetAdded, addedItem.id]
        let tisane_price = getTisanePrice(state.plants, state.weight, state.addedItems, sweetAdded, state.decoAdded);
        return {...state, sweetAdded: sweetAdded, tisane_price: tisane_price}
    }
    if (action.type === ADD_DECO){
        const addedItem = state.deco.find(item=> item.id === action.item_id);
        // If the deco has already been added, remove it.
        if (state.decoAdded.includes(addedItem.id)){
            const idxToRemove = state.decoAdded.findIndex(el => el === addedItem.id)
            const decoAdded = [...state.decoAdded.slice(0, idxToRemove), ...state.decoAdded.slice(idxToRemove + 1)]
            let tisane_price = getTisanePrice(state.plants, state.weight, state.addedItems, state.sweetAdded, decoAdded);
            return {...state, decoAdded: decoAdded, tisane_price: tisane_price}
        }
        const decoAdded = [...state.decoAdded, addedItem.id]
        let tisane_price = getTisanePrice(state.plants, state.weight, state.addedItems, state.sweetAdded, decoAdded);
        return {...state, decoAdded: decoAdded, tisane_price: tisane_price}
    }
    if (action.type === ADD_TO_TISANE){
        if (state.plantsAdded >= 7)
            return {...state}
        let weight = state.weight;
        if (state.plantsAdded >= state.maxPlants)
                weight = '';

        // Get the item we want to add
        let addedItem = state.plants.find(item=> item.id === action.plant_id);
        // Find if it already has been added
        let existed_item = state.addedItems.includes(action.plant_id)
        if (existed_item) {
            return {...state}
        }
        else {
            state.plantsAdded += 1;
            const addedItems = [...state.addedItems, addedItem.id];
            let tisane_price = getTisanePrice(state.plants, state.weight, addedItems, state.sweetAdded, state.decoAdded);
            let availableWeightsOptions = getAvailableWeightOptions(state.plants, addedItems,
                state.plantsAdded, state.maxPlantsByWeight)
            if (!availableWeightsOptions.includes(state.weight))
                weight = '';
            return {
                ...state,
                weight: weight,
                addedItems: addedItems,
                plantsAdded: state.plantsAdded,
                cartNotification: state.cartNotification + 1,
                availableWeightsOptions: availableWeightsOptions,
                tisane_price: tisane_price,
            }
        }
    }
    if (action.type === REMOVE_PLANT) {
        state.plantsAdded -= 1;
        const addedItems = state.addedItems;
        const index = addedItems.findIndex(el => el === action.plant_id)
        const newCart = [...addedItems.slice(0, index), ...addedItems.slice(index + 1)]
        let tisane_price = getTisanePrice(state.plants, state.weight, newCart, state.sweetAdded, state.decoAdded);
        let availableWeightsOptions = getAvailableWeightOptions(state.plants, newCart,
            state.plantsAdded, state.maxPlantsByWeight)
        return {...state,
            addedItems: newCart,
            availableWeightsOptions: availableWeightsOptions,
            plantsAdded: state.plantsAdded,
            tisane_price: tisane_price,}
    }
    if (action.type === UPDATE_TISANE_NAME){
        state.name = action.name;
        return {...state}
    }
    if (action.type === UPDATE_MAX_PLANTS){
        state.maxPlants = parseInt(state.weightByMaxPlants[action.weight]);
        state.weight = action.weight
        let tisane_price = getTisanePrice(state.plants, state.weight, state.addedItems, state.sweetAdded, state.decoAdded)
        return {...state, tisane_price: tisane_price}
    }
    if (action.type === ADD_OFFICIAL_TISANE) {
        let id = state.cart.length ? (
            Math.max.apply(null, state.cart.map(item => item.tisane_id)) + 1)
            : 1;
        let weight = action.tisane.weight.split('g')[0]
        let price = getTisanePrice(state.plants, weight, action.tisane.tisanePlants, [], [])
        let newCartItem = {
            'name': action.tisane.name,
            'addedItems': action.tisane.tisanePlants,
            'weight': weight,
            'quantity': 1,
            'tisane_id': id,
            'price': price,
            'sweetAdded': [],
            'decoAdded': []
        }
        let cart = [...state.cart, newCartItem]
        let { total, subTotal } = getTotalPrice(state.plants, cart, state.deliveryPrice);
        return {...state, cart: cart, subTotal: subTotal, total: total}
    }
    if (action.type === ADD_TO_CART) {
        if (!state.weight)
            return state
        let id = state.cart.length ? (
            Math.max.apply(null, state.cart.map(item => item.tisane_id)) + 1)
            : 1;

        let newCartItem = {
            'name': state.name,
            'addedItems': state.addedItems,
            'weight': state.weight,
            'quantity': state.quantity,
            'tisane_id': id,
            'price': state.tisane_price,
            'sweetAdded': state.sweetAdded,
            'decoAdded': state.decoAdded
        }
        // Reinitialize current_tisane. There should be a cleaner way. I'll see that next when it works
        let cart = [...state.cart, newCartItem]
        let { total, subTotal } = getTotalPrice(state.plants, cart, state.deliveryPrice);
        return {...state, cart: cart,
            name: '', addedItems: [], maxPlants: 7, weight: '', plantsAdded: 0,
            quantity: 1, subTotal: subTotal, tisane_price:0, decoAdded: [],
            sweetAdded: [], total: total, cartNotification: 0,
        }
    }
    if (action.type === UP_QUANTITY) {
        if (!action.tisane_id){
            return {...state, quantity: state.quantity+=1}
        }
        const newCart = state['cart'].map(
            (content, i) => content.tisane_id === action.tisane_id
                ? {...content, quantity: content.quantity + 1}
                : content)
        let { total, subTotal } = getTotalPrice(state.plants, newCart, state.deliveryPrice);
        return {...state, cart: newCart,
            subTotal: subTotal, total: total}
    }
    if (action.type === DOWN_QUANTITY) {
        if (!action.tisane_id){
            if (state.quantity > 1)
                return {...state, quantity: state.quantity-=1}
            else
                return state
        }
        let tisane = state['cart'].find(item=>item.tisane_id === action.tisane_id);
        if (tisane.quantity > 1){
            const newCart = state['cart'].map(
                (content, i) => content.tisane_id === action.tisane_id
                    ? {...content, quantity: content.quantity - 1}
                    : content)
            let { total, subTotal } = getTotalPrice(state.plants, newCart, state.deliveryPrice);
            return {...state, cart: newCart, subTotal: subTotal, total: total}
        }
        return state
    }
    if (action.type === DELETE_ITEM){
        const cart = state.cart;
        const indexToRemove = state.cart.findIndex(item=> item.tisane_id === action.tisane_id)
        cart.splice(indexToRemove, 1)
        let { total, subTotal } = getTotalPrice(state.plants, cart, state.deliveryPrice);
        return {...state, cart: cart, subTotal: subTotal, total: total}
    }
    if (action.type === EDIT_ITEM){
        const addedItem = state.cart.find(item=> item.tisane_id === action.tisane_id)
        const index = state.cart.findIndex(item=> item.tisane_id === action.tisane_id)
        const newCart = [...state.cart.slice(0, index), ...state.cart.slice(index +1)]
        const maxPlants = parseInt(state.weightByMaxPlants[addedItem.weight]);
        let { total, subTotal } = getTotalPrice(state.plants, newCart, state.deliveryPrice);
        return {
            ...state, cart: newCart, name: addedItem.name, weight: addedItem.weight,
            price: addedItem.price, quantity: addedItem.quantity,
            addedItems: addedItem.addedItems, tisane_price: addedItem.price,
            plantsAdded: addedItem.addedItems.length, maxPlants: maxPlants,
            subTotal: subTotal, decoAdded: addedItem.decoAdded,
            sweetAdded: addedItem.sweetAdded, total: total}
    }

    if (action.type === SET_DELIVERY_PRICE){
        if (state.isFetchingPlants)
            return state
        let deliveryzone = state.deliveryPriceByZone.find(item=>item.id===action.deliveryZone);
        let deliveryPrice = deliveryzone.price;
        let { total, subTotal } = getTotalPrice(state.plants, state.cart, deliveryzone.price, deliveryzone.franco_price)
        if (total === subTotal){
            deliveryPrice = 0
        }
        return {...state,
            deliveryPrice: deliveryPrice,
            deliveryFranco: deliveryzone.franco_price,
            deliveryZone: action.deliveryZone, total: total}
    }
    else {
        return state
    }
}
export default cartReducer;
