import React, { Component } from "react";

import AdminOrderByStep from './AdminOrderByStep'
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

function a11yProps(index) {
return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
};
}


class AdminOrderManagement extends Component {

    constructor(props) {
        super(props)
        this.state = {
            order_filter: 'PAYMENT_RECEIVED',
            orders: [],
            tabId: 1,
        }
    }

    handleChange = (event, tabId) => {
        this.setState({tabId: tabId});
      };

    render() {
        const classes = this.props.classes;
        return (
            <Container>
                <Button href="/admin/" variant="contained" className={classes.newPlant}>Retour</Button>
                <Tabs value={this.state.tabId} onChange={this.handleChange}>
                    <Tab label="En attente de paiement" {...a11yProps(0)} />
                    <Tab label="Paiement recu" {...a11yProps(1)} />
                    <Tab label="En approvisionnement" {...a11yProps(2)} />
                    <Tab label="Envoyé" {...a11yProps(3)} />
                    <Tab label="En retrait" {...a11yProps(4)} />
                    <Tab label="Fermée" {...a11yProps(5)} />
                    <Tab label="Annulée" {...a11yProps(6)} />
                </Tabs>
                <TabPanel value={this.state.tabId} index={0}>
                    <AdminOrderByStep step="PROCESSING"/>
                </TabPanel>
                <TabPanel value={this.state.tabId} index={1}>
                    <AdminOrderByStep step="PAYMENT_RECEIVED"/>
                </TabPanel>
                <TabPanel value={this.state.tabId} index={2}>
                    <AdminOrderByStep step="PROVISIONNING"/>
                </TabPanel>
                <TabPanel value={this.state.tabId} index={3}>
                    <AdminOrderByStep step="SENT"/>
                </TabPanel>
                <TabPanel value={this.state.tabId} index={4}>
                    <AdminOrderByStep step="WITHDRAWAL"/>
                </TabPanel>
                <TabPanel value={this.state.tabId} index={5}>
                    <AdminOrderByStep step="CLOSED"/>
                </TabPanel>
                <TabPanel value={this.state.tabId} index={6}>
                    <AdminOrderByStep step="CANCELED"/>
                </TabPanel>
            </Container>
        )
    }
}

export default withStyles(styles)(AdminOrderManagement);
