import React, { Component } from "react";

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EuroIcon from '@material-ui/icons/Euro';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Paper from '@material-ui/core/Paper';
import PlantDescription from "./PlantDescription"
import Typography from '@material-ui/core/Typography';
import { addToTisane } from '../actions/cartActions'
import { connect } from 'react-redux'
import { removePlant } from '../actions/cartActions';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    root: {
      flexGrow: 1,
      marginBottom: '1em'
    },
    mainPaper: {
      maxWidth: 500,
      boxShadow: 'none',
    },
    plantSelected: {
        boxShadow: 'rgba(46, 51, 51, 0.5) 0px 16px 20px 0px;',
        '&:hover': {
            boxShadow: 'rgba(46, 51, 51, 0.2) 0px 22px 24px 0px',
            cursor: 'pointer',
        }
    },
    plantNotSelected: {
        '&:hover': {
            boxShadow: 'rgba(46, 51, 51, 0.2) 0px 22px 24px 0px',
            cursor: 'pointer',
        }
    },
    image: {
      width: 128,
      height: 128,
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
      opacity: 0.9,
    },
    plusIcon: {
        float: 'right',
    },
    description: {
        height: '3em',
    },
    paper: {
        position: 'absolute',
        width: 400,
        border: '2px solid #000',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        outline: 'none'
      },
    catIcon1: {
        width: '18px',
        height: '18px',
        color: 'grey',
        display: 'block',
        margin: 'auto',
    },
    catIcon2: {
        width: '21px',
        height: '21px',
        color: 'grey',
        display: 'block',
        margin: 'auto',
    },
    catIcon3: {
        width: '24px',
        height: '24px',
        color: 'grey',
        display: 'block',
        margin: 'auto',
    },
    catIcon4: {
        width: '27px',
        height: '27px',
        color: 'grey',
        display: 'block',
        margin: 'auto',
    },
    catIcon5: {
        width: '30px',
        height: '30px',
        color: 'grey',
        display: 'block',
        margin: 'auto',
    },
    plantName: {
        fontWeight: '540',
    },
    plantInfo: {
        position: 'relative',
    },
    propertyList: {
        '& a': {
            margin: theme.spacing(0.2, 0.2),
            backgroundColor: theme.palette.primary.light,
        },
    },
    tasteList: {
        '& a': {
            margin: theme.spacing(0.2, 0.2),
            backgroundColor: theme.palette.tertiary.main,
        },
    },
    opacity: {
        opacity: '0.7',
    },
    catIcons: {
        textAlign: 'right',
        paddingRight: theme.spacing(2),
    },
    icons: {
        '& div': {
            minHeight: '35px',
        }
    },
    subtitle: {
        fontSize: '1em',
    },
    divider: {
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    pictoList: {
        marginTop: theme.spacing(1),
    },
    plantTitle: {
        lineHeight: 1,
    },
    noPadding: {
        position: 'relative',
        margin: theme.spacing('auto', 0),
        padding: theme.spacing(0),
    },
    dividerWrapper: {
        position: 'absolute',
        minHeight: '77px',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    verticalDivider: {
        width: '1px',
        minHeight: '77px',
        margin: theme.spacing(0),

        backgroundColor: theme.palette.secondary.main,
    },
    iconsContainer: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '25px',
        minWidth: '25px'
    },
    titleContainer: {
        textAlign: 'center',
        marginTop: theme.spacing(1),
    },
    moreIcon: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        bottom: '0px',
        right: '14px',
        '&:hover': {
            color: theme.palette.secondary.light,
        }
    },
    imgContainer: {
        position: 'relative',
    },
    descriptivIcon: {
        display: 'block',
        margin: 'auto',
    },
    selectedIcon: {
        position: 'absolute',
        right: '12px',
        color: theme.palette.secondary.main,
    }
});


class Plants extends Component {

    constructor() {
        super();
        this.state = {
            ...this.state,
            open: 0,
        }
    }

    handleOpen = (e) => {
        this.setState({ open: true});
        e.stopPropagation();
    };

    handleClose = (e) => {
        this.setState({ open: false});
        e.stopPropagation();
    };

    handleClick = (e, plant_id, selected)=>{
        if (selected){
            this.props.removePlant(plant_id);
        }
        else {
            this.props.addToTisane(plant_id);
        }
        e.stopPropagation();
    }

    render() {
        const classes = this.props.classes;
        const plant = this.props.plant;
        const selected = this.props.addedItems.includes(plant.id);
        let plantVisibilityClass;
        if (selected) {
            plantVisibilityClass = classes.plantSelected;
        }
        else {
            if (this.props.plantsAdded >= 7){
                plantVisibilityClass = '';
            }
            else {
                plantVisibilityClass = classes.plantNotSelected;
            }
        }
        const paperClasses = [classes.mainPaper, plantVisibilityClass].join(' ')

        let catClass = "catIcon" + plant.price_rank
        return (
            <div className={classes.root}>
                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <PlantDescription plant_id={plant.id} closeModal={this.handleClose} handleClick={this.handleClick}/>
                </Modal>
                <Paper className={paperClasses} onClick={(e)=>{this.handleClick(e, plant.id, selected)}}>
                    <Grid container spacing={2} direction="row" justify="center" alignItems="stretch">
                        <Grid item container xs
                            direction="column" justify="space-between"
                             className={classes.plantInfo}>
                            <Grid item className={classes.titleContainer}>
                                <Typography variant="h6" className={classes.plantTitle}>
                                    {plant.name}
                                </Typography>
                            </Grid>
                            <Grid item container className={classes.pictoList}>
                                <Grid item xs className={classes.iconsContainer}>
                                    <EuroIcon className={classes[[catClass]]} titleAccess={`${(plant.price * 100).toFixed(2)}€ / 100g`}/>
                                </Grid>
                                <Grid item xs className={classes.iconsContainer}>
                                    <img alt="Partie de la plante" src={`/partIcon/${plant.plant_part}.png`} height="30px" width="30px" className={classes.descriptivIcon}
                                    title={`${plant.plant_part}`}/>
                                </Grid>
                                <Grid item xs className={classes.iconsContainer}>
                                    { plant.is_bio &&
                                    <img alt="Bio" className={classes.descriptivIcon} title="Certifié biologique" src="/ABlogo.png" height="30px" width="30px"/>}
                                </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={6} className={classes.imgContainer}>
                        {selected && (
                                <div className={classes.selectedIcon}>
                                    <CheckCircleOutlineIcon/>
                                </div>)}

                        <div className={classes.image}>
                            <img className={classes.img} alt="complex" src={`${process.env.REACT_APP_USERS_SERVICE_URL}${plant.drawing_url}`} id=""/>
                        </div>
                        <IconButton size="small" className={classes.moreIcon} onClick={this.handleOpen}>
                            <MoreHorizIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
                </Paper>
            </div>
        );
    }
}

const mapDispatchToProps= (dispatch)=>{
    return{
        addToTisane: (plant_id)=>{dispatch(addToTisane(plant_id))},
        removePlant: (plant_id)=>{dispatch(removePlant(plant_id))},
    }
}
const mapStateToProps = (state)=>{
    return {
        addedItems: state.cartReducer.addedItems,
        maxPlants: state.cartReducer.maxPlants,
        plantsAdded: state.cartReducer.plantsAdded}}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Plants));
