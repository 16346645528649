import React, { Component } from "react";

import Button from '@material-ui/core/Button';
import { FormControl } from "@material-ui/core";
import { Formik } from "formik";
import Grid from '@material-ui/core/Grid';
import ReCAPTCHA from "react-google-recaptcha";
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    formControl: {
        width: '304px',
    },
    textField: {
        marginBottom: theme.spacing(1),
    },
    messageField: {
        marginBottom: theme.spacing(1),
    },
    container: {
        marginTop: theme.spacing(2),
    },
    validatebutton: {
        paddingRight: theme.spacing(0.5),
        paddingLeft: theme.spacing(0.5),
        fontSize: '16px',
        fontWeight: '600',
        color: 'white',
        '&:hover': {
            color: 'white',
        },
        width: '304px',
        margin : theme.spacing(1, 0),
    },
    captcha: {
        margin: theme.spacing(0, 'auto'),
        width: '304px',
    },
    fieldsContainer: {
        textAlign: 'center',
    }
});

class ContactForm extends Component {

    componentDidMount() {
        const script = document.createElement("script");
        script.src =
          "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }


    render() {
        const classes = this.props.classes;
        return (
            <React.Fragment>
            <Formik initialValues={{recaptcha: "", subject:'', message:'', email:''}}
                    onSubmit={
                        (values) => {alert(
                            JSON.stringify({recaptcha: values.recaptcha,}, null, 2));}}>
                {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                        /* and other goodies */
                    }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container className={classes.container}>
                            <Grid item xs={12} className={classes.fieldsContainer}>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    color="primary"
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="standard-basic" placeholder="Adresse email" name="email"
                                    className={classes.textField}
                                    value={values.email}/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className={classes.fieldsContainer}>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        color="primary"
                                        variant="outlined"
                                        onChange={handleChange}
                                        className={classes.textField}
                                        onBlur={handleBlur}
                                        id="standard-basic" placeholder="Sujet" name="subject" value={values.subject}/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className={classes.fieldsContainer}>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        color="primary"
                                        className={classes.messageField}
                                        variant="outlined"
                                        multiline
                                        rows={8}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="standard-basic" placeholder="Votre message" name="message" value={values.message}/>
                                    </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <ReCAPTCHA
                                className={classes.captcha}
                                sitekey="6Le2nREUAAAAALYuOv7X9Fe3ysDmOmghtj0dbCKW"
                                render="explicit"
                                theme="light"
                                verifyCallback={(response) => { setFieldValue("recaptcha", response); }}
                                onloadCallback={() => { console.log("done loading!"); }}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.fieldsContainer}>
                                <Button className={classes.validatebutton}
                                    variant="contained"
                                    color="primary">Envoyer</Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </React.Fragment>
        );
    }
}


export default withStyles(styles)(ContactForm);
