import React, { Component } from "react";

import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { userService } from "../services/user.service";
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    myTisanes: {
        margin: theme.spacing(3, 'auto', 7)
    },
    mainTitle: {
        textAlign: 'center',
        color: theme.palette.secondary.main
    },
    emptyTisane: {
        textAlign: 'center',
    }
});


class UserTisane extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tisanes: []
        }
    }

    componentDidMount() {
        userService.get_user_tisanes()
            .then((data) => {
                this.setState({tisanes: data})
            })
    }

    render() {
        const classes = this.props.classes;
        let tisanes = this.state.tisanes.length ? (
            <List dense='true'>
                {this.state.tisanes.map(item => {
                        return(
                    <React.Fragment>
                        <Divider component="li" />
                        <ListItem>
                            <ListItemText
                                primary={item.name || 'Tisane'} secondary={item.plants.map(item => item.name).join(', ')}/>
                        </ListItem>
                    </React.Fragment>
                )})}
            </List>
        ) : (<div className={classes.emptyTisane}>Aucune tisane créée.</div>)

        return (
            <Container maxWidth="sm" className={classes.myTisanes}>
                <Paper>
                    <Typography variant="h4" className={classes.mainTitle}>Mes Tisanes</Typography>
                    {tisanes}
                </Paper>
            </Container>
        )
    }
}

export default withStyles(styles)(UserTisane);
