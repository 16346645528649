import React, { Component } from "react";
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import CartItemExtra from './CartItemExtra'


const styles = theme => ({
    extraItem: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    cartExtraList: {
        marginBottom: theme.spacing(1),
    }
});


class CartExtraList extends Component {

    render() {
        if (this.props.items === undefined)
            return []
        const first_column = [];
        const second_column = [];
        for (var i = 0; i <= this.props.items.length; i = i + 2) {
            if (this.props.items[i] !== undefined)
                first_column.push(this.props.items[i]);
            if (this.props.items[i +1] !== undefined)
                second_column.push(this.props.items[i + 1]);
        }
        const classes = this.props.classes;
        return (
            <Grid container justify="center" item spacing={2} className={classes.cartExtraList}>
                <Grid item xs className={classes.extraItem}>
                    {first_column.map(item=> {
                        return (
                            <CartItemExtra item_name={this.props.item_name} item={item} handleAddExtra={this.props.handleAddExtra}/>
                        )
                    })}
                </Grid>
                <Grid item xs className={classes.extraItem}>
                    {second_column.map(item=> {

                        return (
                            <CartItemExtra item_name={this.props.item_name} item={item} handleAddExtra={this.props.handleAddExtra}/>
                        )
                    })}
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps= (dispatch)=>{
    return {
    }
}

const mapStateToProps = (state)=>{
    return {
        decoAdded : state.cartReducer.decoAdded,
        sweetAdded : state.cartReducer.sweetAdded,
        }}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CartExtraList));
