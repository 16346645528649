import 'react-quill/dist/quill.snow.css';

import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import ReactQuill from 'react-quill';
import TextField from "@material-ui/core/TextField";
import { Typography } from '@material-ui/core';
import { adminService } from '../services/admin.service'
import { history } from '../helpers/history';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
});


class AdminCGV extends Component {

    constructor(props){
        super(props);
        this.state = {
            creation_email_subject: '',
            creation_email_content: '',
            reset_email_subject: '',
            reset_email_content: '',
            confirmation_email_subject: '',
            confirmation_email_content: '',
            order_sent_email_subject: '',
            order_sent_email_content: '',
            order_canceled_email_subject: '',
            order_canceled_email_content: '',
            order_provisionning_email_subject: '',
            order_provisionning_email_content: '',
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount(){
        adminService.getConfigs([
            'creation_email_subject', 'creation_email_content',
            'reset_email_subject', 'reset_email_content',
            'confirmation_email_subject', 'confirmation_email_content',
            'order_sent_email_subject', 'order_sent_email_content',
            'order_canceled_email_subject', 'order_canceled_email_content',
            'order_provisionning_email_subject', 'order_provisionning_email_content',
        ]).then(res => {this.setState({
            creation_email_subject: res.creation_email_subject,
            creation_email_content: res.creation_email_content,
            reset_email_subject: res.reset_email_subject,
            reset_email_content: res.reset_email_content,
            confirmation_email_subject: res.confirmation_email_subject,
            confirmation_email_content: res.confirmation_email_content,
            order_sent_email_subject: res.order_sent_email_subject,
            order_sent_email_content: res.order_sent_email_content,
            order_canceled_email_subject: res.order_canceled_email_subject,
            order_canceled_email_content: res.order_canceled_email_content,
            order_provisionning_email_subject: res.order_provisionning_email_subject,
            order_provisionning_email_content: res.order_provisionning_email_content
        })})
    }

    handleChange(name, value){
        this.setState({[name]: value})
    }

    handleSubmit(e){
        adminService.updateConfigs(this.state)
        .then(res => {
            if (res.status === "success"){
                history.push('/admin')
        }});
    }

    render() {
        return (
            <Container maxWidth="sm">
                <Button href="/admin/" variant="contained">Retour</Button>
                <Typography variant="h5">Création de compte</Typography>
                <TextField fullWidth onChange={(e) => this.handleChange(e.target.name, e.target.value)} name="creation_email_subject" value={this.state.creation_email_subject}/>
                <ReactQuill theme="snow" value={this.state.creation_email_content}
                  onChange={(value) => {this.handleChange("creation_email_content", value)}} />
                <Typography variant="h5">Réinitialisation de compte</Typography>
                <TextField fullWidth onChange={(e) => this.handleChange(e.target.name, e.target.value)} name="reset_email_subject" value={this.state.reset_email_subject}/>
                <ReactQuill theme="snow" value={this.state.reset_email_content}
                  onChange={(value) => {this.handleChange("reset_email_content", value)}} />
                <Typography variant="h5">Confirmation de commande</Typography>
                <TextField fullWidth onChange={(e) => this.handleChange(e.target.name, e.target.value)} name="confirmation_email_subject" value={this.state.confirmation_email_subject}/>
                <ReactQuill theme="snow" value={this.state.confirmation_email_content}
                  onChange={(value) => {this.handleChange("confirmation_email_content", value)}} />
                <Typography variant="h5">Confirmation d'envoi</Typography>
                <TextField fullWidth onChange={(e) => this.handleChange(e.target.name, e.target.value)} name="order_sent_email_subject" value={this.state.order_sent_email_subject}/>
                <ReactQuill theme="snow" value={this.state.order_sent_email_content}
                  onChange={(value) => {this.handleChange("order_sent_email_content", value)}} />
                <Typography variant="h5">Création d'annulation</Typography>
                <TextField fullWidth onChange={(e) => this.handleChange(e.target.name, e.target.value)} name="order_canceled_email_subject" value={this.state.order_canceled_email_subject}/>
                <ReactQuill theme="snow" value={this.state.order_canceled_email_content}
                  onChange={(value) => {this.handleChange("order_canceled_email_content", value)}} />
                <Typography variant="h5">En attente d'appro</Typography>
                <TextField fullWidth onChange={(e) => this.handleChange(e.target.name, e.target.value)} name="order_provisionning_email_subject" value={this.state.order_provisionning_email_subject}/>
                <ReactQuill theme="snow" value={this.state.order_provisionning_email_content}
                  onChange={(value) => {this.handleChange("order_provisionning_email_content", value)}} />

                <div>
                    <Button variant="contained" color="primary" onClick={this.handleSubmit}>Sauvegarder</Button>
                </div>
            </Container>
        )
    }
}

export default withStyles(styles)(AdminCGV);
