import React, { Component } from "react";
import { addDeco, addSweets, downQuantity, upQuantity, updateMaxPlants } from '../actions/cartActions'

import Button from '@material-ui/core/Button';
import CartExtraList from './CartExtraList';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { Typography } from "@material-ui/core";
import { addToCart } from '../actions/cartActions'
import { alertActions } from '../actions/alert.actions.js';
import { connect } from 'react-redux';
import { updateTisaneName } from '../actions/cartActions';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    title: {
        textAlign: 'center',
    },
    tisaneName: {
        margin: theme.spacing(2, 0),
    },
    extraTitle: {
        fontWeight: 'bold',
        lineHeight: '1'
    },
    extraHeader: {
        margin: theme.spacing(0, 2),
    },
    extraContainer: {
        paddingBottom: theme.spacing(2),
    },
    removeIcon: {
        '&:hover': {
            cursor: 'pointer',
            fill: theme.palette.secondary.light,
        },
        fill: theme.palette.primary.dark,
        transition: '0.3s',
    },
    addIcon: {
        '&:hover': {
            cursor: 'pointer',
            fill: theme.palette.primary.main,
        },
        fill: theme.palette.primary.dark,
        transition: '0.3s',
    },
    validButton: {
        width: '100%',
        fontSize: '16px',
        fontWeight: '600',
        color: 'white',
    },
    footer: {
        textAlign: 'center',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    validButtonContainer: {
        padding: theme.spacing(0, 1)
    },
    extraPrice: {
        float: 'right',
        color: '#828585',
        fontSize: '400',
        fontWeight: '400',
    },
    weightSelect: {
        minWidth: '120px',
    }
});


class CartExtra extends Component {

    handleOnBlur = (e)=> {
        const { dispatch } = this.props;
        dispatch(updateTisaneName(e.target.value));
    }

    handleClick = () => {
        const { dispatch } = this.props;
        if (!this.props.weight){
            this.setState({weightError: true})
            return
        }
        if (this.props.addedItems.length === 0) {
            dispatch(alertActions.error("Vous devez avoir ajouté au moins une plante."))
            this.props.handleClose();
            return
        }
        dispatch(addToCart());
        this.props.handleClose();
    }

    handleAddSweets = (item_id) => {
        const { dispatch } = this.props;
        dispatch(addSweets(item_id, this.props.item_name))
    }

    handleAddDeco = (item_id) => {
        const { dispatch } = this.props;

        dispatch(addDeco(item_id, this.props.item_name))
    }

    handleAddQuantity = (tisane_id)=> {
        const { dispatch } = this.props;
        dispatch(upQuantity(tisane_id));
    }
    handleDownQuantity = (tisane_id) => {
        const { dispatch } = this.props;
        dispatch(downQuantity(tisane_id));
    }

    handleOnChange = (e)=> {
        const { dispatch } = this.props;
        if (e.target.value){
            this.setState({weightError: false})
            dispatch(updateMaxPlants(e.target.value));
        }
    }

    constructor(props){
        super(props)
        this.state = {
            weightError: false,
        }
    }

    render() {
        const classes = this.props.classes;
        let price = this.props.tisane_price ? this.props.tisane_price + '€' : '';
        const weightOptions = [];
        for (var maxPlantsOption in this.props.maxPlantsByWeight) {
            for (var i=0; i < this.props.maxPlantsByWeight[maxPlantsOption].length; i++){
                let weight = this.props.maxPlantsByWeight[maxPlantsOption][i];
                if (this.props.availableWeightsOptions.includes(weight))
                    weightOptions.push(<MenuItem value={weight}>{weight}g</MenuItem>)
            }
        }
        return (
        <Container maxWidth="xs">
            <Paper>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="stretch"
                    className={classes.extraContainer}>
                    <Grid item xs container className={classes.tisaneName}
                    spacing={2} justify="center" alignItems="center">
                        <Grid item xs></Grid>
                        <Grid item xs={6}>
                            <TextField
                            variant="outlined"
                            fullWidth
                            placeholder="Nom de ma tisane"
                            onBlur={(e) =>{this.handleOnBlur(e)}} ref={this.TisaneName} />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl error={this.state.weightError}>
                                <Select
                                className={classes.weightSelect}
                                variant="outlined"
                                onChange={(e) =>{this.handleOnChange(e)}}
                                value={this.props.weight}
                                displayEmpty
                                fullWidth>
                                    <MenuItem value="">{this.state.weightError ? '⚠️  - Poids' : "Poids"}</MenuItem>
                                    {weightOptions}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs></Grid>
                    </Grid>
                    { this.props.deco.length > 0 &&
                    <Grid item container justify="stretch">
                        <div className={classes.extraHeader}>
                            <Typography variant="subtitle1" className={classes.extraTitle}>Choisissez une décoration<span className={classes.extraPrice}>(+0,75€)</span></Typography>
                            <Typography variant="subtitle2">Ces plantes ajouteront de la couleur à votre tisane sans en altérer le goût!</Typography>
                        </div>
                        <CartExtraList items={this.props.deco} item_name='deco' handleAddExtra={this.handleAddDeco}/>
                    </Grid>}
                    { this.props.sweets.length > 0 &&
                    <Grid item container justify="stretch">
                        <div className={classes.extraHeader}>
                            <Typography variant="subtitle1" className={classes.extraTitle}>Choisissez un sucrant<span className={classes.extraPrice}>(+0,75€)</span></Typography>
                            <Typography variant="subtitle2">Cette plante ajoutera naturellement un goût sucré à votre tisane.</Typography>
                        </div>
                        <CartExtraList items={this.props.sweets} item_name='sweets' handleAddExtra={this.handleAddSweets}/>
                    </Grid>}
                    <Grid item xs><Divider variant="middle" className={classes.divider}/></Grid>
                    <Grid item xs container className={classes.footer}>
                        <Grid item xs={4}>
                            <Button onClick={this.props.handleClose} size="large">Annuler</Button>
                        </Grid>
                        <Grid item xs={8} className={classes.validButtonContainer}>
                            <Button variant="contained" size="large" className={classes.validButton}color="primary" onClick={()=>{this.handleClick()}}><span>Valider</span> {price && <span>&nbsp;{price}</span>}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
        );
    }
}

const mapStateToProps = (state)=>{
    return {
        weight: state.cartReducer.weight,
        sweets: state.cartReducer.sweets,
        availableWeightsOptions: state.cartReducer.availableWeightsOptions,
        maxPlantsByWeight: state.cartReducer.maxPlantsByWeight,
        deco: state.cartReducer.deco,
        addedItems: state.cartReducer.addedItems,
        name: state.cartReducer.name,
        tisane_price: state.cartReducer.tisane_price,}}

export default connect(mapStateToProps)(withStyles(styles)(CartExtra));
