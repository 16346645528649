import React, { Component } from "react";

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { adminService } from '../services/admin.service'
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    paper: {
        margin: theme.spacing(2, 1),
        padding: theme.spacing(2, 1),
        '& >h1': {
            color: theme.palette.secondary.main,
            fontSize: '24px',
        }
    },
    header: {
        textAlign: 'center',
    }
});


class Cgv extends Component {

    constructor(props){
        super(props);
        this.state = {cgv: ''}
    }

    componentDidMount(){
        adminService.getConfigs(['cgv']).then(res => {this.setState({cgv: res.cgv})})
    }

    render() {
        const classes = this.props.classes;
        if (this.state.cgv === ''){
            return <p></p>
        }

        return (
            <Container maxWidth="sm">
                <Paper className={classes.paper} dangerouslySetInnerHTML={{'__html': this.state.cgv}}>
                </Paper>
            </Container>
        )
    }
}

export default withStyles(styles)(Cgv);
