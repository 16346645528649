import React, { Component } from "react";
import { deleteItem, downQuantity, upQuantity } from '../actions/cartActions'
import { fetchPlantsIfNeeded, fetchWeightsIfNeeded } from '../actions/cartActions.jsx'

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    tisaneName: {
        fontWeight: '600',
        fontSize: '14px',
    },
    itemDescription: {

    },
    itemPlants: {
        marginLeft: theme.spacing(2),
    },
    noPadding: {
        padding: theme.spacing(0),
    },
    removeIcon: {
        '&:hover': {
            cursor: 'pointer',
            fill: theme.palette.secondary.light,
        },
        fill: theme.palette.primary.dark,
        transition: '0.3s',
    },
    addIcon: {
        '&:hover': {
            cursor: 'pointer',
            fill: theme.palette.primary.main,
        },
        fill: theme.palette.primary.dark,
        transition: '0.3s',
    },
    quantity: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
        fontWeight: '500',
        fontSize: '16px',
    },
    prices: {
        textAlign: 'right',
    },
    loading: {
        height: '70px;'
    }
})


class cartItem extends Component {

    componentDidMount() {
        this.props.fetchPlantsIfNeeded();
        this.props.fetchWeightsIfNeeded();
    }

    handleAddQuantity = (tisane_id)=> {
        this.props.tisaneAddQuantity(tisane_id);
    }
    handleDownQuantity = (tisane_id) => {
        this.props.tisaneDownQuantity(tisane_id);
    }
    handleDeleteItem = (tisane_id) => {
        this.props.deleteItem(tisane_id);
    }

    render() {
        const classes = this.props.classes;
        const item = this.props.item;
        if (!this.props.plants.length) {
            return (<p className={classes.loading}>Chargement...</p>)
        }
        return (
                <Grid container>
                    <Grid item xs={8} className={classes.itemDescription}>
                        <Typography variant="body1" className={classes.tisaneName}>
                            <span className={classes.quantity}>{item.quantity}x </span>{item.name || 'Tisane'} ({item.weight}g)
                        </Typography>
                        <Typography variant="subtitle2" className={classes.itemPlants}>
                            {item.addedItems.map(plant_id => this.props.plants.find(plant => plant.id === plant_id).name).join(', ')}
                        </Typography>
                        {item.decoAdded.length > 0 && <Typography variant="subtitle2" className={classes.itemPlants}>
                            Déco : {item.decoAdded.map(plant_id => this.props.deco.find(plant => plant.id === plant_id).name).join(', ')}
                        </Typography>}
                        {item.sweetAdded.length > 0 &&
                            <Typography variant="subtitle2" className={classes.itemPlants}>
                                Sucrant : {item.sweetAdded.map(plant_id => this.props.sweets.find(plant => plant.id === plant_id).name).join(', ')}
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={2} className={classes.prices}>
                        {item.price}€
                    </Grid>
                    <Grid item xs={2} className={classes.prices}>
                        <span>{(item.quantity * item.price).toFixed(2)}€</span>
                    </Grid>
                </Grid>
        );
    }
}

const mapDispatchToProps= (dispatch)=>{
    return {
        tisaneAddQuantity: (tisane_id)=>{dispatch(upQuantity(tisane_id))},
        tisaneDownQuantity: (tisane_id)=>{dispatch(downQuantity(tisane_id))},
        deleteItem: (tisane_id)=>{dispatch(deleteItem(tisane_id))},
        fetchPlantsIfNeeded: ()=>{dispatch(fetchPlantsIfNeeded())},
        fetchWeightsIfNeeded: ()=>{dispatch(fetchWeightsIfNeeded())},
    }
}
const mapStateToProps = (state)=>{
    return {
        plants: state.cartReducer.plants,
        deco: state.cartReducer.deco,
        sweets: state.cartReducer.sweets}}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(cartItem));
