import React, { Component } from "react";

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Table from '@material-ui/core/Table';
import { TableBody } from "@material-ui/core";
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { adminService } from '../services/admin.service';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({

});


class AdminShipment extends Component {

    componentDidMount(){
        adminService.getDeliveryItems()
            .then(res => this.setState({deliveryItems: res}))
    }

    constructor(props){
        super(props);
        this.state = {
            deliveryItems: [],
        }
    }

    render() {
        const classes = this.props.classes;
        if (!this.state.deliveryItems.length)
            return (<p>Chargement...</p>)
        return (
            <Container maxWidth="sm">
                <Button href="/admin/" variant="contained" className={classes.newPlant}>Retour</Button>
                <Table>
                    <TableHead>
                        <TableCell>Ordre d'affichage</TableCell>
                        <TableCell>Nom de zone</TableCell>
                        <TableCell>Prix</TableCell>
                        <TableCell>Prix de franco</TableCell>
                        <TableCell></TableCell>
                    </TableHead>
                    <TableBody>
                        {this.state.deliveryItems.map(item =>
                            <TableRow>
                                <TableCell>{item.order}</TableCell>
                                <TableCell>{item.zone}</TableCell>
                                <TableCell>{item.price}</TableCell>
                                <TableCell>{item.franco_price}</TableCell>
                                <TableCell><a href={"/admin/shipment/edit/" + item.id}><EditOutlinedIcon /></a></TableCell>
                            </TableRow>
                            )}
                    </TableBody>
                </Table>
            </Container>
        )
    }
}

export default withStyles(styles)(AdminShipment);
