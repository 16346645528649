import React, { Component } from "react";
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { history } from '../helpers/history';
import { userService } from '../services/user.service';


const styles = theme => ({
    myOrders: {
        margin: theme.spacing(3, 'auto', 7)
    },
    mainTitle: {
        textAlign: 'center',
        color: theme.palette.secondary.main
    },
    noOrder: {
        textAlign: 'center',
    }
});


class UserOrder extends Component {


    constructor(props) {
        super(props);
        this.state = {
            orders: []
        }
        this.mapping = {
            'PAYMENT_RECEIVED': 'En préparation',
            'PROVISIONNING': 'En approvisionnement',
            'SENT': 'Envoyée',
            'WITHDRAWAL': 'Retrait en magasin',
            'CLOSED': 'Fermée',
            'CANCELED': 'Annulée'}
    }

    componentDidMount(){
        userService.get_order_list()
            .then((data) => {
                this.setState({orders: data});
            })
    }

    handleClick(id) {
        history.push('/order/' + id)
    }

    get_status(status) {
        return this.mapping[status]
    }

    render() {
        const classes = this.props.classes;
        let order_list = []
        if (this.state.orders.length > 0){
            order_list = (
                <List>
                    {this.state.orders.map((order) => (
                        <React.Fragment>
                            <Divider component="li" />
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                primary={`N°${order.id} - ${this.get_status(order.current_status)} - ${order.quantity} Tisanes`}
                                secondary={`${(order.price / 100).toFixed(1)}€`}
                                />
                                <ListItemSecondaryAction onClick={()=>{this.handleClick(order.id)}}>
                                    <IconButton edge="end" aria-label="delete" >
                                        <ArrowForwardIosIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </React.Fragment>
                    ))}
                </List>
            )
        }
        else {
            order_list = <div className={classes.noOrder}>Vous n'avez pas encore passé de commande</div>
        }

        return (
            <Container maxWidth="sm" className={classes.myOrders}>
                <Paper >
                    <Typography variant="h4" className={classes.mainTitle}>Mes commandes</Typography>
                        {order_list}
                </Paper>
            </Container>
        )
    }
}

export default withStyles(styles)(UserOrder);
