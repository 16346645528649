import React, { Component } from "react";

import ContactForm from './ContactForm';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import FacebookIcon from '@material-ui/icons/Facebook';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import InstagramIcon from '@material-ui/icons/Instagram';
import MailIcon from '@material-ui/icons/Mail';
import Paper from '@material-ui/core/Paper';
import { Typography } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    title: {
        textAlign: 'center',
        paddingBottom: theme.spacing(1),
    },
    container: {
        margin: theme.spacing(2, 'auto')
    },
    containerInner: {
        padding: theme.spacing(2, 1),
    },
    contactLink: {
        '& > span': {
            color: 'black',
        },
        '& > svg': {
            color: theme.palette.primary.dark,
            margin: theme.spacing(0, 1),
        },
        '&:hover > svg': {
            color: theme.palette.primary.light,
        },
        display: 'flex',
        margin: theme.spacing(2, 0),
        width: '300px',
        fontSize: '22px',
        fontWeight: 450,
    },
    divider: {
        color: theme.palette.secondary.main,
        padding: theme.spacing(6, 0),
        textAlign: 'center',
    }
});

class About extends Component {

  render() {
    const classes = this.props.classes;
    return (
        <Container maxWidth="md" className={classes.container}>
            <Paper className={classes.containerInner}>
            <Typography variant="h4" color="secondary" className={classes.title}>Gardons le contact !</Typography>
                <Grid container>
                    <Grid item xs container direction="column" alignItems="center" justify="space-evenly">
                        <Grid item>
                            <a className={classes.contactLink} href="mailto:contact@tisaneo.fr"> <MailIcon fontSize="large" /><span>contact@tisaneo.fr</span></a>
                        </Grid>
                        <Grid item>
                            <a className={classes.contactLink} target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/tisaneo.fr/"><InstagramIcon fontSize="large"/><span>@tisaneo.fr</span></a>
                        </Grid>
                        <Grid item>
                            <a className={classes.contactLink} target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/tisaneo.fr/"><FacebookIcon fontSize="large"/><span>facebook.com/tisaneo.fr</span></a>
                        </Grid>
                    </Grid>
                    <Hidden smDown>
                        <Divider orientation="vertical" className={classes.divider} flexItem/>
                    </Hidden>
                    <Grid item xs>
                        <ContactForm />
                    </Grid>

                </Grid>
            </Paper>
        </Container>
    );
  }
}


export default withStyles(styles)(About);
