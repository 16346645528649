import { createMuiTheme } from '@material-ui/core/styles';


export default createMuiTheme({
    palette: {
        // primary: {main: '#bcb88a'},
        // secondary: {main: '#8f3b21'},
        // tertiary: {main: '#dd9927'}
        primary: {main: '#B2B26C'},
        secondary: {main: '#97292C'},
        tertiary: {main: '#dd9927'},
        quaternary: {main: '#D19D6D'},
        quinary: {main: '#C37A67'},
        senary: {main: '#BA5F42'},
    }
})
