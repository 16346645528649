import React, { Component } from "react";

import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    CartItemExtra: {
        marginBottom: theme.spacing(1.5),
    }
});


class CartItemExtra extends Component {

    render() {
        let item = this.props.item;
        let selected = '';
        if (this.props.item_name === 'deco'){
            if (this.props.decoAdded.includes(item.id)){
                selected='selected';
            }
        }
        if (this.props.item_name === 'sweets'){
            if (this.props.sweetAdded.includes(item.id)){
                selected='selected';
            }
        }
        const classes = this.props.classes;
        return (
            <Paper onClick={()=> {this.props.handleAddExtra(item.id)}} className={classes.CartItemExtra}>
                    <Checkbox checked={selected}/> {item.name}
            </Paper>)
    }
}

const mapStateToProps = (state)=>{
    return {
        decoAdded: state.cartReducer.decoAdded,
        sweetAdded: state.cartReducer.sweetAdded,
}}

export default connect(mapStateToProps)(withStyles(styles)(CartItemExtra));
