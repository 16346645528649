import React, { Component } from "react";

import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Thumb from './Thumb';
import { history } from '../helpers/history';
import { plantService } from '../services/plant.service';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    container: {

    }
})


class AdminPlantsForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            plant_id: this.props.match.params.plant_id,
            plant: {},
            priceAvailable: [],
            tastes: [],
            properties: [],
        }
    }


    componentDidMount(){
        if (this.state.plant_id){
            plantService.getPlant(this.state.plant_id)
                .then(res => this.setState({plant: res}))
        }
        plantService.getPriceTags()
            .then(res=>this.setState({priceAvailable: res}))
        plantService.getTag(0)
            .then(res=>this.setState({tastes: res}))
        plantService.getTag(1)
            .then(res=>this.setState({properties: res}))
    }

    get_initial_data(plant=undefined, tastes=undefined, properties=undefined){
        if (!plant){
            return {
                name: '', latin_name: '' , plant_part: 'FRUIT', visible: true,
                origin: false, is_bio: false, allways_bio: false, description: '', taste_description: '',
                properties_description: '', density: 1 , pricetag_id: 1, plant_type: 0, drawing_url: '', real_url: ''}
        }
        else {
            return {
                name: plant.name, latin_name: plant.latin_name,
                plant_part: plant.plant_part, visible: plant.visible,
                origin: plant.origin, is_bio: plant.is_bio, allways_bio: plant.allways_bio,
                description: plant.description, taste_description: plant.taste_description,
                properties_description: plant.properties_description,
                density: plant.density, pricetag_id:plant.pricetag_id,
                plant_type:plant.plant_type, drawing_url: plant.drawing_url,
                real_url: plant.real_url,
                tastes: plant.taste_tags.map(item => tastes.find(taste => taste.name === item)),
                properties: plant.property_tags.map(item => properties.find(taste => taste.name === item))
            }
        }
    }

    render() {
        const classes = this.props.classes;
        let initialValues = {};
        if (this.state.plant_id){
            if (Object.entries(this.state.plant).length === 0){
                return <span>Chargement</span>
            }
            if (Object.entries(this.state.tastes).length === 0 || Object.entries(this.state.properties).length === 0){
                return <span>Chargement</span>
            }
            initialValues = this.get_initial_data(this.state.plant, this.state.tastes, this.state.properties);
        }
        else
            initialValues = this.get_initial_data();

        return (
            <Container maxWidth='md' className={classes.container}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting }) => {
                          plantService.updatePlant(this.state.plant_id, values)
                          .then(res => {
                              if (res.status === 'success'){
                                    history.push('/admin/plants')
                              }
                          })
                          .catch(error => console.log);
                          setSubmitting(false);
                    }}>

                {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                        /* and other goodies */
                    }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={4}
                            justify="center"
                            alignItems="center">
                            <Grid item xs={4}>
                                <TextField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="standard-basic" label="Name" name="name"
                                    value={values.name}/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="standard-basic" label="Latin Name" name="latin_name" value={values.latin_name}/>
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel id="demo-simple-select-label">Part</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="plant_part"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.plant_part}
                                >
                                    <MenuItem value="Fruit">Fruit</MenuItem>
                                    <MenuItem value="Pétale">Pétale</MenuItem>
                                    <MenuItem value="Fleur">Fleur</MenuItem>
                                    <MenuItem value="Zeste">Zeste</MenuItem>
                                    <MenuItem value="Ecorce">Ecorce</MenuItem>
                                    <MenuItem value="Bouton">Bouton</MenuItem>
                                    <MenuItem value="Bractée">Bractée</MenuItem>
                                    <MenuItem value="Racine">Racine</MenuItem>
                                    <MenuItem value="Feuille">Feuille</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    onChange={handleChange}
                                    name="visible"
                                    control={<Checkbox />}
                                    label="Visible"
                                    checked={values.visible}
                                />
                            </Grid>
                            <Grid item xs={4}>
                            <TextField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="standard-basic" label="Origine" name="origin"
                                    value={values.origin}/>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControlLabel
                                    onChange={handleChange}
                                    control={<Checkbox name="is_bio" />}
                                    label="Bio"
                                    name="is_bio"
                                    checked={values.is_bio}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormControlLabel
                                    onChange={handleChange}
                                    control={<Checkbox name="allways_bio" />}
                                    label="Toujours bio"
                                    name="allways_bio"
                                    checked={values.allways_bio}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="standard-multiline-static"
                                    label="Description"
                                    multiline
                                    rows={4}
                                    name="description"
                                    value={values.description}/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="standard-multiline-static"
                                    label="Taste"
                                    multiline
                                    rows={4}
                                    name="taste_description"
                                    value={values.taste_description}
                                    dejaultValue="Default Value" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="standard-multiline-static"
                                    label="Properties"
                                    multiline
                                    rows={4}
                                    name="properties_description"
                                    value={values.properties_description}
                                    defaultValue="Default Value" />
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel id="demo-simple-select-label">Plant Type</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.plant_type}
                                name="plant_type"
                                >
                                    <MenuItem value="0">Tisane</MenuItem>
                                    <MenuItem value="1">Deco</MenuItem>
                                    <MenuItem value="2">Sweet</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel id="demo-simple-select-label">Density</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.density}
                                name="density"
                                >
                                    <MenuItem value="1">Elevée</MenuItem>
                                    <MenuItem value="2">Ni élevée, ni basse</MenuItem>
                                    <MenuItem value="4">Basse</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel id="demo-simple-select-label">Price</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.pricetag_id}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="pricetag_id"
                                >
                                    {this.state.priceAvailable.map(item =>
                                        <MenuItem value={item.id}>{item.rank} - {item.price} €/g</MenuItem>
                                        )}
                                </Select>
                            </Grid>
                            <Grid item xs={6}>
                                {this.state.tastes.length >0 &&
                                    <Autocomplete
                                        multiple
                                        filterSelectedOptions
                                        id="tastes"
                                        options={this.state.tastes}
                                        getOptionLabel={(option) => option.name}
                                        value={values.tastes}
                                        onChange={(e, value) => {
                                            setFieldValue(
                                            "tastes",
                                            value !== null ? value : initialValues.tastes
                                            );
                                        }}
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="tastes"
                                            variant="standard"
                                            label="Goûts"/>
                                        )}
                                    />}
                            </Grid>
                            <Grid item xs={6}>
                                {this.state.properties.length >0 &&
                                    <Autocomplete
                                        multiple
                                        filterSelectedOptions
                                        id="properties"
                                        options={this.state.properties}
                                        getOptionLabel={(option) => option.name}
                                        value={values.properties}
                                        onChange={(e, value) => {
                                            setFieldValue(
                                            "properties",
                                            value !== null ? value : initialValues.properties
                                            );
                                        }}
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="properties"
                                            variant="standard"
                                            label="Propriétées"/>
                                        )}
                                    />}
                            </Grid>
                            <Grid item container>
                                    <Grid item xs>
                                        <InputLabel id="drawing-label">Dessin</InputLabel>
                                        <input id="drawing" name="drawing" type="file" onChange={(event) => {
                                            setFieldValue("drawing", event.currentTarget.files[0]);
                                        }} className="form-control" />
                                        <Thumb file={values.drawing} img_url={values.drawing_url}/>
                                    </Grid>
                                    <Grid item xs>
                                        <InputLabel id="image-label">Image réelle</InputLabel>
                                        <input id="image" name="image" type="file" onChange={(event) => {
                                            setFieldValue("image", event.currentTarget.files[0]);
                                        }} className="form-control" />
                                        <Thumb file={values.image} img_url={values.real_url}/>
                                    </Grid>
                            </Grid>
                        </Grid>
                        <Button type="submit" color="primary" disabled={isSubmitting}>
            Submit
          </Button>
                    </form>
                )}
                </Formik>
            </Container>
        );
    }
}


export default withStyles(styles)(AdminPlantsForm);
