import { Collapse, Divider } from "@material-ui/core";
import React, { Component } from "react";

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import PreniumTisaneCarousel from './PreniumTisaneCarousel'
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    descriptionStepHeader: {
        color: theme.palette.secondary.main,
    },
    preniumTisane: {
        color: theme.palette.secondary.main,
        textAlign: 'center',
        marginBottom: theme.spacing(1)
    },
    mobilePreniumTisane: {
        color: theme.palette.secondary.main,
        textAlign: 'center',
        margin: theme.spacing(1, 0),
        fontSize: "23px",
        '&:hover': {
            cursor: 'pointer',
        }
    },
    mainDivider: {
        marginLeft: theme.spacing(1),
    },
    descriptionContent: {
        'textJustify': 'auto',
        'textAlign': 'justify',
        fontSize: '16px',
    },
    appDivider: {
        height: '1px',
        backgroundColor: theme.palette.secondary.main,
        margin: theme.spacing(2, 'auto'),
        width: '80%',
    },
    verticalAppDivider: {
        backgroundColor: theme.palette.secondary.main,
    },
    stepNumber: {
        fontSize: '25px',
        fontWeight: 'Bold',
    },
    mobileDivider: {
        backgroundColor: theme.palette.secondary.main,
    }
})


class AppDescription extends Component {

    constructor(props){
        super(props);
        this.state = {
            open: false,
        }
        this.openPreniumTisane = this.openPreniumTisane.bind(this);
    }

    openPreniumTisane(){
        this.setState({open: !this.state.open})
    }

    render() {
        const classes = this.props.classes;
        return (
            <Grid container spacing={3} alignItems="center">
                <Grid item container direction="column" sm xs={12}>
                    <Grid item className={classes.borderDescritpion}>
                        <Typography variant="h5" className={classes.descriptionStepHeader}>
                            <span className={classes.stepNumber}>1.</span> Je cueille mes plantes
                        </Typography>
                        <Typography variant="body2" gutterBottom className={classes.descriptionContent}>
                        Je choisis jusqu'à <b>7 plantes</b> pour composer ma tisane parmi les <b>30 plantes</b> qui ont été pré-sélectionnées pour leurs <b>goûts, propriétés et qualités</b>.
                        </Typography>
                    </Grid>
                    <Divider flexItem className={classes.appDivider}/>
                    <Grid item direction="row" className={classes.borderDescritpion}>
                        <Typography variant="h5" className={classes.descriptionStepHeader}>
                        <span className={classes.stepNumber}>2.</span> Je personnalise
                        </Typography>
                        <Typography variant="body2" gutterBottom className={classes.descriptionContent}>
                        Je choisis <b>une ou plusieurs plantes colorées</b>, pourquoi pas une plante <b>sucrante</b> et même un nom pour personnaliser ma tisane !
                        </Typography>
                    </Grid>
                    <Divider flexItem className={classes.appDivider}/>
                    <Grid item className={classes.borderDescritpion}>
                        <Typography variant="h5" className={classes.descriptionStepHeader}>
                        <span className={classes.stepNumber}>3.</span> Je reçois chez moi ma commande
                        </Typography>
                        <Typography variant="body2" gutterBottom className={classes.descriptionContent}>
                        Je prends le temps de savourer ma tisane avec ma famille et mes amis !
                        </Typography>
                    </Grid>
                </Grid>
                <Hidden xsDown>
                    <Divider orientation="vertical" flexItem className={classes.verticalAppDivider}/>
                </Hidden>

                <Hidden smUp>
                    <Grid item xs={12} sm={6} direction="column" justify="center" spacing={1}>
                        <Divider className={classes.mobileDivider}/>
                            <Typography variant="h5" className={classes.mobilePreniumTisane} onClick={this.openPreniumTisane}>
                                Mélanges du moment {this.state.open && <span>&#x025B3;</span>}{!this.state.open && <span>&#x025BD;</span>}
                            </Typography>
                        <Collapse in={this.state.open} timeout="auto" mountOnEnter unmountOnExit>
                            <PreniumTisaneCarousel />
                        </Collapse>
                        <Divider className={classes.mobileDivider}/>
                    </Grid>
                </Hidden>

                <Hidden xsDown>
                    <Grid item xs={12} sm={6} direction="column" justify="center" spacing={1}>
                        <Typography variant="h5" className={classes.preniumTisane}>Mélanges du moment</Typography>
                        <PreniumTisaneCarousel />
                    </Grid>
                </Hidden>

            </Grid>
        );
    }
};

export default withStyles(styles)(AppDescription);
