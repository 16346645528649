import React, { Component } from "react";

import Button from '@material-ui/core/Button';
import { Container } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { plantService } from "../services/plant.service";
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    fab: {
        position: 'fixed',
        zIndex: 150,
        right: 0,
      },
    eyeIcon: {
        color: theme.palette.primary.main,
    }
})


class AdminRecipes extends Component {

    constructor(props){
        super(props)
        this.state = {
            recipes: []
        }
    }

    componentDidMount() {
        plantService.getAdminRecipes()
            .then(res => this.setState({recipes: res}))
    }

    render(){
        const classes = this.props.classes;
        return (
            <Container maxWidth="sm">
                <Button href="/admin/recipes/edit/" color="primary" variant="contained" className={classes.newPlant}>Nouvelle recette</Button>
                <Button href="/admin/" variant="contained" className={classes.newPlant}>Retour</Button>
                <Paper>
                    <List dense='true'>
                        {this.state.recipes.map(item => {
                            return (
                                <React.Fragment>
                                    <Divider component="li" />
                                    <ListItem>
                                        <ListItemText
                                            primary={item.name || 'Tisane'} secondary={item.tisanePlants.join(', ')}/>
                                        <ListItemSecondaryAction>
                                            <IconButton href={"/admin/recipes/edit/" + item.id}>
                                                <EditOutlinedIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </React.Fragment>
                            )
                        })}
                    </List>
                </Paper>
            </Container>
        )
    }
}



export default withStyles(styles)(AdminRecipes);
