import { buildAuthHeader, getUserToken, handleResponse } from '../helpers/ajax';

export const plantService = {
    getPlants,
    getPlant,
    updatePlant,
    getPriceTags,
    updatePriceTag,
    getAdminPlants,
    getTag,
    getOfficialRecipes,
    getOfficialRecipe,
    getPlantImage,
    getAdminRecipes,
    getAdminRecipe,
    updateAdminRecipe,
    getWeights,
}

function getPlants(plant_type) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/plants/` + plant_type, requestOptions)
          .then(res => {
            this.setState({ plants: res.data });
          })
}

function getPlant(id) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/plant/` + id, requestOptions)
        .then(response => response.text())
        .then(text => JSON.parse(text))
}

function updatePlant(id, values){
    var formData = new FormData();
    values['tastes'] = values.tastes.map(item=>item.id);
    values['properties'] = values.properties.map(item=>item.id);
    for ( var key in values ) {
        formData.append(key, values[key]);
    }
    if (!('drawing' in values)) {
        let blob = new Blob(['emtpy'], { type: 'plain/text' });
        formData.append('drawing', blob,'empty');
    }
    if (!('image' in values)) {
        let blob = new Blob(['emtpy'], { type: 'plain/text' });
        formData.append('image', blob,'empty');
    }
    const user_token = getUserToken()
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + user_token},
        body: formData
    }
    let is_new = id ? 'edit/' + id : 'new/'
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/admin/plant/` + is_new, requestOptions)
        .then(handleResponse)
}


function getAdminPlants(){
    const requestOptions = {
        method: 'GET',
        headers: buildAuthHeader(),
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/admin/plants/`, requestOptions)
        .then(handleResponse)
}
function getPriceTags(){
    const requestOptions = {
        method: 'GET',
        headers: buildAuthHeader(),
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/price_tag/`, requestOptions)
        .then(handleResponse)
}

function updatePriceTag(values){
    const requestOptions = {
        method: 'POST',
        headers: buildAuthHeader(),
        body: JSON.stringify(values),
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/price_tag/`, requestOptions)
        .then(handleResponse)
}

function getTag(categoryId){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/tag/` + categoryId, requestOptions)
        .then(handleResponse)
}

function getOfficialRecipes(){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/officialrecipes/`, requestOptions)
    .then(handleResponse)
}

function getOfficialRecipe(tisane_id) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/officialrecipe/` + tisane_id, requestOptions)
    .then(handleResponse)
}

function getAdminRecipes() {
    const requestOptions = {
        method: 'GET',
        headers: buildAuthHeader()
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/admin/officialrecipe/list`, requestOptions)
    .then(handleResponse)
}

function getAdminRecipe(id) {
    const requestOptions = {
        method: 'GET',
        headers: buildAuthHeader()
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/admin/officialrecipe/edit/` + id, requestOptions)
    .then(handleResponse)
}

function updateAdminRecipe(id, values) {
    values['tisanePlants'] = values.tisanePlants.map(item=>item.id)
    var formData = new FormData();
    for (var key in values) {
        formData.append(key, values[key]);
    }
    if (!('picture' in values)){
        var blob = new Blob(['emtpy'], { type: 'plain/text' });
        formData.append('picture', blob, 'empty')
    }

    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + getUserToken()},
        body: formData
    }
    let is_new = id ? 'edit/' + id : 'new/'
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/admin/officialrecipe/` + is_new, requestOptions)
    .then(handleResponse)
}

function getPlantImage(img_url) {
    const requestOptions = {
        method: 'GET',
        headers: buildAuthHeader(),
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}${this.state.img_url}`, requestOptions)
    .then(res => res.blob())
}

function getWeights() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/weights/`, requestOptions)
          .then(res => res.json())
}
