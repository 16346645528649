import { persistor, store } from './configureStore'

import App from "./App.jsx";
import Footer from "./components/Footer";
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider } from 'react-redux';
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './configureTheme'

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </PersistGate>
    </Provider>,
  document.getElementById("root")
);

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Footer/>
    </ThemeProvider>,
    document.getElementById("footer")
);
