import React, { Component } from "react";

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { userService } from "../services/user.service";
import { withStyles } from '@material-ui/styles';

const rowStyles = theme => ({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    addressTitle: {
        textAlign: 'center',
    },
    addressFields: {
        textAlign: 'center',
    },
});


const innerRowStyle = theme => ({
    addressTitle: {
        fontSize: '14px',
        fontWeight: '501',
    },
    field: {
        lineHeight: '1.10',
    }

})

class InnerRow extends Component {

    constructor(props){
        super(props);
        this.state = {
            order: {}
        }
    }


    componentDidMount(){
        userService.get_admin_order(this.props.orderId)
        .then(res => this.setState({order: res}))
    }

    render() {
        const classes = this.props.classes;
        if (!Object.keys(this.state.order).length){
            return <p>Chargement...</p>
        }
        const order = this.state.order;
        return (
            <Box margin={1}>
                <Grid container direction="row">
                    <Grid item container xs={12}>
                            <Grid item xs={6}>
                                <div >
                                    <Typography component="p" className={classes.addressTitle}>Livraison</Typography>
                                    <Typography componenent="p" variant="body2" className={classes.field}>{order.liv_name}</Typography>
                                    <Typography component="p" className={classes.field}variant="body2">{order.liv_street} {order.liv_precision}</Typography>
                                    <Typography componenent="p" variant="body2" className={classes.field}></Typography>
                                    <Typography component="p" variant="body2" className={classes.field}>{order.fact_zip_code} </Typography>
                                    <Typography component="p" variant="body2" className={classes.field}>{order.liv_city}</Typography>
                                    <Typography component="p" variant="body2" className={classes.field}>{order.liv_country} </Typography>
                                    <Typography component="p" className={classes.field}variant="body2">{order.liv_phone}</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div>
                                <Typography component="p" className={classes.addressTitle}>Facturation</Typography>
                                    <Typography componenent="p" variant="body2" className={classes.field}>{order.fact_name}</Typography>
                                    <Typography component="p" className={classes.field}variant="body2">{order.fact_street} {order.fact_precision}</Typography>
                                    <Typography componenent="p" variant="body2" className={classes.field}></Typography>
                                    <Typography component="p" variant="body2" className={classes.field}>{order.fact_zip_code} </Typography>
                                    <Typography component="p" variant="body2" className={classes.field}>{order.liv_city}</Typography>
                                    <Typography component="p" variant="body2" className={classes.field}>{order.fact_country}</Typography>
                                    <Typography component="p" className={classes.field}variant="body2">{order.fact_phone}</Typography>
                                </div>
                            </Grid>
                        </Grid>
                    <Grid item xs={12}>
                    <Table size="small" aria-label="purchases">
                        <TableHead>
                        <TableRow>
                            <TableCell>Nom</TableCell>
                            <TableCell>Quantité</TableCell>
                            <TableCell align="right">Prix</TableCell>

                            <TableCell>Poids</TableCell>
                            <TableCell>Plantes</TableCell>
                            <TableCell>Deco</TableCell>
                            <TableCell>Sucrant</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            this.state.order.recipeData.map((tisaneRow) => {
                                const plants = tisaneRow.plants.filter(item => item.type===0).map(item => (item.name +' (' +  item.quantity + 'g)'));
                                const deco = tisaneRow.plants.filter(item => item.type===1).map(item => item.name);
                                const sweets = tisaneRow.plants.filter(item => item.type===2).map(item => item.name);
                                return (
                            <TableRow key={tisaneRow.id}>
                            <TableCell component="th" scope="row">
                                {tisaneRow.name || 'Tisane'}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {tisaneRow.quantity}
                            </TableCell>
                            <TableCell>{tisaneRow.price / 100}€</TableCell>
                            <TableCell>{tisaneRow.weight}g</TableCell>
                            <TableCell align="right">{plants.join(', ')}</TableCell>
                            <TableCell align="right">{deco.join(', ')}</TableCell>
                            <TableCell align="right">{sweets.join(', ')}</TableCell>
                            </TableRow>
                            );
                            })
                        }
                        </TableBody>
                    </Table>
                    </Grid>

                </Grid>
            </Box>
        )
    }
}


const StyledInnerRow = withStyles(innerRowStyle)(InnerRow)


class Row extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
        }
    }

    handleStatusChange(e, orderId) {
        userService.updateOrderState(orderId, e.target.value)
            .then(res=> this.setState({tisanes: res}))
    }

    render(){
        const classes = this.props.classes;
        return (
            <React.Fragment>
              <TableRow className={classes.root}>
                <TableCell>
                  <IconButton aria-label="expand row" size="small" onClick={() => this.setState({open: !this.state.open})}>
                    {this.state.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  {this.props.row.id}
                </TableCell>
                <TableCell align="right">{this.props.row.uuid}</TableCell>
                <TableCell align="right">{this.props.row.date}</TableCell>
                <TableCell align="right">{this.props.row.email}</TableCell>
                <TableCell align="right">{this.props.row.totalPrice / 100}€</TableCell>
                <TableCell align="right">{this.props.row.price / 100} €</TableCell>
                <TableCell align="right">{this.props.row.deliveryPrice / 100}€</TableCell>
                <TableCell align="right">{this.props.row.nbTisane}</TableCell>
                <TableCell align="right">
                <Select defaultValue={this.props.row.status}
                    onChange={(e)=>{this.handleStatusChange(e, this.props.row.id)}}>
                    <MenuItem value="PROCESSING" disabled>En attente de paiement</MenuItem>
                    <MenuItem value="PAYMENT_RECEIVED">Payment reçu</MenuItem>
                    <MenuItem value="PROVISIONNING">En approvisionnement</MenuItem>
                    <MenuItem value="SENT">Envoyé</MenuItem>
                    <MenuItem value="WITHDRAWAL">Retrait</MenuItem>
                    <MenuItem value="CLOSED">Fermée</MenuItem>
                    <MenuItem value="CANCELED">Annulée</MenuItem>
                </Select>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                  <Collapse in={this.state.open} timeout="auto" mountOnEnter unmountOnExit>
                      <StyledInnerRow orderId={this.props.row.id}/>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
        );
    }
}

const StyledRow = withStyles(rowStyles)(Row)


const styles = theme => ({

});

class AdminOrderByStep extends Component {

    constructor(props) {
        super(props)
        this.state = {
            order_filter: this.props.step,
            orders: [],
        }
    }

    handleStatusChange(e, orderId) {
        userService.updateOrderState(orderId, e.target.value)
    }

    componentDidMount(){
        userService.getAllOrders(this.state.order_filter)
            .then(res => this.setState({orders: res}))
    }

    createData(id, uuid, date, email, status, totalPrice, nbTisane, deliveryPrice) {
        return {
          id,
          uuid,
          email,
          date,
          status,
          price: totalPrice ,
          nbTisane,
          deliveryPrice,
          totalPrice: totalPrice + deliveryPrice ,
        };
    }



    render(){
        const rows =  [];
        if (this.state.orders.length > 0){
            for (let i=0; i < this.state.orders.length; i++){
                let order = this.state.orders[i];
                rows.push(this.createData(order.id, order.uuid, order.email, order.date, order.status, order.price, order.nbTisane, order.deliveryPrice))
            }

        }
        return (
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>n°</TableCell>
                        <TableCell>n° (pour le client)</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell align="right">Date de commande</TableCell>
                        <TableCell align="right">Prix total</TableCell>
                        <TableCell align="right">Prix tisanes</TableCell>
                        <TableCell align="right">Prix livraison</TableCell>
                        <TableCell align="right">nb tisane</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row) => (
                        <StyledRow key={row.name} row={row} />
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
  );
    }
}



export default withStyles(styles)(AdminOrderByStep);
