import React, { Component } from "react";

import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Thumb from './Thumb';
import { history } from '../helpers/history';
import { plantService } from "../services/plant.service";
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
      },
      input: {
        display: 'none',
      },
})


class AdminRecipesForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recipe_id: this.props.match.params.recipe_id,
            recipe: {},
            weights: [],
            plants: []
        }
    }

    componentDidMount(){
        if (this.state.recipe_id){
            plantService.getAdminRecipe(this.state.recipe_id)
                .then(res => this.setState({recipe: res}))
        }
        plantService.getWeights()
            .then(res => this.setState({weights: res}))

        plantService.getAdminPlants()
            .then(res => {
                let validKeys = ['name', 'id', 'visible'];
                res.map(item=> Object.keys(item).forEach((key) => validKeys.includes(key) || delete item[key]));
                this.setState({plants: res})
            })
    }

    get_initial_data(recipe=undefined){
        if (!recipe) {
            return {
                name: '', description: '', picture_url: '', weight: '', visible: true, tisanePlants:[]}
        }
        else {
            return {
                name: recipe.name, description: recipe.description, picture_url: recipe.picture_url,
                weight: recipe.weight, visible: recipe.visible,
                tisanePlants: recipe.tisanePlants.map(
                    plant_id => this.state.plants.find(plant => plant.id === plant_id))
            }
        }
    }

    handleTagChange(event, value){

    }

    render(){
        let initialValues = {};
        if (this.state.recipe_id) {
            if (Object.entries(this.state.recipe).length === 0 || !this.state.plants.length){
                return <span>Loading</span>
            }
            initialValues = this.get_initial_data(this.state.recipe);
        }
        else
            initialValues = this.get_initial_data();
        return (
            <Container maxWidth="md">
                <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    plantService.updateAdminRecipe(this.state.recipe_id, values)
                    .then(res => {
                        if (res.status === 'success'){
                              history.push('/admin/recipes')
                        }
                    });
                    setSubmitting(false);
              }}>
                  {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={6}
                                justify="center"
                                alignItems="center">
                                <Grid item xs={6}>
                                    <TextField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="standard-basic" label="Name" name="name"
                                        value={values.name}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        multiline
                                        id="standard-basic" label="Description" name="description"
                                        value={values.description}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        onChange={handleChange}
                                        control={<Checkbox name="visible"/>}
                                        label="Visible"
                                        name="visible"
                                        checked={values.visible}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel id="demo-simple-select-label">Poids</InputLabel>
                                    <Select
                                    onChange={handleChange} onBlur={handleBlur}
                                    value={values.weight} name="weight">
                                        {this.state.weights.length && this.state.weights.map(
                                            item => <MenuItem value={item.id}>{item.value}g</MenuItem>
                                        )}
                                    </Select>
                                </Grid>
                                <Grid item xs={6}>
                                        <InputLabel id="picture-label">Dessin</InputLabel>
                                        <input id="picture" name="picture" type="file" onChange={(event) => {
                                            setFieldValue("picture", event.currentTarget.files[0]);
                                        }} className="form-control" />
                                        <Thumb file={values.picture} img_url={values.picture_url}/>
                                </Grid>
                                <Grid item xs={6}>
                                {this.state.plants.length >0 &&
                                    <Autocomplete
                                        multiple
                                        filterSelectedOptions
                                        id="plants"
                                        options={this.state.plants}
                                        getOptionLabel={(option) => option.name}
                                        // defaultValue={values.tisanePlants}
                                        value={values.tisanePlants}
                                        onChange={(e, value) => {
                                            setFieldValue(
                                            "tisanePlants",
                                            value !== null ? value : initialValues.tisanePlants
                                            );
                                        }}
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="tisanePlants"
                                            variant="standard"
                                            label="Plantes"/>
                                        )}
                                    />}
                                </Grid>
                            </Grid>
                            <button type="submit" disabled={isSubmitting}>
                                Submit
                            </button>
                        </form>
                    )}
                </Formik>
            </Container>
        )
    }
}


export default withStyles(styles)(AdminRecipesForm);
