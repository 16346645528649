import React, { Component } from "react";

import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { addFilter } from '../actions/cartActions';
import { connect } from 'react-redux';
import { plantService } from '../services/plant.service'
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    plantSearch: {
        "& button": {
            "-webkit-transform": 'none',
            transform: 'none',
        }
    },
    searchContainer: {
        marginBottom: theme.spacing(2),
    }
})


class PlantSearch extends Component {

    constructor (props) {
        super(props)
        this.state = {
            tastes: [],
            properties: [],
        }
        this.onPlantChange = this.onPlantChange.bind(this);
    }

    onPlantChange(event, value){
        const { dispatch } = this.props;
        if (value === undefined) {
            const { name, value } = event.target;
            this.setState({[name]: value})
            dispatch(addFilter(name, value))
        }
        else {
            this.setState({plantName: this.formatPlantName(value)})
            if (value == null){
                dispatch(addFilter('plantName', ''))
                return
            }
            dispatch(addFilter(event.target.name, event.target.value))
        }
    }

    formatPlantName(option){
        if (option === undefined || option === null || !('name' in option))
            return ''
        return option.name + ' — ' + option.latin_name
    }

    componentDidMount(){
        const { dispatch } = this.props;
        dispatch(addFilter('plantName', ''))
        dispatch(addFilter('taste_tags', ''))
        dispatch(addFilter('property_tags', ''))
        plantService.getTag(0)
            .then((data)=> {
                this.setState({tastes: data})
            })
        plantService.getTag(1)
            .then((data)=> {
                this.setState({properties: data})
            })
    }

    render() {
        const classes = this.props.classes;
        const defaultProps = {
            options: this.props.plants.filter(function(obj) {return obj.filtered}).map(({name, latin_name, id}) => ({name, latin_name, id})),
            getOptionLabel: (option) => this.formatPlantName(option)
        };
        return (
            <React.Fragment>
                <Grid item xs={12} sm={12} md={6} className={classes.searchContainer}>
                    <Autocomplete
                        className={classes.plantSearch}
                        margin="dense"
                        {...defaultProps}
                        id="plantSearch"
                        noOptionsText="Aucune plante trouvée"
                        popupIcon={<SearchIcon />}
                        onChange={this.onPlantChange}
                        inputValue={this.props.nameSearch}
                        size="small"
                        renderInput={(params) => (
                            <FormControl variant="outlined" fullWidth margin="none">
                                <TextField {...params} variant="outlined" label="Cherchez une plante" onChange={this.onPlantChange} name="plantName"/>
                            </FormControl>)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} className={classes.searchContainer}>
                    <FormControl variant="outlined" fullWidth margin="none" size="small">
                        <InputLabel htmlFor="outlined-age-native-simple">Un goût</InputLabel>
                        <Select label="Un goût" name="taste_tags" onChange={this.onPlantChange} value={this.props.tasteSearch}>
                            <MenuItem value="">
                                <em>Aucun</em>
                            </MenuItem>
                            {this.state.tastes.map((row) => (
                                <MenuItem value={row.name}>{row.name}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className={classes.searchContainer}>
                    <FormControl variant="outlined" fullWidth margin="none" size="small">
                        <InputLabel id="demo-simple-select-helper-label">Une propriété</InputLabel>
                        <Select label="Une propriété"  name="property_tags" onChange={this.onPlantChange} value={this.props.propertySearch}>
                            <MenuItem value="">
                                <em>Aucun</em>
                            </MenuItem>
                            {this.state.properties.map((row) => (
                                    <MenuItem value={row.name}>{row.name}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
            </React.Fragment>
        );
    }
};

const mapStateToProps = (state)=>{
    return {
        plants: state.cartReducer.plants,
        nameSearch: state.cartReducer.nameSearch,
        tasteSearch: state.cartReducer.tasteSearch,
        propertySearch: state.cartReducer.propertySearch,
    }}

export default connect(mapStateToProps)(withStyles(styles)(PlantSearch));
