import React, { Component } from "react";
import { deleteItem, downQuantity, editItem, upQuantity } from '../actions/cartActions'

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Divider } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    price: {
        fontSize: '1.2em',
    },
    basketHeader: {
        paddingTop: '1em',
        paddingBottom: '1em',
    },
    tisanePrice: {
        marginTop: '0.5em',
    },
    tisaneName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        marginRight: '0.6em',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '19px',
    },
    finishedTisane: {
        paddingTop: '0.5em',
        paddingLeft: '1em',
    },
    tisaneWeight: {
        paddingLeft: '2em',
    },
    validateButton : {
        paddingRight: theme.spacing(0.5),
        paddingLeft: theme.spacing(0.5),
        fontSize: '16px',
        fontWeight: '600',
        color: 'white',
        '&:hover': {
            color: 'white',
        }
    },
    addIcon: {
        '&:hover': {
            cursor: 'pointer',
            fill: theme.palette.primary.main,
        },
        fill: "#484848",
        transition: '0.3s',
    },
    removeIcon: {
        '&:hover': {
            cursor: 'pointer',
            fill: theme.palette.secondary.main,
        },
        fill: "#484848",
        transition: '0.3s',
    },
    inner: {
        maxHeight: '500px',
        overflowY: 'auto',
    },
    secondaryAction: {
        position: 'absolute',
        right: '10px',
        bottom: '0',
    },
    itemListWrapper: {
        position: 'relative',
        paddingBottom: '0.6em',
    },
    header: {
        textAlign: 'center',
        margin: theme.spacing(1.5, 0),
    },
    blackDivider: {
        backgroundColor: theme.palette.secondary.light,
    },
    weight: {
        fontWeight: '400',
        lineHeight: '1.43',
        color: 'rgba(0, 0, 0, 0.54)',
    }
});


class TisaneListCart extends Component {

    handleAddQuantity = (tisane_id)=> {
        this.props.tisaneAddQuantity(tisane_id);
    }
    handleDownQuantity = (tisane_id) => {
        this.props.tisaneDownQuantity(tisane_id);
    }

    handleDeleteItem = (tisane_id) => {
        this.props.deleteItem(tisane_id);
    }

    handleEditItem = (tisane_id) => {
        if (this.props.addedItems.length > 0){
            if (!window.confirm('You will loose you current tisane, are you sure?')) {
                return
            }
        }
        this.props.editItem(tisane_id);
    }

    makePlantList = (addedItems, plants) => {
        const plantNames = [];
        for (let i=0; i< addedItems.length; i++) {
            plantNames.push(plants.find(item=>item.id === addedItems[i]).name)
        }
        return plantNames
    }

    render() {
        const classes = this.props.classes;
        let price = this.props.price ? this.props.price + '€' : '';
        if (!this.props.cartValid){
            return (
                <p>Chargement...</p>
            )
        }
        let validLink = this.props.loggedIn ? '/cart' : '/login/true';
        return (

            <Grid container direction="column"
                justify="center" alignItems="stretch" >
                <Grid item>
                    <Typography variant="h5" className={classes.header}>Mon panier</Typography>
                    <Divider className={classes.blackDivider}/>
                </Grid>

                <div className={classes.inner}>
                {this.props.cart.map((item, index) => {
                    const plants = this.makePlantList(item.addedItems, this.props.plants);
                    const deco = this.makePlantList(item.decoAdded, this.props.deco);
                    const sweet = this.makePlantList(item.sweetAdded, this.props.sweets);
                    let divider = <Divider className={classes.blackDivider}/>
                    if (index === this.props.cart.length - 1){
                        divider = []
                    }
                    return (
                        <div>
                            <Grid item container xs className={classes.itemListWrapper}>
                                <Grid item container direction="column" xs={2} justify="center" alignItems="center">
                                    <Grid item><RemoveIcon className={classes.removeIcon}
                                    onClick={()=>{this.handleDownQuantity(item.tisane_id)}}/></Grid>
                                    <Grid item>{item.quantity}</Grid>
                                    <Grid item><AddIcon className={classes.addIcon} onClick={()=>{this.handleAddQuantity(item.tisane_id)}}/></Grid>
                                </Grid>
                                <Grid item xs={7}>
                                    <div className={classes.finishedTisane}>
                                        <Typography variant="subtitle1" className={classes.tisaneName}>
                                            {item.name || 'Tisane'}
                                        </Typography>
                                        <Typography variant="body2" className={classes.weight}>
                                            {item.weight}g
                                        </Typography>
                                        <Typography variant="body2">
                                            {plants.join(', ')}
                                        </Typography>
                                        <Typography variant="body2">
                                            {deco.length > 0 && <span>Deco: {deco.join(', ')}</span>}
                                        </Typography>
                                        <Typography variant="body2">
                                            {sweet.length > 0 && <span>Sucrant: {sweet.join(', ')}</span>}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={2} className={classes.tisanePrice}>
                                    <span>{item.price}€</span>
                                </Grid>
                                <Grid item xs={1}>
                                </Grid>
                                <div className={classes.secondaryAction}>
                                    <IconButton size="small"
                                    onClick={()=> this.handleEditItem(item.tisane_id)}>
                                        <EditIcon className={classes.addIcon}/>
                                    </IconButton>
                                    <IconButton size="small"
                                    onClick={()=> {if (window.confirm('Voulez-vous supprimer cette tisane?')) this.handleDeleteItem(item.tisane_id)}}>
                                        <DeleteForeverIcon className={classes.removeIcon}/>
                                    </IconButton>
                                </div>
                            </Grid>
                            {divider}
                        </div>
                    )})}
                </div>
                <Divider className={classes.blackDivider}/>
                <Grid item xs container direction="row" className={classes.basketHeader}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={7}>
                        <Button variant="contained" href={validLink} className={classes.validateButton} color="primary">Valider</Button>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2} className={classes.price}>
                            {price && <span>{price}</span>}
                        </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state)=>{
    return{
        plants: state.cartReducer.plants,
        deco: state.cartReducer.deco,
        sweets: state.cartReducer.sweets,
        addedItems: state.cartReducer.addedItems,
        cartValid: state.cartReducer.cartValid,
        loggedIn: state.authentication.loggedIn
        }
    }

const mapDispatchToProps= (dispatch)=>{
    return {
        tisaneAddQuantity: (tisane_id)=>{dispatch(upQuantity(tisane_id))},
        tisaneDownQuantity: (tisane_id)=>{dispatch(downQuantity(tisane_id))},
        deleteItem: (tisane_id)=>{dispatch(deleteItem(tisane_id))},
        editItem: (tisane_id)=>{dispatch(editItem(tisane_id))},

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TisaneListCart));
