import 'react-quill/dist/quill.snow.css';

import { Button, Container } from "@material-ui/core";
import React, { Component } from "react";

import ReactQuill from 'react-quill';
import { adminService } from '../services/admin.service'
import { history } from '../helpers/history';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    editor: {
        width: '100%',
    }
});


class AdminCGV extends Component {

    constructor(props){
        super(props);
        this.state = {
            cgv: '',
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount(){
        adminService.getConfigs(['cgv']).then(res => {this.setState({cgv: res.cgv})})
    }

    handleChange(value){
        this.setState({cgv: value})
    }

    handleSubmit(e){
        adminService.updateConfigs({'cgv': this.state.cgv})
        .then(res => {
            if (res.status === "success"){
                history.push('/admin')
        }});
    }

    render() {
        const classes = this.props.classes;
        return (
            <Container maxWidth="sm">
                <div className={classes.editor}>
                    <ReactQuill theme="snow" value={this.state.cgv}
                  onChange={this.handleChange} />
                </div>
                <div>
                    <Button variant="contained" color="primary" onClick={this.handleSubmit}>Sauvegarder</Button>
                </div>
            </Container>
        )
    }
}

export default withStyles(styles)(AdminCGV);
