import { alertActions } from './alert.actions.js';
import { history } from '../helpers/history';
import { userConstants } from './action-types/user-actions';
import { userService } from '../services/user.service';

export const userActions = {
    login,
    logout,
    register,
};

function register(firstName, lastName, email, password, emailingEnabled) {
    return dispatch => {
        userService.register(firstName, lastName, email, password, emailingEnabled)
            .then(
                user => {
                    if (user.status !== "fail"){
                        dispatch(alertActions.success("Compte crée avec succes"))
                        history.push('/login/false')
                    }
                    else (
                        dispatch(alertActions.error(user.message))
                    )
                },
                error => {
                    dispatch(alertActions.error(error));
                }
            );
    };
}

function login(email, password, referrer) {
    return dispatch => {
        dispatch(request({ email }));

        userService.login(email, password, referrer)
            .then(
                user => {
                    dispatch(success(user));
                    history.push(referrer);
                    dispatch(alertActions.success("Connexion réussie"))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    return { type: userConstants.LOGOUT };
}
