import React, { Component } from "react";

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { plantService } from '../services/plant.service'
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    plantContainer: {

    },
    mainTitle: {
        textAlign: 'center',
    },
    subTitle: {
        margin: theme.spacing(2, 2),
    },
    subTitleContent: {
        display: "inline-flex",
    },
    img: {
        width: '100%',
        paddingTop: theme.spacing(2),
        padding: theme.spacing(0, 2),
    },
    sectionTitle: {
        textAlign: 'center',
    },
    sectionContent: {
        padding: theme.spacing(0, 2),
        marginBottom: theme.spacing(2),
    },
    tagList: {
        '& a': {
            margin: theme.spacing(0.2, 0.2),
            backgroundColor: theme.palette.primary.light,
        },
        padding: theme.spacing(0, 1),
    },
    tasteList: {
        '& a': {
            margin: theme.spacing(0.2, 0.2),
            backgroundColor: theme.palette.tertiary.main,
        },
        padding: theme.spacing(0, 1),
    },
    divider: {
        backgroundColor: theme.palette.secondary.light,
    },
    descriptionFooter: {
        padding: theme.spacing(2, 1),
        '& :last-child': {
            textAlign: 'center',
        }
    },
    bulletContainer: {
        marginRight: theme.spacing(1),
        minWidth: '25px',
        textAlign: 'center',
    },
    validButtonContainer: {
        padding: theme.spacing(0, 1)
    },
    validButton: {
        width: '100%',
        fontSize: '16px',
        fontWeight: '600',
        color: 'white',
    },
    cancelButton: {
        // Had to do it by hand
        padding: '8px 22px',
    },
    content: {
        flexWrap: 'nowrap',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    modal: {
        padding: theme.spacing(0),
        // Paper
        // paddingBottom: theme.spacing(2),
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        borderRadius: '4px',
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: '#fff',
        // container
        maxHeight: 'calc(100% - 64px)',
        position: 'absolute',
        outline: '0',

        // Grid container
        width: '100%',
        display: 'flex',
        boxSizing: 'border-box',
        flexDirection: 'column',
    },
    precisionSection: {
        padding: theme.spacing(1, 0),
    },
    closeIcon: {
        '& :hover': {
            cursor: 'pointer',
            fill: theme.palette.secondary.light,
        },
        fill: theme.palette.secondary.dark,
        transition: '0.3s',
    },
    headerContainer: {
        padding: theme.spacing(2, 0)
    },
    legend: {
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.875rem',
        fontWeight: '400',
    },
    bioDispo: {
        float: 'right',
        fontSize: '12px',
        color: 'rgba(0, 0, 0, 0.54)',
    },
    backLink: {
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.secondary.dark,
        }
    },
    plantLink: {
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.secondary.dark,
        }
    }
});

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            plant_id: this.props.plant_id || this.props.match.params.plant_id,
            plant: {},
            fullPage: this.props.closeModal === undefined,
        }
    }

    componentDidMount() {
        plantService.getPlant(this.state.plant_id)
        .then(res => this.setState({plant: res}))
    }

    handleModalClick = (e, plant_id) =>{
        this.props.handleClick(e, this.state.plant_id);
        this.props.closeModal(e);
    }

    render() {
        const classes = this.props.classes;
        if (!Object.keys(this.state.plant).length)
            return (<div></div>)
        const plant = this.state.plant;
        let part = plant.plant_part;
        let origin = plant.origin !== '' ? 'Origine ' + plant.origin: '';
        let bio = (plant.is_bio ? (plant.allways_bio ? 'Produit biologique' : 'Produit biologique*') : '')
        let legend= [origin, bio].filter(Boolean).join(' — ')
        return (
            <Container maxWidth="xs" className={this.state.fullPage? '' : classes.modal }>
                { this.state.fullPage &&
                    <Grid item>
                        <Link to='/' className={classes.backLink}>&#8592; Retour à la liste des plantes</Link>
                    </Grid>
                }

                <Grid container item className={classes.headerContainer}>
                    <Grid item xs={11} className={classes.mainTitle}>
                        {
                            this.state.fullPage ? <Typography variant="h5">
                                {plant.name} — {part}</Typography> : (
                            <Link to={/plant/ + plant.id} target="_blank">
                                <Typography variant="h5" className={classes.plantLink}>
                                    {plant.name} — {part}</Typography>
                            </Link>)

                        }

                        <Typography variant="h6"><i>{plant.latin_name}</i></Typography>
                    </Grid>
                    <Grid item xs>
                        {!this.state.fullPage &&
                        <CloseIcon className={classes.closeIcon} onClick={this.props.closeModal}/>}
                    </Grid>
                </Grid>
                <Grid item>
                    <Divider className={classes.divider}/>
                </Grid>

                <Grid item container direction="column" className={classes.content}>
                    <Grid item>
                        <img alt="" src={`${process.env.REACT_APP_USERS_SERVICE_URL}${plant.real_url}`} className={classes.img}/>
                        <figcaption className={classes.legend}>{legend}</figcaption>
                    </Grid>
                    <Grid item>
                        <Divider variant="middle" className={classes.divider}/>
                    </Grid>
                    <Grid item className={classes.precisionSection}>
                        <Typography variant="h6" className={classes.sectionTitle}><b>Description</b></Typography>
                        <Typography variant="body" component="p" className={classes.sectionContent}>{plant.description}</Typography>
                    </Grid>
                    <Grid item>
                        <Divider variant="middle" className={classes.divider}/>
                    </Grid>
                    <Grid item className={classes.precisionSection}>
                        <Typography variant="h6" className={classes.sectionTitle}><b>Goût</b></Typography>
                        <Typography variant="body" component="p" className={classes.sectionContent}>{plant.taste_description}</Typography>
                        <div className={classes.tasteList}>
                            {plant.taste_tags.map( item => <Chip  label={item} component="a" href="#chip" clickable/>)}
                        </div>
                    </Grid>
                    <Grid item>
                        <Divider variant="middle" className={classes.divider}/>
                    </Grid>
                    <Grid item className={classes.precisionSection}>
                        <Typography variant="h6" className={classes.sectionTitle}>
                            <b>Propriétés</b>
                        </Typography>
                        <Typography variant="body" component="p" className={classes.sectionContent}>
                            {plant.properties_description}
                        </Typography>
                        <div className={classes.tagList}>
                            {plant.property_tags.map( item => <Chip  label={item} component="a" href="#chip" clickable/>)}
                        </div>
                    </Grid>
                    {!plant.allways_bio &&
                        <Grid item>
                            <p className={classes.bioDispo}>*A chaque fois que disponible</p>
                        </Grid>}
                </Grid>
                { !this.state.fullPage &&
                <React.Fragment>
                    <Grid item>
                        <Divider className={classes.divider}/>
                    </Grid>
                    <Grid item container className={classes.descriptionFooter}>
                        <Grid item xs={4}>
                            <Button variant="outlined" size="large" onClick={this.props.closeModal} className={classes.cancelButton}>Annuler</Button>
                        </Grid>
                        <Grid item xs></Grid>
                        <Grid item xs={7}>
                            <Button variant="contained" size="large" className={classes.validButton} color="primary" onClick={(e)=>{this.handleModalClick(e, this.state.plant_id)}}>Ajouter</Button>
                        </Grid>
                    </Grid>
                </React.Fragment>
                }
            </Container>
        );
    }
}

export default withStyles(styles)(App);
