import React, { Component } from "react";

import AccountCircle from '@material-ui/icons/AccountCircle';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Container } from "@material-ui/core";
import EcoOutlined from '@material-ui/icons/Eco';
import EuroIcon from '@material-ui/icons/Euro';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import SettingsIcon from '@material-ui/icons/Settings';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    adminContainer: {
        marginTop: '3em',
    },
});


class Admin extends Component {

    render() {
        const classes = this.props.classes;
        return (
            <Container>
                <Grid container justify="center" spacing={3} className={classes.adminContainer}>
                    <Grid item>
                        <Link to='/admin/orders'>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        Commandes
                                        <IconButton>
                                            <LocalAtmIcon />
                                        </IconButton>
                                    </Typography>
                                </ CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to='/admin/plants'>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                    Plantes
                                    <IconButton>
                                            <EcoOutlined />
                                    </IconButton>
                                    </Typography>
                                </ CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to='/admin/recipes'>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                    Recettes
                                    <IconButton>
                                        <FormatListNumberedIcon />
                                    </IconButton>
                                    </Typography>
                                </ CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item>
                    <Link to='/admin/emails'>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                    Emails
                                    <IconButton>
                                        <AlternateEmailIcon />
                                    </IconButton>
                                    </Typography>
                                </ CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item>
                    <Link to='/admin/settings'>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                    CGV
                                    <IconButton>
                                        <SettingsIcon />
                                    </IconButton>
                                    </Typography>
                                </ CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to='/admin/price'>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                    Prix
                                    <IconButton>
                                        <EuroIcon />
                                    </IconButton>
                                    </Typography>
                                </ CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to='/admin/shipment'>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                    Livraison
                                    <IconButton>
                                        <LocalShippingIcon />
                                    </IconButton>
                                    </Typography>
                                </ CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to='/admin/users'>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                    Utilisateurs
                                    <IconButton>
                                        <AccountCircle />
                                    </IconButton>
                                    </Typography>
                                </ CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to='/admin/tags/taste'>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                    Tag Goût
                                    <IconButton>
                                        <LoyaltyIcon />
                                    </IconButton>
                                    </Typography>
                                </ CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to='/admin/tags/vertues'>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                    Tag vertues
                                    <IconButton>
                                        <LoyaltyIcon />
                                    </IconButton>
                                    </Typography>
                                </ CardContent>
                            </Card>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

export default withStyles(styles)(Admin);
