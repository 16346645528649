// import config from 'config';
import { buildAuthHeader, buildHeader, handleResponse } from '../helpers/ajax';

export const userService = {
    login,
    register,
    get_user_data,
    get_user_addresses,
    hideAddress,
    updateUserData,
    get_user_tisanes,
    get_order_list,
    get_order,
    getAllOrders,
    fetchCheckoutSession,
    checkUserCart,
    togglePlantVisibility,
    updateOrderState,
    sendResetToken,
    resetPassword,
    get_admin_order,
    downlaoadUsers
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: buildHeader(),
        body: JSON.stringify({ email, password })
    };

    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/auth/login`, requestOptions)
        .then(handleResponse)
}

function register(firstName, lastName, email, password, emailingEnabled) {
    const requestOptions = {
        method: 'POST',
        headers: buildHeader(),
        body: JSON.stringify({'first_name': firstName, 'last_name': lastName, email, password, emailingEnabled})
    };
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/auth/register`, requestOptions)
    .then(handleResponse)
    .then(user => {
        return user;
    });
}

function get_user_data(){
    const requestOptions = {
        method: 'GET',
        headers: buildAuthHeader()
    };
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/auth/user`, requestOptions)
        .then(handleResponse)
}

function updateUserData(firstName, lastName){
    const requestOptions = {
        method: 'POST',
        headers: buildAuthHeader(),
        body: JSON.stringify({'firstName': firstName, 'lastName': lastName})
    };
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/auth/user`, requestOptions)
        .then(handleResponse)
}

function get_user_addresses(){
    const requestOptions = {
        method: 'GET',
        headers: buildAuthHeader()
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/addresses`, requestOptions)
        .then(handleResponse)
}

function hideAddress(address_id){
    const requestOptions = {
        method: 'POST',
        headers: buildAuthHeader()
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/address/hide/` + address_id, requestOptions)
        .then(handleResponse)
}

function get_user_tisanes(){
    const requestOptions = {
        method: 'GET',
        headers: buildAuthHeader()
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/user/recipes/`, requestOptions)
        .then(handleResponse)
}

function get_order_list(){
    const requestOptions = {
        method: 'GET',
        headers: buildAuthHeader()
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/user/orders/`, requestOptions)
        .then(handleResponse)
}

function get_admin_order(orderId){
    const requestOptions = {
        method: 'GET',
        headers: buildAuthHeader()
    }

    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/admin/order/` + orderId, requestOptions)
    .then(handleResponse)
}

function get_order(id){
    const requestOptions = {
        method: 'GET',
        headers: buildAuthHeader()
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/order/` + id, requestOptions)
        .then(handleResponse)
}

function getAllOrders(order_filter, page) {
    const requestOptions = {
        method: 'GET',
        headers: buildAuthHeader()
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/admin/orders/` + order_filter + '/' + 0, requestOptions)
        .then(handleResponse)
}

function fetchCheckoutSession() {
    const requestOptions = {
        method: 'GET',
        headers: buildAuthHeader()
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/payment/`, requestOptions)
        .then(handleResponse)
}

function checkUserCart(cart, deliveryId, address) {
    const requestOptions = {
        method: 'POST',
        headers: buildAuthHeader(),
        body: JSON.stringify({'cart': cart, 'delivery_id':deliveryId, 'address':address})
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/cart/`, requestOptions)
        .then(handleResponse)
}

function togglePlantVisibility(plant_id) {
    const requestOptions = {
        method: 'POST',
        headers: buildAuthHeader(),
        body: JSON.stringify({'plant_id': plant_id})
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/admin/plants/toggle/`, requestOptions)
        .then(handleResponse)
}
function updateOrderState(orderId, orderStep) {
    const requestOptions = {
        method: 'POST',
        headers: buildAuthHeader(),
        body: JSON.stringify({'order_id': orderId, 'order_step': orderStep})
    }
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/admin/order/change_step/`, requestOptions)
        .then(handleResponse)
}
function sendResetToken(email){
    const requestOptions = {
        method: 'POST',
        headers: buildHeader(),
        body: email
    };
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/auth/reset_password`, requestOptions)
}
function resetPassword(password, token){
    const requestOptions = {
        method: 'POST',
        headers: buildHeader(),
        body: password
    };
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/auth/reset/` + token, requestOptions)

}

function downlaoadUsers(){
    const requestOptions = {
        method:'GET',
        headers: buildAuthHeader(),
    };
    return fetch(`${process.env.REACT_APP_USERS_SERVICE_URL}/api/user/emailing/`, requestOptions)
    .then(handleResponse)
}
