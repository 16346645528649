import React, { Component } from "react";
import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';

import UserPersonal from "./UserPersonal"
import UserAdresses from "./UserAdresses"

const styles = theme => ({
    container: {

    },
    smallContainerWrapper: {
        backgroundColor: "#F4F5F5"
    },
    deleteLink: {
        visiblity: 'hidden',
    }

})

class UserInfo extends Component {

    render() {
        const classes = this.props.classes;
        return (
            <div className={classes.smallContainerWrapper}>
                <Container maxWidth="sm" className={classes.container}>
                    <UserPersonal />
                    <UserAdresses />
                </Container>
            </div>
        )
    }

}

export default (withStyles(styles)(UserInfo));
