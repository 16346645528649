import React, { Component } from "react";

import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { userService } from '../services/user.service';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    title: {
        textAlign: 'center',
    },
    adresseInfo: {
        padding: theme.spacing(1),
        margin: theme.spacing(2, 0)
    }
})

class UserPersonal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addresses: []
        }
        this.handleDeleteAddress = this.handleDeleteAddress.bind(this);
    }

    componentDidMount() {
        userService.get_user_addresses()
            .then ((data) => {
                this.setState({addresses : data})
            });
      }

    handleDeleteAddress(address_id){
        userService.hideAddress(address_id)
            .then ((data) => {
                this.setState(prevState => ({
                    addresses: prevState.addresses.filter(el => el.id !== address_id )}))
            });
    }

    render() {
        const classes = this.props.classes;
        return (
            <Paper className={classes.adresseInfo}>
                <Typography variant="h4" className={classes.title}>Adresses</Typography>
                <Divider/>
                <List className={classes.root}>
                    { this.state.addresses.map( item =>
                    <React.Fragment>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary={item.full_name}
                                secondary={
                                    <React.Fragment>
                                        <p>{[item.street, item.street_precision].join(' ')}</p>
                                        <p>{[item.city, item.zip_code, item.country].join(' ')}</p>
                                        <p>{item.phone}</p>
                                    </React.Fragment>
                                }
                                />
                            <Link edge="end" className={classes.deleteLink} onClick={() => { this.handleDeleteAddress(item.id)}}>Supprimer</Link>
                        </ListItem>
                        <Divider/>
                    </React.Fragment>)}

                </List>
            </Paper>
        )
    }
}

export default (withStyles(styles)(UserPersonal));
