import React, { Component } from "react";

import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { userService } from '../services/user.service';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    root: {
        marginTop: theme.spacing(2),
    },
    addressField: {
        display: 'block',
    },
    orderNumber: {
        alignItem: 'center',
    },
    title: {
        textAlign: 'center',
        color: theme.palette.secondary.main,
    },
    dateField: {
        textAlign: 'center',
    },
    statusField: {
        textAlign: 'center',
    },
    addressSection: {
        textAlign: 'center',
    },
    dividerField: {
        margin: theme.spacing(1, 0),
    }
});


class UserOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            order: {},
            order_id: this.props.match.params.order_id,
        }
        this.mapping = {
            1: 'En préparation',
            2: 'En approvisionnement',
            3: 'Envoyée',
            4: 'Retrait en magasin',
            5: 'Fermée',
            6: 'Annulée'}
    }

    componentDidMount(){
        userService.get_order(this.state.order_id)
            .then((data) => {
                this.setState({order: data});
            })
    }

    render() {
        const classes = this.props.classes
        if (Object.keys(this.state.order).length === 0){
            return (<p>Chargement...</p>)
        }
        debugger
        return (
            <Container maxWidth="sm">
                <Paper className={classes.root}>
                    <Grid container
                        direction="column"
                        justify="center"
                        alignItems="stretch">
                        <Grid item>
                            <Typography variant="h4" className={classes.title}>Commande {this.state.order.uuid}</Typography>
                        </Grid>
                        <Grid item container>
                            <Grid item xs={6} className={classes.dateField}>
                                Commandée le {this.state.order.dateCreated}
                            </Grid>
                            <Grid item xs={6} className={classes.statusField}>
                                Statut : {this.mapping[this.state.order.current_status]}
                            </Grid>
                            <Grid item xs={12} >
                                <Divider className={classes.dividerField}/>
                            </Grid>
                            <Grid item xs={6} className={classes.addressSection}>
                                <Typography variant="h5">Address d'expedition</Typography>
                                <div>
                                    <span className={classes.addressField}>{this.state.order.liv_name}</span>
                                    <span className={classes.addressField}>{this.state.order.liv_street}</span>
                                    <span className={classes.addressField}>{this.state.order.liv_precision}</span>
                                    <span className={classes.addressField}>{this.state.order.liv_city} {this.state.order.liv_zip_code}</span>
                                    <span className={classes.addressField}>{this.state.order.liv_phone}</span>
                                </div>
                            </Grid>
                            <Grid item xs={6} className={classes.addressSection}>
                                <Typography variant="h5">Address de facturation</Typography>
                                <div>
                                <span className={classes.addressField}>{this.state.order.fact_name}</span>
                                    <span className={classes.addressField}>{this.state.order.fact_street}</span>
                                    <span className={classes.addressField}>{this.state.order.fact_precision}</span>
                                    <span className={classes.addressField}>{this.state.order.fact_city} {this.state.order.fact_zip_code}</span>
                                    <span className={classes.addressField}>{this.state.order.fact_phone}</span>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item>
                        <Divider className={classes.dividerField}/>
                            <List dense='true'>
                                {this.state.order.recipeData.map(item =>
                                    <ListItem alignItem="flex-start">
                                        <ListItemText
                                        primary={item.quantity + " x " + (item.name || "Tisane") + ' (' + item.weight + 'g)'}
                                        secondary={
                                            <div>
                                                <div>{item.plant_list.join(' ,')}</div>
                                                {item.deco_list.length > 0 && <div>Déco: {item.deco_list.join(' ,')}</div>}
                                                {item.sweet_list.length > 0 && <div>Sucrant: {item.sweet_list.join(' ,')}</div>}
                                            </div>}
                                        />
                                        <ListItemSecondaryAction>
                                            {item.price /100}€
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}
                                <ListItem alignItem="flex-start">
                                    <ListItemText primary="Sous-total"/>
                                    <ListItemSecondaryAction>
                                        {this.state.order.price /100 - this.state.order.price_shipment / 100}€
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem alignItem="flex-start">
                                    <ListItemText primary="Frais de livraison"/>
                                    <ListItemSecondaryAction>
                                    {this.state.order.price_shipment / 100}€
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem alignItem="flex-start">
                                    <ListItemText primary="Total"/>
                                    <ListItemSecondaryAction>
                                        {this.state.order.price / 100}€
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        )
    }
}

export default withStyles(styles)(UserOrder);
