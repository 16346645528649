import * as yup from 'yup';

import React, { Component } from "react";

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    sectionTitle: {
        paddingTop: theme.spacing(2),
        textAlign: 'center',
        color : theme.palette.secondary.main,
    }
});

class CartAddressForm extends Component {

    signupSchema = yup.object().shape({
        addressId: yup.string(),
        fullName: yup.string().required('Obligatoire'),
        address1: yup.string().required('Obligatoire'),
        address2: yup.string(),
        city: yup.string().required('Obligatoire'),
        zipCode: yup.string().required('Obligatoire'),
        country: yup.string().required('Obligatoire'),
        phone: yup.string().min(5, 'Invalide').required('Obligatoire').max(14, 'Invalide'),
        email: yup.string().email('Veuillez entrer une adresse valide').concat(!this.props.loggedIn ? yup.string().required('Obligatoire'): null)
    })

    fillAddressFields(e) {
        let address = this.state.addresses.find(item=>item.id === e.target.value)
        this.setState({
            errors: {},
            addressId: address.id,
            fullName: address.full_name,
            address1: address.street,
            address2: address.street_precision,
            city: address.city,
            zipCode: address.zip_code,
            country: address.country,
            phone: address.phone,
            saveAddress: '',
        })
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({...this.state, addressId: null, [name]: value , error: (delete this.state.errors[[name]])});
    }

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            addresses: [],
            addressId: null,
            fullName: '',
            address1: '',
            address2: '',
            city: '',
            zipCode: '',
            country: '',
            phone: '',
            email: '',
        }
        this.form = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    updateAddressSelect(addresses){
        this.setState({addresses: addresses})
    }

    getAddress(){
        const errorDict = {}
        let address = {};
        let formData = new FormData(this.form.current);
        for (var [key, value] of formData.entries()) {
            address[key] = value;
          }
        try{
            this.signupSchema.validateSync(address, {abortEarly: false})}
        catch (error){
            for (let i=0; i<error.inner.length; i++){
                let innerError = error.inner[i];
                errorDict[innerError.path] = innerError.message
            }
            this.setState({errors: errorDict})
        }
        return {address: address, errors: errorDict}
    }

    render() {
        let addresses_select = []
        if (this.state.addresses.length){
            addresses_select = (
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth='true'>
                            <InputLabel id="address-select-label">Addresse existante</InputLabel>
                            <Select labelId="address-select-label" onChange={(e)=> this.fillAddressFields(e)}>
                                {this.state.addresses.map(address =>{
                                    return (
                                        <MenuItem value={address.id}>
                                            {address.full_name} {address.street} {address.city}
                                        </MenuItem>)
                                } )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

            )
        }
        return (
        <React.Fragment>
            {addresses_select}
            <form id="form-address" ref={this.form}>
                <input name="addressId" type='hidden' value={this.state.addressId}/>
                <Grid container spacing={3}>
                    { !this.props.loggedIn &&
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                id="email"
                                name="email"
                                label="Adresse email"
                                fullWidth
                                error={'email' in this.state.errors}
                                helperText={'email' in this.state.errors ? this.state.errors['email'] : ''}
                                value={this.state.email}
                                onChange={this.handleChange}/>
                        </Grid>}
                    <Grid item xs={12} sm={12}>
                        <TextField
                            required
                            id="fullName"
                            name="fullName"
                            label="Nom complet"
                            fullWidth
                            error={'fullName' in this.state.errors}
                            helperText={'fullName' in this.state.errors ? this.state.errors['fullName'] : ''}
                            autoComplete="fname"
                            value={this.state.fullName}
                            onChange={this.handleChange}/>
                    </Grid>
                    <Grid item xs={12}>
                    <TextField
                        required
                        id="address1"
                        name="address1"
                        label="Numéro de voie et nom de la rue"
                        fullWidth
                        error={'address1' in this.state.errors}
                        helperText={'address1' in this.state.errors ? this.state.errors['address1'] : ''}
                        autoComplete="billing address-line1"
                        value={this.state.address1}
                        onChange={this.handleChange}/>
                    </Grid>
                    <Grid item xs={12}>
                    <TextField
                        id="address2"
                        name="address2"
                        label="Bâtiment, appartement…"
                        fullWidth
                        error={'address2' in this.state.errors}
                        helperText={'address2' in this.state.errors ? this.state.errors['address2'] : ''}
                        autoComplete="billing address-line2"
                        value={this.state.address2}
                        onChange={this.handleChange}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="city"
                        name="city"
                        label="Ville"
                        fullWidth
                        error={'city' in this.state.errors}
                        helperText={'city' in this.state.errors ? this.state.errors['city'] : ''}
                        autoComplete="billing address-level2"
                        value={this.state.city}
                        onChange={this.handleChange}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="zip"
                        name="zipCode"
                        label="Code postal"
                        error={'zipCode' in this.state.errors}
                        helperText={'zipCode' in this.state.errors ? this.state.errors['zipCode'] : ''}
                        fullWidth
                        autoComplete="billing postal-code"
                        value={this.state.zipCode}
                        onChange={this.handleChange}
                    />

                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="country"
                        name="country"
                        label="Pays"
                        error={'country' in this.state.errors}
                        helperText={'country' in this.state.errors ? this.state.errors['country'] : ''}
                        fullWidth
                        autoComplete="country"
                        value={this.state.country}
                        onChange={this.handleChange}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="phone" name="phone"
                        error={'phone' in this.state.errors}
                        helperText={'phone' in this.state.errors ? this.state.errors['phone'] : ''}
                        label="Téléphone" fullWidth
                        value={this.state.phone}
                        onChange={this.handleChange}/>
                    </Grid>
                    <Grid item xs={12}>

                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return { loggedIn: state.authentication.loggedIn };
}

export default connect(mapStateToProps, null, null, {forwardRef: true})(withStyles(styles)(CartAddressForm));
