import React, { Component } from "react";

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { addOfficialTisane } from '../actions/cartActions';
import { connect } from 'react-redux';
import { plantService } from '../services/plant.service'
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    mainTitle: {
        fontSize: '14px',
        lineHeight: '19px',
        fontWeight: '600',
        textAlign: 'center',
    },
    imgContainer: {
        textAlign: 'center',
        padding: theme.spacing(2, 0)
    },
    sectionTitle: {
        textAlign: 'center',
        padding: theme.spacing(1, 0),
    },
    sectionContent: {
        padding: theme.spacing(0, 2),
        marginBottom: theme.spacing(2),
    },
    ingredients: {
        padding: theme.spacing(0, 2),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    descriptionFooter: {
        padding: theme.spacing(2, 1),
        '& :last-child': {
            textAlign: 'center',
        }
    },
    validButton: {
        width: '100%',
        fontSize: '16px',
        fontWeight: '600',
        color: 'white',
    },
    cancelButton: {
        // Had to do it by hand
        padding: '8px 22px',
    },
    ingredientSection: {
    },
    headerContainer: {
        padding: theme.spacing(2, 0)
    },
    closeIcon: {
        '& :hover': {
            cursor: 'pointer',
            fill: theme.palette.secondary.light,
        },
        fill: theme.palette.secondary.dark,
        transition: '0.3s',
    },
    modal: {
        padding: theme.spacing(0),
        // Paper
        // paddingBottom: theme.spacing(2),
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        borderRadius: '4px',
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: '#fff',
        // container
        maxHeight: 'calc(100% - 64px)',
        position: 'absolute',
        outline: '0',

        // Grid container
        width: '100%',
        display: 'flex',
        boxSizing: 'border-box',
        flexDirection: 'column',
    },
    contentContainer: {
        flexWrap: 'nowrap',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    puce: {
        height: '0.4em',
        width: '0.4em',
        marginRight: theme.spacing(1),
    }
})

class TisaneDescription extends Component {

    constructor(props){
        super(props);
        this.state = {
            tisane: {}
        }
        this.handleAddTisane = this.handleAddTisane.bind(this);
    }

    componentDidMount() {
        let tisane_id = this.props.id || this.props.match.params.id;
        plantService.getOfficialRecipe(tisane_id)
            .then(res => this.setState({tisane: res[0]}))
    }

    handleAddTisane() {
        const { dispatch } = this.props;
        dispatch(addOfficialTisane(this.state.tisane))
        this.props.closeModal()
    }

    render() {
        const classes = this.props.classes;
        if (!this.state.tisane.id){
            return (
                <div></div>
            )
        }
        const plantList = []
        for (let i=0; i < this.state.tisane.tisanePlants.length ; i++) {
            plantList.push(this.props.plants.find(item => item.id === this.state.tisane.tisanePlants[i]).name)
        }
        return (
            <Container maxWidth="xs" className={classes.modal}>
                <Grid item container justify="center" alignItems="center" className={classes.headerContainer}>
                    <Grid item xs={11} className={classes.mainTitle}>
                        <Typography variant="h5">{this.state.tisane.name}</Typography>
                    </Grid>
                    <Grid item>
                        <CloseIcon className={classes.closeIcon} onClick={this.props.closeModal}/>
                    </Grid>
                </Grid>
                <Grid item>
                    <Divider/>
                </Grid>
                <Grid item className={classes.contentContainer}>

                    <div className={classes.imgContainer}>
                        <img alt={this.state.name} src={`${process.env.REACT_APP_USERS_SERVICE_URL}${this.state.tisane.picture_url}`} className={classes.img}
                        title={this.state.name}/>
                    </div>
                    <div className={classes.ingredientSection}>
                        <Typography variant="h6" className={classes.sectionTitle}><b>Ingrédients</b></Typography>
                        <Grid container justify="flex-start" alignItems="stretch" className={classes.ingredients}>
                            {plantList.map(item =>
                                <Grid item xs={6}>
                                    <FiberManualRecordIcon className={classes.puce}/>
                                    {item}
                                </Grid>)}
                        </Grid>
                    </div>
                    <Divider variant="middle"/>
                    <Typography variant="h6" className={classes.sectionTitle}><b>Description</b></Typography>
                    <Typography variant="body" component="p" className={classes.sectionContent}>
                        {this.state.tisane.description}
                    </Typography>
                </Grid>
                <Grid item>
                    <Divider/>
                </Grid>
                <Grid item container className={classes.descriptionFooter}>
                    <Grid item xs={4}>
                        <Button variant="outlined" size="large" onClick={this.props.closeModal} className={classes.cancelButton}>Annuler</Button>
                    </Grid>
                    <Grid item xs></Grid>
                    <Grid item xs={7}>
                        <Button variant="contained" size="large" className={classes.validButton}color="primary" onClick={() =>{this.handleAddTisane()}}>Ajouter</Button>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}


const mapStateToProps = (state)=>{
    return {
        plants: state.cartReducer.plants,
    }
}


export default connect(mapStateToProps)(withStyles(styles)(TisaneDescription));
