import 'react-multi-carousel/lib/styles.css';

import React, { Component } from 'react';

import Card from '@material-ui/core/Card';
import Carousel from 'react-multi-carousel';
import PreniumTisane from "./PreniumTisane"
import { plantService } from '../services/plant.service'
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    sectionTitle: {
        color: theme.palette.secondary.main,
        textAlign: 'center',
    },
    headerContainer: {
        position: 'relative',
    },
    seeMore: {
        '& a': {
            color: "black",
        },
        position: 'absolute',
        right: '0px',
        top: '6px',
        paddingRight: theme.spacing(1),
    },
    officialContainer: {
        // maxWidth: '100%',
    },
    root: {
    },
    fixWidth: {
        padding: theme.spacing(1),
    },
    carousel: {
        paddingBottom: theme.spacing(1),
    },
    flex: {
        display: 'flex',
    },

})

class PreniumTisaneCarousel extends Component {


    constructor(props) {
        super(props)
        this.state = {
            items: []
        }
    }

    componentDidMount() {
        plantService.getOfficialRecipes()
            .then(res => this.setState({items: res}))
    }

    render () {
        if (!this.state.items.length)
            return (
                <Card>
                    Loading
                </Card>)
        const classes = this.props.classes;
        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 4,
                slidesToSlide: 4,
                partialVisibilityGutter: 30
              },
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 2,
                slidesToSlide: 2,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 1,
                slidesToSlide: 2,
                partialVisibilityGutter: 30
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                slidesToSlide: 1,
                partialVisibilityGutter: 30
              }
          };
        return (
            <React.Fragment>
                    <Carousel autoPlay={true} infinite={true} autoPlaySpeed={3000} className={classes.carousel} partialVisible={true} responsive={responsive} itemClass={classes.fixWidth}>
                        { this.state.items.map(item =>{
                            return (
                                <PreniumTisane item={item} />
                            )})}
                    </Carousel>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(PreniumTisaneCarousel);
