import React, { Component } from "react";

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    container: {
        margin: theme.spacing(2, 'auto')
    },
    containerInner: {
        padding: theme.spacing(2, 1),
    },
    paragraph: {
        padding: theme.spacing(0, 2),
        fontSize: '18px',
    },
    title: {
        textAlign: 'center',
        paddingBottom: theme.spacing(1),
    },
    img: {
        padding: theme.spacing(0, 2),
    },
    aboutContainer: {
        padding: theme.spacing(0, 2),
    },
});

class About extends Component {


  render() {
    const classes = this.props.classes;
    return (
        <Container maxWidth="md" className={classes.container}>
            <Paper className={classes.containerInner}>
                <Typography variant="h4" color="secondary" className={classes.title}>À propos de Tisanéo</Typography>
                <Grid container spacing={1} justify="center" direction="row" alignItems="center" >
                    <Grid item sm={4}>
                        <img src="About1.jpg" className={classes.img} alt=""/>
                    </Grid>
                    <Grid item sm={8}>
                        <div className={classes.aboutContainer}>
                            <Typography variant="body1" className={classes.paragraph}>
                                <b>Soyez tisanier !</b> <b>Tisanéo</b> vous propose de vous initier à l’art de la composition de tisanes. Vous pouvez choisir vos plantes pour leurs aspects, leurs couleurs, leurs goûts ou leurs vertus. À partir de votre choix <b>Tisanéo</b> établira la meilleure formule quantitative pour que votre tisane soit, tout à la fois, <b>savoureuse et bénéfique</b> !
                            </Typography>


                        </div>
                    </Grid>
                    <Grid item sm={8}>
                        <Typography variant="body1" className={classes.paragraph}>
                            <b>Tisanéo</b> est basé en <b>France</b>, travaille avec des plantes issues de <b>l’agriculture biologique</b> de la meilleure qualité disponible. Nous n’utilisons pas d’autres moyens de conservation qu’un bon séchage de nos plantes et bien entendu pas d’adjonction d'arômes.
                        </Typography>
                        <Typography variant="body1" className={classes.paragraph}>
                                <b>Tisanéo</b> est uniquement disponible en ligne et vous invite à partager vos expériences gustatives et bienfaisantes avec notre communauté <b>#tisanéo</b> <b>#matisaneperso</b> .
                        </Typography>
                    </Grid>
                    <Grid item sm={4}>
                        <img src="About2.jpg" className={classes.img} alt=""/>
                    </Grid>

                </Grid>
            </Paper>
        </Container>
    );
  }
}


export default withStyles(styles)(About);
