import React, { Component } from "react";
import { deleteItem, editItem, removePlant } from '../actions/cartActions'

import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import CurrentTisane from './currentTisane';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import TisaneListCart from './tisaneListCart';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    basketWrapper: {
        // position: "relative",
    },
    basket: {
        // display: "block",
        // position: "absolute",
        width: "100%",
        overflowY: 'auto',
        maxHeight: '900px',
    },
    cart: {
        marginTop: '1em',
    },
    currentTisane: {
    },
    divider: {
        margin: theme.spacing(1, 0),
    },
    mobileCurrentTisane: {
        marginTop: theme.spacing(2),
    }
})

class Cart extends Component{

    constructor(props){
        super(props);
        this.state = {
            display: this.props.cart.length > 0,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
         display: nextProps.cart.length > 0,
        };
       }

    render(){
        const classes = this.props.classes;
        if (this.props.mobile){
            return (
                <Container maxWidth="xs">
                        <Paper className={classes.mobileCurrentTisane}>
                            <CurrentTisane name={this.props.tisane_name} weight={this.props.weight} quantity={this.props.quantity} addedItems={this.props.addedItems} closeModal={this.props.closeModal}/>
                        </Paper>
                        <Divider className={classes.divider}/>

                    <Paper className={classes.mobilecart}>
                        <Collapse in={this.state.display}>
                            <TisaneListCart price={this.props.subTotal} cart={this.props.cart}/>
                        </Collapse>
                    </Paper>
                </Container>
            )
        }
        return(
            <div className={classes.basket}>
                <Paper className={classes.currentTisane}>
                    <CurrentTisane name={this.props.tisane_name} weight={this.props.weight} quantity={this.props.quantity} addedItems={this.props.addedItems} mobile={false}/>
                </Paper>
                <Collapse in={this.state.display}>
                    <Paper className={classes.cart}>
                        <TisaneListCart price={this.props.subTotal} cart={this.props.cart}/>
                    </Paper>
                </Collapse>
            </div>
    )
    }
}

const mapStateToProps = (state)=>{
return{
    addedItems: state.cartReducer.addedItems,
    tisane_name: state.cartReducer.name,
    weight: state.cartReducer.weight,
    quantity: state.cartReducer.quantity,
    cart : state.cartReducer.cart,
    subTotal: state.cartReducer.subTotal,
}
}

const mapDispatchToProps= (dispatch)=>{
    return {
        removePlant: (plant_id)=>{dispatch(removePlant(plant_id))},
        deleteItem: (tisane_id)=>{dispatch(deleteItem(tisane_id))},
        editItem: (tisane_id)=>{dispatch(editItem(tisane_id))},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Cart));
