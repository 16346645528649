import { Redirect, Route } from 'react-router-dom';

import React from 'react';

export const PrivateRoute = ({ component: Component, isAutenticated, ...rest }) => (
    <Route {...rest} render={props => (
        isAutenticated
            ? <Component {...props}/>
            : <Redirect to={{ pathname: '/login/false', state: { from: props.location } }}/>
    )} />
)
