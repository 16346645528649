import React, { Component } from "react";

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    tableHeader: {
        fontWeight: '500',
        fontSize: '14px',
        textAlign: 'right',
    },

})


class cartItemDescription extends Component {

    render() {
        const classes = this.props.classes;
        return (
                <Grid container>
                    <Grid item xs={8}>
                    </Grid>
                    <Grid item xs={2} className={classes.tableHeader}>
                        Prix unitaire
                    </Grid>
                    <Grid item xs={2} className={classes.tableHeader}>
                        Prix
                    </Grid>
                </Grid>
        );
    }
}
export default withStyles(styles)(cartItemDescription);
