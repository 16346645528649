import 'react-quill/dist/quill.snow.css';

import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { userService } from '../services/user.service';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
});


class AdminCGV extends Component {

    constructor(props){
        super(props);
        this.state = {
            csv: '',
        }
        this.downlaoadUsers = this.downlaoadUsers.bind(this);
    }

    async downlaoadUsers(){
        await userService.downlaoadUsers()
            .then(res => this.setState({csv: res}))
        let csvContent = "data:text/csv;charset=utf-8,"
            + "Email,\n"
            + this.state.csv.map(e => e + ',').join("\n");
        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }

    render() {
        return (
            <Container maxWidth="sm">
                <Button href="/admin/" variant="contained">Retour</Button>
                <Button variant="contained" color="primary" onClick={this.downlaoadUsers}>Télécharger les emails</Button>
            </Container>
        )
    }
}

export default withStyles(styles)(AdminCGV);
