import { Button, TextField, Typography } from "@material-ui/core";
import React, { Component } from "react";

import Container from '@material-ui/core/Container';
import { Formik } from "formik";
import { alertActions } from '../actions/alert.actions.js';
import { connect } from 'react-redux';
import { history } from '../helpers/history';
import { userService } from "../services/user.service";
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    passwordReinitialize: {
        padding: theme.spacing(6, 2)
    },
    form: {
        width: '100%',
    },
    submitButton: {
        margin: theme.spacing(3, 0)
    }
})


class PasswordReset extends Component {



    render() {
        const classes = this.props.classes;

        return (
            <Container maxWidth="xs" className={classes.passwordReinitialize}>
                <Typography variant="h6">Réinitialisation de votre mot de passe</Typography>
                <Typography variant="body1">
                    Veuillez préciser votre adresse email pour réinitialiser votre mot de passe.
                </Typography>
                <Formik
                    initialValues={{email: ''}}
                    validate={
                        values =>{
                            const errors = {};
                            if (!values.email){
                                errors.email = 'Obligatoire';
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                                errors.email = 'Invalide';
                            }
                            return errors;
                        }}
                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(false);
                        userService.sendResetToken(JSON.stringify(values))
                            .then(history.push('/'))
                            .then(this.props.success("Si cette addresse éxiste un email vous lui a été envoyée."))

                    }}>
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <form onSubmit={handleSubmit} className={classes.form} novalidate>
                                <TextField
                                    fullWidth
                                    error={errors.email && touched.email}
                                    helperText={errors.email}
                                    // type="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    label="Adresse email" />

                            <div>
                                <Button type="submit" color="primary" variant="contained" disabled={isSubmitting} className={classes.submitButton}>
                                Continuer
                                </Button>
                            </div>
                          </form>
                        )}
                </Formik>
            </Container>
        );
    }
}

const mapDispatchToProps = {
    success: alertActions.success,
    error: alertActions.error,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(PasswordReset));
