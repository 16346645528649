import React, { Component } from "react";

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { Formik } from 'formik';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import { plantService } from '../services/plant.service';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
});



class AdminOrderManagement extends Component {

    constructor(props) {
        super(props)
        this.state = {
            prices: []
        }
    }

    componentDidMount(){
        plantService.getPriceTags()
            .then(res=>this.setState({prices: res}))
    }


    render() {
        const initialValues = {};
        if (this.state.prices.length> 0){
            for (var i=0; i<this.state.prices.length; i++){
                initialValues[this.state.prices[i].id] = this.state.prices[i].price
            }
        }
        else {return <p>Chargement</p>}

        return (
            <Container maxWidth="sm">
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting }) => {
                            plantService.updatePriceTag(values)
                            setSubmitting(false);
                    }}>
                    {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit}>
                        <List>
                            {this.state.prices.map(
                                item=>
                                <ListItem>
                                    {item.id} : <TextField onChange={handleChange}
                                    onBlur={handleBlur} name={item.id} value={values[item.id]}/>€/g
                                </ListItem>)}
                        </List>
                        <Button variant="contained" color="primary" type="submit">Valider</Button>
                    </form>
                )}
                </Formik>
            </Container>
        )
    }
}

export default withStyles(styles)(AdminOrderManagement);
