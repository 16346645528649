import { alert } from "./alert.reducer"
import authentication from "./authReducer"
import cartReducer from "./cartReducer"
import localForage from 'localforage'
import { persistCombineReducers } from 'redux-persist'

const persistConfig = {
    key: 'root',
    storage: localForage,
    // whitelist: ['cartReducer', 'alert', 'authentication']
  }

const rootReducer = persistCombineReducers(persistConfig, {
    cartReducer: cartReducer,
    authentication: authentication,
    alert: alert})


export default rootReducer
