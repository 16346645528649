import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { history } from '../helpers/history';
import { userActions } from '../actions/user.actions';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      form: {
        width: '100%', // Fix IE 11 issue.

      },
      divider: {
        marginTop: theme.spacing(2),
      },
      submit: {
        marginTop: theme.spacing(2),
        color: 'white',
        fontSize: '16px',
        fontWeight: 600,
        '& :hover': {
            color: 'white',
        }
      },
      accountDescription: {
        margin: theme.spacing(3, 0),
      }
});

class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        let referrer = this.props.location.state ? (
            this.props.location.state.from.pathname) : (
                '/')
        // reset login status
        this.state = {
            email: '',
            password: '',
            submitted: false,
            referrer: referrer,
            cart: this.props.match.params.cart,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {

        e.preventDefault();
        this.setState({ submitted: true });
        const { email, password, referrer } = this.state;
        const { dispatch } = this.props;
        if (email && password) {
            dispatch(userActions.login(email, password, referrer));
        }
    }
    handleNoAccount(e){
        e.preventDefault();
        history.push('/cart');
    }

    render() {
        const { email, password } = this.state;
        const classes = this.props.classes;

        return (
            <Container maxWidth="xs">
                <Grid container direction="column" className={classes.accountDescription}>
                {this.state.cart === "true" &&
                    <Grid item>
                        Créer un compte vous permet de suivre l'état de <b>vos commandes</b> depuis Tisaneo.fr et de garder un <b>historique de vos tisanes</b>.
                    </Grid>}
                    <Grid item>
                        <div className={classes.paper}>
                            <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Adresse email"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={email} onChange={this.handleChange}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Mot de passe"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={password} onChange={this.handleChange}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}>
                                        Se connecter
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="/password_reset" variant="body2" color="secondary">
                                            Mot de passe oublié
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="/register" variant="body2" color="secondary">
                                            Créer un compte
                                        </Link>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </Grid>
                    {this.state.cart === "true" &&
                        <React.Fragment>
                            <Grid item>
                                <Divider className={classes.divider}/>
                            </Grid>
                            <Grid item>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={this.handleNoAccount}
                                    >
                                        Continuer sans compte
                                </Button>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return {
        loggingIn
    };
}

export default  connect(mapStateToProps)(withStyles(styles)(LoginPage));
