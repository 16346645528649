import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { alertActions } from '../actions/alert.actions.js';
import { connect } from 'react-redux';
import { userActions } from '../actions/user.actions';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      avatar: {
        margin: theme.spacing(1),
        // backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
        color: 'white',
        fontSize: '16px',
        fontWeight: 600,
      },

});

class Register extends React.Component {

    constructor(props) {
        super(props);
        this.state= {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            emailingEnabled: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleEmailing = this.handleEmailing.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleEmailing(e) {
        const { name, checked } = e.target;
        this.setState({ [name]: checked });
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { firstName, lastName, email, password, emailingEnabled } = this.state;
        const { dispatch } = this.props;
        if (password != "" && password.length < 8) {
            dispatch(alertActions.error("Votre mot de passe faire plus de 8 caractères pour être valide."))
            return;
        }
        if (firstName && lastName && email && password) {
            dispatch(userActions.register(firstName, lastName, email, password, emailingEnabled))
        }
        else {
            dispatch(alertActions.error("Tous les champs doivent être templis."))
        }
    }

    render() {
        const classes = this.props.classes;
        const { firstName, lastName, email, password, emailingEnabled } = this.state;
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5" color="secondary">
                    Créer un Compte
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                        <TextField
                            autoComplete="fname"
                            name="firstName"
                            variant="outlined"
                            required
                            fullWidth
                            id="firstName"
                            label="Prénom"
                            autoFocus
                            value={firstName} onChange={this.handleChange}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="lastName"
                            label="Nom de famille"
                            name="lastName"
                            autoComplete="lname"
                            value={lastName} onChange={this.handleChange}/>
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            label="Addresse email"
                            name="email"
                            autoComplete="email"
                            value={email} onChange={this.handleChange}/>
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            label="Mot de passe"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password} onChange={this.handleChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                name="emailingEnabled"
                                control={<Checkbox />}
                                label="Recevoir des nouvelles de Tisanéo"
                                checked={emailingEnabled} onChange={this.handleEmailing}/>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Créer un compte
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                        <Link href="/login/false" variant="body2" color="secondary">
                            Vous avez déjà un compte? Identifiez vous
                        </Link>
                        </Grid>
                    </Grid>
                    </form>
                </div>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

export default  connect(mapStateToProps)(withStyles(styles)(Register));
