import 'react-quill/dist/quill.snow.css';

import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { adminService } from '../services/admin.service';
import { history } from '../helpers/history';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
});


class AdminTagForm extends Component {

    constructor(props){
        super(props);
        this.state = {
            tagId: props.match.params.tag_id,
            name: "",
            category_id: 0,
            visible: true,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    componentDidMount(){
        if (this.state.tagId) {
            adminService.getAdminTagForm(this.state.tagId)
                .then(res=>this.setState({name: res.name, category_id: res.category_id, visible: res.visible==="False" ? false: true}))
        }
    }

    handleChange(e){
        const {name, value} = e.target;
        this.setState({[name]: value})
    }

    handleCheckboxChange(e){
        const {name, checked} = e.target;
        this.setState({[name]: checked})
    }

    handleSubmit(e){
        const { tagId, name, category_id, visible } = this.state;
        adminService.updateAdminTag(tagId, {name: name, category_id: category_id, visible: visible})
        .then(
            res => {
            if (res.status === "success"){
                history.push('/admin')
            }}
        )
    }

    render() {
        return (
            <Container maxWidth="sm">
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Nom"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    value={this.state.name} onChange={this.handleChange}
                />
                 <Select
                    name="category_id"
                    onChange={this.handleChange}
                    value={this.state.category_id}
                    fullWidth
                    >
                        <MenuItem value="0">Goût</MenuItem>
                        <MenuItem value="1">Proptiété</MenuItem>
                    </Select>

                <FormControlLabel

                    control={<Checkbox color="secondary" name="visible" checked={this.state.visible} onChange={this.handleCheckboxChange}/>}
                    label="Visible" />

                <Button variant="contained" color="primary" onClick={this.handleSubmit}>Valider</Button>
            </Container>
        )
    }
}

export default withStyles(styles)(AdminTagForm);
